div.ourStudio{
    padding: 0 0 80px;
    @media all and (max-width:641px){
        padding: 0 20px 80px;
    }
	div.wrapper { 

		div.videoContainer { 
            width: 90%;
            margin: 0 auto 30px;
			video { 
                display: block;
                width: 100%;
			}
            @media all and (max-width:1200px){
                width: 70%;
            }
            @media all and (max-width:640px){
                width: 80%;
            }
            @media all and (max-width:481px){
                width: 100%;
            }
		}

		
        div.contentsContainer{
            width: 90%;
            margin: 0 auto;
            @media all and (max-width:1200px){
                width: 80%;
            }
            @media all and (max-width:640px){
                width: 85%;
            }
            @media all and (max-width:480px){
                width: 90%;
            }
            div.contents { 
                margin-bottom: 50px;
                h1.title { 
                    font-size: 18px;
                    margin-bottom: 10px;
                    @media all and (max-width:980px){
                        font-size: 16px;
                    }
                }
                div.details { 
                    border-left: 2px solid var(--text-color);
                    padding-left: 15px;
                    &.list{
                        border: none;
                        ul{
                            li{
                                display: flex;
                                margin-bottom: 10px;
                                &:before{
                                    content:"◦";
                                    margin-right: 10px;
                                }
                                ul{
                                    li{
                                        &:before{
                                            content: "»";
                                            margin-right: 10px;
                                        }
                                    }
                                }
                                p{
                                    font-size: 16px;
                                    letter-spacing: .5px;
                                    @media all and (max-width:980px){
                                        font-size: 14px;
                                    }
                                    span{
                                        font-size: 16px;
                                        color: var(--primary-color);
                                        @media all and (max-width:980px){
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    p { 
                        font-size: 16px;
                        text-align: left;
                        margin-bottom: 0;
                        @media all and (max-width:980px){
                            font-size: 14px;
                        }
                    }
                }
            }
        }


		
	}
}