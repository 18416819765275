div.homeContainer{
    padding: 0 0 0;
    @media all and (max-width:1200px){
        padding: 0 0 130px;
      }
    div.wrapper{
       div.categoriesSection{
        padding-top: 20px;
       } 
        
        
    }
    
}