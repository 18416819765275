div.register-login{
    padding: 150px 0;
    position: relative;
    span.forgot{
        color: var(--primary-color);
        font-size: 14px;
        cursor: pointer;
        display: block;
        margin-top: 10px;
       }
    @media all and (max-width:640px){
        padding: 150px 30px;
    }
    h1.heading{
        font-size: 24px;
        padding-bottom: 10px;
    }
    span.error{
        color: var(--primary-color);
        font-size: 12px;
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }
    span.success{
        font-size: 12px;
    }
    div.registerLoginContainer{
        border-top: 1px solid var(--white-color);
        background-color: var(--secondary-bg-color);
        padding: 70px 70px 40px 70px;
        display: flex;
        justify-content: space-between;
        @media all and (max-width:980px){
            flex-direction: column-reverse;
        }
        @media all and (max-width:640px){
            padding: 30px;
        }
        div.register{
            width: 53%;
            @media all and (max-width:980px){
                width: 100%;
            }
            h1.title{
                font-size: 20px;
                @media all and (max-width:480px){
                    font-size: 17px;
                }
            }
            div.overlayOtp{
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.7);
                z-index: 8;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            div.otpContainer{
                z-index: 9;
                text-align: center;
                position: fixed;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                background-color: var(--secondary-bg-color);
                width: 40%;
                margin: 0 auto;
                padding: 60px;
                border-radius: 6px;
                @media all and (max-width:1200px){
                    padding: 30px;
                }
                @media all and (max-width:768px){
                    width: 60%;
                }
                @media all and (max-width:480px){
                    width: 80%;
                }
                label{
                    font-size: 16px;
                margin-bottom: 10px;
                }
                button.bg-button{
                    margin: 20px auto 0;
                    width: 30%;
                }
                input{
                    display: block;
                    margin: 0 auto;
                    padding: 6px 8px;
                    border: 1px solid var(--primary-color);
                    border-radius: 6px;
                    color: var(--text-color);
                    width: 60%;
                    @media all and (max-width:1200px){
                        width: 80%;
                    }
                }
                input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
            }
            
           
            span.error{
                color: #ff5159;
                padding-left: 6px;
                display: block;
            }
            input[type="number"]{
                background-color: #141414;
                border: none;
                transition: all ease-in-out 0.5s;
            }
            
            span.otpError{
                color: #ff5159;
                padding-left: 6px;
                display: block;
                text-align: center;
                margin-top: 10px;
            }
            span.resendCode{
                margin-top: 10px;
                cursor: pointer;
            }
            span.resendCode:hover{
               text-decoration: underline;
            }
            div.formContainer{
                margin-top: 50px;
                padding-left: 30px;
                @media all and (max-width:640px){
                    padding-left: 0;
                }
                form{
                    div.oneByTwo{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        @media all and (max-width:640px){
                            flex-direction: column;
                        }
                        &:nth-child(3){
                            margin-bottom: 0;
                        }
                        div.inputContainer{
                            display: flex;
                            flex-direction: column;
                            width: 48%;
                            span.error{
                                text-align: left;
                            }
                            @media all and (max-width:640px){
                                width: 100%;
                                margin-bottom: 20px;
                            }
                            label{
                                font-size: 14px;
                                color: var(--text-color);
                                display: inline-block;
                                margin-bottom: 6px;
                            }
                            input{
                                padding: 8px 6px;
                                border: none;
                                outline: none;
                                border-radius: 6px;
                                font-size: 14px;
                            }
                            select{
                                -webkit-appearance: none;
                                padding: 8px 6px;
                                border: none;
                                outline: none;
                                border-radius: 6px;
                                font-size: 14px;
                            }
                            
                            
                        }
                       
                    }
                    div.buttonContainer{
                        margin-top: 130px;
                        @media all and (max-width:640px){
                            margin-top: 30px;
                            
                        }
                        button.bg-button{
                            margin: 0 auto ;
                            width: 35%;
                            @media all and (max-width:640px){
                                width: 50%;
                                
                            }
                        }
                    }
                }
            }
        }
        div.login{
            width: 30%;
            @media all and (max-width:980px){
                width: 100%;
                margin-bottom: 30px;

            }
            span.error{
                margin-top: 20px;
            }
            h1.title{
                font-size: 20px;
                @media all and (max-width:480px){
                    font-size: 17px;
                }
            }
            div.formContainer{
                width: 100%;
                margin-top: 50px;
                form{
                    width: 100%;
                    div.full{
                        width: 100%;
                        margin-bottom: 20px;
                        &:nth-child(2){
                            margin-bottom: 0;
                        }
                        div.inputContainer{
                            display: flex;
                            flex-direction: column;
                            label{
                                font-size: 14px;
                                color: var(--text-color);
                                display: inline-block;
                                margin-bottom: 6px;
                            }
                            input{
                                padding: 8px 6px;
                                border: none;
                                outline: none;
                                border-radius: 6px;
                                font-size: 14px;
                            }
                        }
                    }
                    div.buttonContainer{
                        margin-top: 40px;
                        button{

                            width: 50%;
                            margin: 0 auto ;
                        }
                    }
                }
            }
        }
    }
}