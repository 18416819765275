div.prayerSuccessPage{
    padding: 40px 0 160px;
    @media all and (max-width:640px){
        padding: 40px 0 120px;
    }
    div.wrapper{
        p.message{
            font-size: 18px;
            max-width: 85%;
            margin: 0 auto;
            text-align: center;
            @media all and (max-width:1200px){
                font-size: 18px;
            }
            @media all and (max-width:640px){
                font-size: 16px;
            }
            span{
                color: var(--primary-color);
                cursor: pointer;
            }
        }
    }
}