div.newsCard { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--secondary-bg-color);
    transition: 0.4s ease-in-out;
    &:hover{
        background-color: var(--hover-color);
    }
    @media all and (max-width:980px){
        flex-direction: column;
    }
	div.left { 
        width: 48%;
        @media all and (max-width:1200px){
            width: 50%;
        }
        @media all and (max-width:980px){
            width: 100%;
            margin-bottom: 20px;
        }
		div.imageContainer { 
            width: 100%;
			img { 
                width: 100%;
                display: block;
                border-radius: 8px;
			}
		}
	}

	div.right { 
        width: 48%;
        @media all and (max-width:980px){
            width: 100%;
        }
		h1.title { 
            font-size: 22px;
            margin-bottom: 20px;
            @media all and (max-width:1200px){
                margin-bottom: 10px;
            }
            @media all and (max-width:640px){
                font-size: 20px;
            }
		}

		p { 
            font-size: 16px;
            @media all and (max-width:1200px){
                    font-size: 14px;
                    margin-bottom: 10px;
            }
            @media all and (max-width:640px){
                font-size: 13px;
            }
		}

		div.dateSection { 
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            @media all and (max-width:1200px){
                margin-bottom: 10px;
        }
			span.icon { 
                width: 20px;
                display: inline-block;
                margin-right: 10px;
				img { 
                    width: 100%;
                    display: block;
				}
			}

			span.date { 
                font-size: 14px;
                color: var(--text-color);
                @media all and (max-width:640px){
                    font-size: 13px;
                }
			}
		}

		div.buttonContainer { 

			button.bg-button { 

			}
		}
	}
}