div.articleInfo { 
    padding: 0 0 180px;
   

	div.wrapper { 

		h1.heading { 
            font-size: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
            @media all and (max-width:640px){
                padding-left: 20px;
            }
		}

		div.dateSection { 
            display: flex;
            align-items: center;
            margin-top: 30px;
            @media all and (max-width:640px){
                padding-left: 20px;
            }
			span.icon { 
                width: 20px;
                display: block;
                margin-right: 10px;
				img { 
                    display: block;
                    width: 100%;
				}
			}

			span.date { 
                font-size: 12px;
                color: var(--text-color);
			}
		}

		div.articleDetails { 
            display: flex;
            justify-content: space-between; 
            width: 80%;
            margin: 20px auto 0;
            flex-direction: column;
            @media all and (max-width:980px){
                width: 90%;
            }
            @media all and (max-width:768px){
                flex-direction: column;
            }
			div.left { 
                width: 100%;
                margin-bottom: 20px;
                @media all and (max-width:768px){
                    width: 100%;
                    margin-bottom: 30px;
                }
				div.imageContainer { 
                    width: 100%;
                    height: 100%;
					img { 
                        display: block;
                        width: 100%;
                        border-radius: 6px;
					}
				}
			}

			div.right { 
                width: 100%;
                @media all and (max-width:768px){
                    width: 100%;
                }
				p { 
                    font-size: 14px;
                    margin-bottom: 20px;
                    letter-spacing: .3px;
                    line-height: 1.8em;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media all and (max-width:980px){
                        font-size: 13px;
                    }
				}
			}
		}

		div.videoContainer { 
            width: 80%;
            margin: 90px auto 0;
			video { 
                display: block;
                width: 100%;
			}
            @media all and (max-width:980px){
                width: 90%;
            }
		}

		div.buttonContainer { 
            text-align: center;
            margin-top: 50px;
            position: relative;
            button.sharebutton { 
    
                background-color: transparent;
                border: 1px solid var(--primary-color);
                padding: 6px 10px;
                width: 15%;
                border-radius: 10px;
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                transition: 0.4s ease-in-out;
                @media all and (max-width:980px){
                    width: 20%;
                    font-size: 14px;
                }
                @media all and (max-width:641px){
                   width: 25%;
                }
                @media all and (max-width:480px){
                    width: 35%;
                    padding: 6px 15px;
                 }
                span{
                    color: var(--primary-color);
                    margin :0 10px;
                    transition: 0.4s ease-in-out;
                }
                
                svg{
                    transition: 0.4s ease-in-out;
                }
                svg.active{
                    transform: rotate(180deg);
                }
                &:hover{
                    border-color: var(--icons-color);
                    span{
                        color: var(--icons-color);
                    }
                    svg{
                       fill: var(--icons-color);
                    }
                }
                
            }
            button.active{
                    svg.active{
                        transform: rotate(0deg);
                    }
            }
            div.shareContainer{
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: var(--secondary-bg-color);
                width: 15%;
                animation: slideDown 0.4s ease-in-out;
                @media all and (max-width:1200px){
                    width: 17%;
                }
                @media all and (max-width:980px){
                    width: 21%;
                }
                @media all and (max-width:768px){
                    width: 23%;
                }
                @media all and (max-width:640px){
                    width: 30%;
                }
                @media all and (max-width:480px){
                    width: 45%;
                }
                button{
                   padding: 8px 0 !important;
                   padding: 8px 0 !important;
                   display: block;
                   text-align: center;
                   margin: 0 auto;
                }
                button.clipboard{
                    border: none;
                    background-color: transparent;
                    font-size: 14px;
                    color: var(--text-color);
                }
                span.name{
                    font-size: 14px;
                    color: var(--primary-color);
                }
                span.twitter{
                    color: #00aceb;
                }
                span.facebook{
                    color: #3a5896;
                    font-size: 14px;
                }
                
                &:hover{
                    svg.bi-facebook{

                        fill: #1877f2 !important;
                    }
                }
            }
        }
	}
}

@keyframes slideDown {
    0%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(0);
    }
}