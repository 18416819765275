div.show-information{
    padding: 0 0 80px;
    @media all and (max-width:640px){
        padding: 0 20px 80px;
    }
    div.spotlight{
        
        div.featuredContainer{
            position: relative;
            padding: 80px 50px;
            overflow: hidden;
            @media all and (max-width:480px){
                padding: 30px 20px;
            }
            div.gradientBg{
                bottom: 0 !important;
            }
            div.background{
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0;
                right: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                filter: blur(80px);
                img{
                    display: block;
                    width: 100%;
                }
            }
            div.imageContainer{
                display: block;
                max-width: 60%;
                height: auto;
                margin: 0 auto;
                @media all and (max-width:768px){
                    max-width: 75%;
                }
                @media all and (max-width:640px){
                    max-width: 90%;
                }
                @media all and (max-width:480px){
                    max-width: 100%;
                }
                img{
                    display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                border-radius: 10px;
                }
            }
        }
        div.details{
            background-color: var(--bg-color);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            @media all and (max-width:480px){
                padding: 10px 0;
             }
             @media all and (max-width:768px){
                flex-direction: column;
            }
            
            div.left{
                width: 60%;
                @media all and (max-width:1400px){
                    width: 70%;
                }
                @media all and (max-width:1200px){
                    width: 60%;
                }
                @media all and (max-width:980px){
                    width: 70%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                
                h1{
                    font-size: 22px;
                    @media all and (max-width:768px){
                        font-size: 20px;
                    }
                    @media all and (max-width:480px){
                       font-size: 18px;
                    }
                }
                p{
                    margin-bottom: 0;
                    font-size: 14px;
                    @media all and (max-width:768px){
                        font-size: 13px;
                    }
                }
            }
            div.right{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: right;
                @media all and (max-width:1400px){
                    width: 30%;
                }
                @media all and (max-width:1200px){
                    width: 40%;
                }
                @media all and (max-width:980px){
                    width: 45%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                    justify-content: left;
                }
                @media all and (max-width:480px){
                    justify-content: center;
                }
                div.shareButtonContainer{
                    width: 25%;
                    margin-right: 20px;
                    position: relative;
                    @media all and (max-width:1400px){
                        width: 40%;
                    }
                    @media all and (max-width:1200px){
                        width: 33%;
                    }
                    @media all and (max-width:980px){
                        width: 50%;
                    }
                    @media all and (max-width:768px){
                        width: 20%;
                    }
                    @media all and (max-width:640px){
                        width: 28%;
                    }
                    @media all and (max-width:480px){
                        width: 50%;
                    }
                    @media all and (max-width:360px){
                        width: 65%;
                    }
                    div.shareContainer{
                        border-radius:3px;
                        position: absolute;
                        top: 40px;
                        left: 0;
                        right: 0;
                        background-color: var(--secondary-bg-color);
                        width: 100%;
                        animation: slideDown 0.4s ease-in-out;
                        z-index: 9;
                        button{
                           padding: 8px 0 !important;
                           padding: 8px 0 !important;
                           display: block;
                           text-align: center;
                           margin: 0 auto;
                        }
                        button.clipboard{
                            border: none;
                            background-color: transparent;
                            font-size: 14px;
                            color: var(--text-color);
                        }
                        span.name{
                            font-size: 14px;
                            color: var(--primary-color);
                        }
                        span.twitter{
                            color: #00aceb;
                        }
                        span.facebook{
                            color: #3a5896;
                            font-size: 14px;
                        }
                        
                        &:hover{
                            svg.bi-facebook{
        
                                fill: #1877f2 !important;
                            }
                        }
                    }
                }
                button.transparent{
                    padding: 4px 3px;
                    width: 100%;
                    span{
                        color: var(--primary-color);
                        margin: 0 10px;
                        transition: 0.4s ease-in-out;
                    }
                    @media all and (max-width:1200px){
                        padding: 3px ;
                    }
                    
                    &.active{
                        svg.active{
                            transform: rotate(0deg);
                        }
                    }
                    &:hover{
                        span{
                            color: var(--icons-color);
                        }
                    }
                    svg.share{
                        width: 15px !important;
                        height: 15px !important;
                        @media all and (max-width:1400px){
                            width: 14px !important;
                            height: 14px !important;
                        }
                    }
                    
                    svg.active{
                        transform: rotate(180deg);
                        transition: 0.4s ease-in-out;
                        width: 10px !important;
                        height: 10px !important;
                    }
                    
                    @media all and (max-width:1200px){
                    }
                    @media all and (max-width:980px){
                    }
                    @media all and (max-width:768px){
                        font-size: 12px;
                    }
                    @media all and (max-width:480px){
                    }
                    @media all and (max-width:360px){
                        padding: 6px 6px;
                    }
                }
                button.bg-button{
                    padding: 4px 16px 4px 3px;
                    width: 25%;
                    justify-content: right;
                    font-size: 12px;
                    svg{
                        height: 20px !important;
                        width: 20px !important;
                    }
                    @media all and (max-width:1400px){
                        width: 43%;
                    }
                    @media all and (max-width:1200px){
                        padding: 3px 16px 4px 3px ;
                        width: 35%;
                    }
                    @media all and (max-width:980px){
                        width: 50%;
                    }
                    @media all and (max-width:768px){
                        font-size: 12px;
                        width: 20%;
                        padding: 2px 16px 2px 3px;
                    }
                    @media all and (max-width:640px){
                        width: 28%;
                    }
                    @media all and (max-width:480px){
                        width: 40%;
                    }
                }
            }
        }
    }
    div.scheduleContainer{
        margin-top: 40px;
        h1.heading{
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
            font-size: 22px;
            margin-bottom: 20px;
        }
        div.detailsContainer{
            display: flex;
            justify-content: space-between;
            @media all and (max-width:640px){
                flex-direction: column;
            }
            div.left{
                width: 48%;
                @media all and (max-width:768px){
                    width: 30%;
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                h1{
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                div.scheduleTime{
                    border-left: 1px solid var(--white-color);
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    @media all and (max-width:1400px){
                    }
                    @media all and (max-width:1200px){
                        width: 100%;
                    }
                    span{
                        font-size: 14px;
                        color: var(--text-color);
                        display: inline-block;
                        @media all and (max-width:360px){
                            font-size: 13px;
                        }
                        &.top{
                            width: 26%;
                            margin-bottom: 10px;
                            
                            &:last-child{
                                margin-right: 0;
                                margin-bottom: 0;
                            }
                            @media all and (max-width:1400px){
                                width: 32%;
                            }
                            @media all and (max-width:1200px){
                                width: 35%;
                            }
                            @media all and (max-width:980px){
                                width: 100%;
                            }
                            @media all and (max-width:640px){
                                width: 34%;
                            }
                            @media all and (max-width:480px){
                                width: 50%;
                            }
                            
                        }
                    }
                    span.time{
                            color: var(--primary-color);
                    }
                }
            }
            div.right{
                width: 48%;
                @media all and (max-width:768px){
                    width: 58%;
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                h1{
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                div.scheduleTime{
                    border-left: 1px solid var(--white-color);
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    @media all and (max-width:1400px){
                    }
                    @media all and (max-width:1200px){
                        width: 100%;
                    }
                    span{
                        font-size: 14px;
                        color: var(--text-color);
                        display: inline-block;
                        @media all and (max-width:360px){
                            font-size: 13px;
                        }
                        &.top{
                            margin-bottom: 10px;
                           width: 26%;

                            &:last-child{
                                margin-right: 0;
                                margin-bottom: 0;
                            }
                            @media all and (max-width:1400px){
                                width: 32%;
                            }
                            @media all and (max-width:1200px){
                                width: 35%;
                            }
                            @media all and (max-width:980px){
                                width: 39%;
                            }
                            @media all and (max-width:768px){
                                width: 44%;
                            }
                            @media all and (max-width:640px){
                                width: 34%;
                            }
                            @media all and (max-width:480px){
                                width: 50%;
                            }
                            
                            
                        }
                    }
                    span.time{
                            color: var(--primary-color);
                    }
                }
            }
        }
    }
    div.librarySection{
        h1.heading{
            font-size: 22px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
        }
        div.libraries{
            padding-top: 30px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            div.items{
                width: 22%;
                margin-bottom: 150px;
                margin-right: 30px;
                &:nth-child(4n){
                    margin-right: 0;
                }
                @media all and (max-width:980px){
                    width: 30%;
                    margin-bottom: 125px;
                    &:nth-child(4n){
                            margin-right: 30px;
                        }
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                }
                @media all and (max-width:768px){
                    width: 47%;
                    
                    &:nth-child(3n){
                        margin-right: 30px;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:480px){
                    width: 100%;
                }
            }
        }
    }
    div.buttonContainer{
        position: absolute;
        bottom: -71px;
        width: 100%;
        z-index: 9;
        background: var(--bg-color);
    }
}