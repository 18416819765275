div.aboutRevelation {
  padding: 20% 0 0;
  @media all and (max-width:1200px){
    padding: 25% 0 5%;
  }
  @media all and (max-width:981px){
    padding: 35% 0 5%;
  }
  @media all and (max-width:768px){
    padding: 40% 0 5%;
  }
  @media all and (max-width:480px){
    padding: 70% 0 5%;
  }
  div.headContainer {
    div.glowWhite {
      position: absolute;
      top: -135px;
      left: 38%;
      border-radius: 50%;
      background-color: #f8f8f8;
      box-shadow: 
      10px 10px 86px 26px #f8f8f8,
       4px 0 79px 35px #f8f8f8, 
       10px 0 52px 10px #f8f8f8;
       width: 100px;
        height: 100px;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        opacity: 0.4;
      &:hover{
      box-shadow: 10px 10px 37px 26px #f8f8f8, 4px 0 96px 69px #f8f8f8, 10px 0 17px 2px #f8f8f8;
        opacity: 1;
    }
    @media all and (max-width:1200px){
      top: -176px;
      left: 34%;
  }

    }
    div.glowRed1 {
      position: absolute;
      top: 101px;
      left: 24%;
      border-radius: 50%;
      background-color: #e72a32;
      z-index: 8;
      box-shadow: 10px 10px 37px 26px #e72a32, 4px 0 96px 43px #e72a31, 10px 0 4px -7px #e72a32;
      width: 100px;
        height: 100px;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        opacity: 0.4;
      &:hover{
      box-shadow: 10px 10px 37px 26px #e72a32, 4px 0 96px 69px #e72a31, 10px 0 17px 2px #e72a32;
        opacity: 1;
    }
    @media all and (max-width:980px){
      top: 33px;
  }
  @media all and (max-width:480px){
    top: 106px;
    left: 9%;
}
      
    }
    div.glowRed2 {
      position: absolute;
      top: 65px;
    right: 31%;
      border-radius: 50%;
      background-color: #e72a31;
      box-shadow: 10px 10px 81px -6px #e72a31, 10px 0 112px 62px #e72a31,
        10px 0 38px 6px #e72a32;
        width: 100px;
        height: 100px;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        opacity: 0.3;
        @media all and (max-width:1200px){
          top: -25px;
          right: 19%;
      }
        @media all and (max-width:768px){
            top: 51px;
            right: 8%;
        }
      &:hover{
        opacity: 1;
    }
    }

    p {
    text-align: center;
    font-size: 22px;
    max-width: 75%;
    margin: 0 auto;
    @media all and (max-width:1600px){
      max-width: 81%;
    }
    @media all and (max-width:1400px){
      max-width: 95%;
        
      }
      @media all and (max-width:1200px){
        max-width: 80%;
        font-size: 17px;
          
        }
        @media all and (max-width:980px){
          max-width: 100%;
      }
    }
    div.revLogoContainer {
        width: 60%;
        margin: 0 auto;
        position: absolute;
        top: -192px;
        left: 50%;
        transform: translateX(-50%) !important;
        z-index: -1;
        @media all and (max-width:768px){
            top: -65px
        }
        @media all and (max-width:480px){
          width: 96%;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  div.videoContainer{
    width: 65%;
    margin: 0 auto 30px;
    padding: 15% 0 0;
    position: relative;
    z-index: 9;
    @media all and (max-width:1200px){
        padding: 21% 0 0;
    }
    @media all and (max-width:980px){
        padding: 20% 0 0;
    }
    @media all and (max-width:768px){
        padding: 23% 0 0;
        width: 72%;
    }
    @media all and (max-width:640px){
        padding: 23% 0 0;
    }
    @media all and (max-width:480px){
        padding: 41% 0 5%;
      }
    video{
        width: 100%;
        display: block;
    }
  }
  div.about{
    text-align: center;
    p{
        max-width: 70%;
        margin: 0 auto 50px;
        color: var(--white-color);
        opacity: 0.7;
        @media all and (max-width:1200px){
          font-size: 12px;
      }
      @media all and (max-width:768px){
        max-width: 90%;
    }
      
        @media all and (max-width:640px){
            font-size: 14px;
            max-width: 90%;
        }
        
    }
  }
  div.details{
    width: 65%;
    margin: 0 auto;
    padding-left: 30px;
    border-left: 2px solid var(--text-color);
    @media all and (max-width:768px){
     width: 75%;
  }
    @media all and (max-width:640px){
        width: 80%;
    }
    ul{
        li{
            p{
              @media all and (max-width:1200px){
                font-size: 12px;
            }
            }
        }
    }
  }
}
