div.forgotPassword{
    padding: 150px 0 0;
    span.error{
        font-size: 14px;
        color: var(--primary-color);
        text-align: left;
        display: block;
        margin-top: 10px;
    }
    span.success{
        font-size: 14px;
        text-align: left;
        display: block;
        margin-top: 10px;
    }
    h1.heading{
        font-size: 22px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--white-color);
        @media all and (max-width:640px){
            padding-left: 20px;
        }
    }
    div.resetEmailContainer{
        width: 50%;
        margin: 70px auto;
        text-align: center;
        background-color: var(--secondary-bg-color);
        padding: 60px 60px;
        border-radius: 6px;
        @media all and (max-width:980px){
            width: 70%;
        }
        @media all and (max-width:768px){
            width: 80%;
        }
        @media all and (max-width:480px){
            width: 90%;
            padding: 40px;
        }
        div.inputContainer{
            display: flex;
            flex-direction: column;
            label{
                text-align: left;
                color: var(--text-color);
                font-size: 16px;
                margin-bottom: 10px;
            }
            input{
                border: none;
                outline: none;
                padding: 8px 8px;
                border-radius: 5px;
            }
            button{
                width: 40%;
                margin: 30px auto 0;
                @media all and (max-width:1400px){
                    width: 47%;
                }
                @media all and (max-width:640px){
                    width: 60%;
                }
                &.buttonDisabled{
                    color: var(--text-color);
                    border: none;
                    outline: none;
                    font-size: 13px;
                    padding: 6px 30px;
                    border-radius: 6px;
                    &:hover{
                    }
                }
            }
        }
    }
}