div.tileContainer{
    &.magazine{
        width: 70%;
        margin: 0 auto;
        @media all and (max-width:980px){
            width: 82%;
        }
        @media all and (max-width:768px){
            margin-top: 30px;
         }
         div.item{
            width: 30%;
            margin-right: 30px;
    margin-bottom: 100px;
    margin-top: 20px;
    @media all and (max-width:980px){
        width: 47%;
        margin-bottom: 19px;

        &:nth-child(2){
            margin-right: 0;
        }
        
    }
    @media all and (max-width:768px){
        width: 46%;
        margin-right: 25px;
    }
    @media all and (max-width:640px){
       width: 100%;
       margin-right: 0;
    }
    &:last-child{
        margin-right: 0;
    }

         }
    }
   div.head{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
       div.left{
           display: flex;
           align-items: center;
           span.title{
               font-size: 18px;
               display: inline-block;
               margin-right: 10px;
           }
           span.icon{
               display: inline-block;
               width: 28px;
               img{
                   display: block;
                   width: 100%;
               }
           }
       }
       div.right{
   
           span.icon{
               border: 2px solid var(--white-color);
               border-radius: 50%;
               padding: 4px;
               cursor: pointer;
               transform: scale(0.8);
               width: 30px;
               height: 30px;
               display: flex;
               justify-content: center;
               align-items: center;
               &:hover svg{
                   transform: rotate(-90deg) scale(1.2);
               }
               svg{
                   transition: 0.4s ease-in-out;
                   
               }
           }
       }
   }
   div.body{
    &.buttonCard{
        div.showCard{
            div.bottom{
                padding: 4px 0 15px !important;
            }
        }
        @media all and (max-width:1600px){
            .swiper-button-prev {
                top: 37% !important;
            }
            .swiper-button-next {
                top: 37% !important;
            }
        }
        @media all and (max-width:1400px){

            .swiper-button-prev {
                top: 36% !important;
            }
            .swiper-button-next {
                top: 36% !important;
            }
        }
        @media all and (max-width:980px){

            .swiper-button-prev {
                top: 34% !important;
            }
            .swiper-button-next {
                top: 34% !important;
            }
        }
    }
    &.descriptionCard{
        @media all and (max-width:980px){

            .swiper-button-prev {
                top: 40% !important;
            }
            .swiper-button-next {
                top: 40% !important;
            }
        }
        @media all and (max-width:640px){

            .swiper-button-prev {
                top: 34% !important;
            }
            .swiper-button-next {
                top: 34% !important;
            }
        }
    }
    div.mgzine{
        display: flex;
        flex-wrap: wrap;
        
    }
    div.swiper-slide{
        div.item{
            div.showMoreOverlay{
                display: none;
            }
        }
        &:last-child{
            div.item{
                position: relative;
                    &.visibleShowAllButton{
                        div.showMoreOverlay{
                            cursor: pointer;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: var(--bg-color);
                            z-index: 9999999;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            h2{
                                font-size: 20px;
                                @media all and (max-width:980px){
                                    font-size: 16px;
                                }
                                @media all and (max-width:768px){
                                    font-size: 18px;
                                }
                            }
                        }
                        div.showCard{
                            &:hover{
                                transform: none;
                            }
                            div.bottom{
                                display: none;
                            }
                        }
                                
                    }
                    
            }

        }

    }
   }
   
}