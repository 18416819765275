:root {
  --primary-color:#e72a31;
  --bg-color:#101010;
  --secondary-bg-color:#181818;
  --white-color:#f8f8f8;
  --icons-color:#ff0103;
  --hover-color:#1d2124;
}

div.header-container {
  position: fixed;
  width: 100%;
  background-color: var(--bg-color);
  z-index: 999999;
}
div.header-container.transparentBackground {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}
div.header-container div.responsiveOpenAppContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
  border-bottom: 1px solid var(--white-color);
}
div.header-container div.responsiveOpenAppContainer div.left {
  display: flex;
  align-items: center;
}
div.header-container div.responsiveOpenAppContainer div.left div.logoContainer {
  width: 16%;
  margin-right: 20px;
}
div.header-container div.responsiveOpenAppContainer div.left div.logoContainer img {
  width: 100%;
  display: block;
}
div.header-container div.responsiveOpenAppContainer div.left div.description h5 {
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.header-container div.responsiveOpenAppContainer div.left div.description h5 {
    font-size: 14px;
  }
}
div.header-container div.responsiveOpenAppContainer div.left div.description p {
  font-size: 14px;
}
@media all and (max-width: 480px) {
  div.header-container div.responsiveOpenAppContainer div.left div.description p {
    font-size: 12px;
  }
}
div.header-container div.responsiveOpenAppContainer div.right div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--white-color);
  color: var(--white-color);
  border-radius: 30px;
  font-size: 16px;
  padding: 8px 20px;
}
@media all and (max-width: 480px) {
  div.header-container div.responsiveOpenAppContainer div.right div.buttonContainer button {
    font-size: 14px;
    padding: 6px 16px;
  }
}
div.header-container div.Headerwrapper {
  width: 90%;
  margin: 0 auto;
  height: 100px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper {
    border-bottom: none;
    flex-wrap: wrap;
    align-items: flex-start !important;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper {
    border-bottom: 1px solid var(--white-color);
    flex-wrap: nowrap;
    align-items: center !important;
  }
}
div.header-container div.Headerwrapper span.dropDownArrow {
  font-size: 8px;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper span.dropDownArrow {
    font-size: 6px;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper span.dropDownArrow {
    display: none;
  }
}
div.header-container div.Headerwrapper span.subLink {
  font-size: 14px;
  opacity: 0.8;
  font-weight: 400;
  color: var(--heading-color);
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper span.subLink {
    font-size: 13px;
  }
}
div.header-container div.Headerwrapper div.left {
  width: 16%;
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.left {
    width: 33%;
    margin: 0 auto;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.left {
    width: 40%;
    margin: 0;
  }
}
@media all and (max-width: 480px) {
  div.header-container div.Headerwrapper div.left {
    width: 52%;
  }
}
div.header-container div.Headerwrapper div.left a {
  display: block;
  width: 100%;
}
div.header-container div.Headerwrapper div.left h1 {
  width: 100%;
  display: block;
  cursor: pointer;
}
div.header-container div.Headerwrapper div.left h1 img {
  display: block;
  width: 100%;
}
div.header-container div.Headerwrapper div.center {
  width: 57%;
}
@media all and (max-width: 1500px) {
  div.header-container div.Headerwrapper div.center {
    width: 62%;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.center {
    width: 68%;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.center {
    width: 87%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.center {
    display: none;
  }
}
div.header-container div.Headerwrapper div.center ul.nav {
  padding: 0;
  justify-content: center;
}
div.header-container div.Headerwrapper div.center ul.nav a.top {
  margin-right: 28px;
}
div.header-container div.Headerwrapper div.center ul.nav a.top.underLine li.nav-links span.link::after {
  width: 100%;
}
div.header-container div.Headerwrapper div.center ul.nav a.top.liveHover li.nav-links span.link::after {
  width: 100%;
}
div.header-container div.Headerwrapper div.center ul.nav a.top.liveHover li.nav-links span.dot {
  background-color: var(--icons-color);
}
div.header-container div.Headerwrapper div.center ul.nav a.top.liveHover li.nav-links span.dot::before {
  transform: scale(2);
  animation: zoomIn 0.4s ease-in-out;
}
div.header-container div.Headerwrapper div.center ul.nav a.top:last-child {
  margin-right: 0;
}
div.header-container div.Headerwrapper div.center ul.nav a.top:first-child li span.link {
  margin-left: 6px;
}
@media all and (max-width: 1600px) {
  div.header-container div.Headerwrapper div.center ul.nav a.top {
    margin-right: 28px;
  }
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.center ul.nav a.top {
    margin-right: 25px;
  }
}
@media all and (max-width: 1300px) {
  div.header-container div.Headerwrapper div.center ul.nav a.top {
    margin-right: 20px;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(3) {
  margin-right: 28px;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(4) {
  margin-right: 28px;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(8) {
  margin-right: 28px;
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(3) {
    margin-right: 25px;
  }
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(4) {
    margin-right: 25px;
  }
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(8) {
    margin-right: 25px;
  }
}
@media all and (max-width: 1300px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(3) {
    margin-right: 20px;
  }
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(4) {
    margin-right: 20px;
  }
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(8) {
    margin-right: 20px;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links.underLine span.link::after {
  width: 100%;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links.give {
  margin-right: 28px;
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links.give {
    margin-right: 25px;
  }
}
@media all and (max-width: 1300px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links.give {
    margin-right: 20px;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links.active span.link {
  color: var(--primary-color);
  opacity: 1;
  display: inline-block;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(3), div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(4), div.header-container div.Headerwrapper div.center ul.nav li.nav-links:nth-child(8) {
  position: relative;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:hover span.dropDownArrow {
  transform: rotate(180deg);
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu {
  display: none;
  position: absolute;
  top: 35px;
  left: 0;
  padding: 0;
  width: 160px;
  border-radius: 6px;
  background-color: var(--secondary-bg-color);
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu {
    display: none;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu.prayer {
  width: 195px;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li {
  padding: 10px;
  transition: 0.4s ease-in-out;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:hover {
  background-color: var(--hover-color);
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(1) {
  animation: dropDown 0.1s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(2) {
  animation: dropDown 0.2s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(3) {
  animation: dropDown 0.3s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(4) {
  animation: dropDown 0.4s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(4) {
  animation: dropDown 0.5s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu li:nth-child(5) {
  animation: dropDown 0.6s;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links ul.submenu.active {
  display: block;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link {
  opacity: 0.8;
  font-weight: 400;
  color: var(--heading-color);
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--white-color);
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 1700px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link {
    font-size: 14px;
  }
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link {
    font-size: 13px;
  }
}
@media all and (max-width: 1300px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link {
    font-size: 11px;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links span.link {
    font-size: 10px;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:first-child {
  display: flex;
  align-items: center;
  position: relative;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:first-child span.dot {
  background-color: var(--primary-color);
  border-radius: 50px;
  height: 8px;
  width: 8px;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 1px;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.center ul.nav li.nav-links:first-child span.dot {
    margin-bottom: 3px;
  }
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:first-child span.dot::before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: transparent;
  transition: 0.4s ease-in-out;
  border: 1px solid var(--icons-color);
  border-radius: 50%;
  transform: scale(0);
  left: -1px;
  top: -2px;
  opacity: 0;
  animation: zoomOut 0.4s ease-in-out;
}
div.header-container div.Headerwrapper div.center ul.nav li.nav-links:last-child {
  margin-right: 0;
}
div.header-container div.Headerwrapper div.right {
  position: relative;
  width: 20%;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right {
    width: 16%;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right {
    width: 12%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right {
    width: 35%;
    justify-content: right;
  }
}
@media all and (max-width: 640px) {
  div.header-container div.Headerwrapper div.right {
    width: 45%;
  }
}
@media all and (max-width: 480px) {
  div.header-container div.Headerwrapper div.right {
    width: 37%;
  }
}
div.header-container div.Headerwrapper div.right div.searchContainer {
  position: absolute;
  bottom: -30px;
  right: 245px;
  animation: scale 0.5s ease-in-out;
  width: 301px;
  display: flex;
  align-items: center;
  transform: translateY(10px);
}
div.header-container div.Headerwrapper div.right div.searchContainer svg.searchIcon {
  position: absolute;
  right: 7px;
  width: 21px !important;
  height: 21px !important;
  cursor: pointer !important;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right div.searchContainer svg.searchIcon {
    width: 18px !important;
    height: 18px !important;
  }
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.right div.searchContainer {
    right: 195px;
    width: 266px;
    bottom: -20px;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right div.searchContainer {
    right: 76px;
    width: 215px;
    bottom: -10px;
  }
}
@media all and (max-width: 640px) {
  div.header-container div.Headerwrapper div.right div.searchContainer {
    width: 245px;
    right: 50%;
    margin: 0 auto;
  }
}
div.header-container div.Headerwrapper div.right div.searchContainer input {
  padding: 6px 10px;
  background-color: var(--bg-color);
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  color: var(--white-color);
  width: 100%;
  display: block;
}
div.header-container div.Headerwrapper div.right div.searchContainer input::-moz-placeholder {
  font-size: 13px;
}
div.header-container div.Headerwrapper div.right div.searchContainer input::placeholder {
  font-size: 13px;
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.right div.searchContainer input {
    padding: 3px 10px;
  }
  div.header-container div.Headerwrapper div.right div.searchContainer input::-moz-placeholder {
    font-size: 12px;
  }
  div.header-container div.Headerwrapper div.right div.searchContainer input::placeholder {
    font-size: 12px;
  }
}
div.header-container div.Headerwrapper div.right ul {
  padding: 0;
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right ul {
    align-items: flex-start !important;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul {
    align-items: center !important;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon {
  margin-right: 28px;
  position: relative;
  padding-bottom: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
div.header-container div.Headerwrapper div.right ul li.icon.profile {
  justify-content: space-between;
}
div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer {
  width: 30px;
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer {
    margin-top: 4px;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer {
    width: 20px;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer {
    width: 35px;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 30px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer img {
    height: 20px;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.icon.profile div.profileImageContainer img {
    height: 35px;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon svg {
  display: block;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(1) {
    display: none;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon:nth-child(2) {
  margin-top: 10px;
  width: 47px;
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(2) {
    width: 32px;
    margin-right: 7px;
  }
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(2) svg {
    display: block;
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(2) {
    margin-top: 0;
    align-items: flex-start !important;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(2) {
    margin-top: 10px;
    align-items: center !important;
    width: 42px;
    margin-right: 0;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(1) {
    margin-right: 4px;
  }
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(1) svg {
    display: block;
    width: 100%;
    font-size: 1.4em !important;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(1) {
    margin-right: 15px;
  }
  div.header-container div.Headerwrapper div.right ul li.icon:nth-child(1) svg {
    font-size: 1.9em !important;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon.search {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
div.header-container div.Headerwrapper div.right ul li.icon.search:hover {
  background-color: var(--hover-color);
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon.search {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right ul li.icon.search {
    margin-top: 4px;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.icon.search {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}
@media all and (max-width: 1600px) {
  div.header-container div.Headerwrapper div.right ul li.icon {
    margin-right: 17px;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.Headerwrapper div.right ul li.icon {
    margin-right: 0;
  }
}
div.header-container div.Headerwrapper div.right ul li.icon span.dropDownArrow.active {
  transform: rotate(180deg);
}
div.header-container div.Headerwrapper div.right ul li.icon svg path#drop_down_icon {
  transform: rotate(180deg);
}
div.header-container div.Headerwrapper div.right ul li.icon:last-child {
  position: relative;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu {
  display: none;
  position: absolute;
  top: 38px;
  right: 0;
  padding: 0;
  width: 190px;
  border-radius: 6px;
  z-index: 9;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a {
  display: inline-block;
  width: 100%;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(1) {
  animation: dropDown 0.1s;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(1) li {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(2) {
  animation: dropDown 0.2s;
  margin-bottom: 8px;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(2) li {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(3) {
  animation: dropDown 0.4s;
  background-color: var(--hover-color);
  border-radius: 6px;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu a:nth-child(3) li {
  border-radius: 6px;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu li {
  padding: 10px;
  background-color: var(--secondary-bg-color);
  cursor: pointer;
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu li:hover {
  background-color: var(--hover-color);
}
div.header-container div.Headerwrapper div.right ul li.icon ul.submenu.active {
  display: block;
}
div.header-container div.Headerwrapper div.right ul li.menuIcon {
  display: none;
}
@media all and (max-width: 768px) {
  div.header-container div.Headerwrapper div.right ul li.menuIcon {
    display: block;
  }
}
div.header-container div.Headerwrapper div.right div.logo {
  width: 100px;
  padding: 6px 10px;
  background-color: var(--secondary-bg-color);
  border-radius: 6px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.header-container div.Headerwrapper div.right div.logo:hover {
  background-color: var(--hover-color);
}
div.header-container div.Headerwrapper div.right div.logo:hover h1 {
  transform: scale(1.1);
}
@media all and (max-width: 1400px) {
  div.header-container div.Headerwrapper div.right div.logo {
    width: 116px;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.Headerwrapper div.right div.logo {
    display: none;
  }
}
div.header-container div.Headerwrapper div.right div.logo h1 {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
}
div.header-container div.Headerwrapper div.right div.logo h1 img {
  width: 100%;
  display: block;
}
div.header-container div.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 8;
  display: none;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.header-container div.overlay {
    display: block;
  }
}
div.header-container div.mobileMenu {
  position: fixed;
  top: 0;
  right: -330px;
  bottom: 0;
  width: 20%;
  background-color: var(--secondary-bg-color);
  z-index: 9;
  padding: 40px 0;
  display: none;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.header-container div.mobileMenu {
    animation: slideRight 0.4s ease-in-out;
    animation-fill-mode: forwards;
    width: 25%;
  }
  div.header-container div.mobileMenu.active {
    display: block;
    right: 0;
    animation: slideLeft 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.mobileMenu {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.mobileMenu {
    width: 35%;
  }
}
@media all and (max-width: 640px) {
  div.header-container div.mobileMenu {
    width: 45%;
  }
}
@media all and (max-width: 480px) {
  div.header-container div.mobileMenu {
    width: 55%;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.mobileMenu {
    width: 70%;
  }
}
div.header-container div.top {
  position: absolute;
  top: 20px;
  right: 30px;
}
div.header-container div.top span {
  display: block;
  font-size: 30px;
  font-weight: 900;
  color: var(--primary-color);
}
div.header-container ul.menu {
  margin-top: 30px;
  padding-left: 10px;
}
div.header-container ul.menu li.nav-links {
  flex-direction: column;
  padding-left: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(70, 69, 69);
}
div.header-container ul.menu li.nav-links a {
  display: inline-block;
  display: flex;
  align-items: center;
}
div.header-container ul.menu li.nav-links:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
}
div.header-container ul.menu li.nav-links:first-child::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: block;
  margin-right: 5px;
}
div.header-container ul.menu li.nav-links ul.submenu {
  display: none;
  animation: dropDown 0.4s ease-in-out;
}
div.header-container ul.menu li.nav-links ul.submenu.active {
  display: block;
}
div.header-container ul.menu li.nav-links ul.submenu li {
  margin-bottom: 10px;
}
div.header-container ul.menu li.nav-links ul.submenu li span {
  color: var(--primary-color);
}
@keyframes dropDown {
  0% {
    transform: translateY(-9px);
    opacity: 0;
    height: 0;
    transition: 0.3s ease-in-out;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    height: 100%;
    transition: 0.6s ease-in-out;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes slideLeft {
  0% {
    right: -330px;
  }
  100% {
    right: 0;
  }
}
@keyframes slideRight {
  0% {
    right: 0;
  }
  100% {
    right: -330px;
  }
}
@keyframes scale {
  0% {
    width: 0;
    transform: scale(0);
    bottom: 0;
    right: 245px;
  }
  100% {
    width: 301px;
    transform: scale(1) translateY(10px);
    bottom: -30px;
    right: 245px;
  }
}
@media all and (max-width: 1400px) {
  @keyframes scale {
    0% {
      width: 0;
      transform: scale(0);
      bottom: 0;
      right: 195px;
    }
    100% {
      width: 266px;
      transform: scale(1) translateY(10px);
      bottom: -20px;
      right: 195px;
    }
  }
  @keyframes scaleOut {
    0% {
      transform: scale(1);
      bottom: -40px;
      right: 134px;
    }
    100% {
      transform: scale(0);
      bottom: 0;
      right: 195px;
    }
  }
}
@media all and (max-width: 1200px) {
  @keyframes scale {
    0% {
      width: 0;
      transform: scale(0);
      bottom: 0;
      right: 76px;
    }
    100% {
      width: 215px;
      transform: scale(1) translateY(10px);
      bottom: -10px;
      right: 76px;
    }
  }
}
@media all and (max-width: 640px) {
  @keyframes scale {
    0% {
      width: 0;
      transform: scale(0);
      bottom: 0;
      right: 118px;
    }
    100% {
      width: 245px;
      transform: scale(1) translateY(10px);
      bottom: -10px;
      right: 50%;
      margin: 0 auto;
    }
  }
}
@keyframes scaleOut {
  0% {
    width: 230px;
    transform: scale(1);
    bottom: -40px;
    right: 241px;
  }
  100% {
    width: 0;
    transform: scale(0);
    bottom: 0;
    right: 241px;
  }
}
@keyframes scaleInInput {
  0% {
    width: 100%;
    padding: 0;
    border: 1px solid var(--primary-color);
    border-radius: none;
  }
  100% {
    width: 0%;
    padding: 6px 10px;
    border: 1px solid var(--primary-color);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}/*# sourceMappingURL=Header.css.map */