div.searchallshows{
    padding: 0 0 60px;
    @media all and (max-width:1200px){
        padding: 0 0 10px;
    }
    h1.heading{
        font-size: 24px;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--white-color);
        margin-bottom: 20px;
        @media all and (max-width:640px){
            padding-left: 20px;
        }
    }
    div.showsList{
        display: flex;
        flex-wrap: wrap;
        @media all and (max-width:640px){
            padding: 0 20px;
        }
        div.items{
            width: 22%;
            margin-bottom: 150px;
            margin-right: 28px;
            &:nth-child(4n){
                margin-right: 0;
            }
            @media all and (max-width:980px){
                width: 30%;
                &:nth-child(4n){
                    margin-right: 28px;
                }
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
            @media all and (max-width:768px){
                width: 47%;
                
                &:nth-child(4n){
                    margin-right: 0;
                }
                &:nth-child(3n){
                    margin-right: 20px;
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
            @media all and (max-width:640px){
                width: 100%;
                margin-right: 0 !important;
                
    
            }
        }
    }
}