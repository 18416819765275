div.leaderShipContainer{
    padding: 0 0 100px;
    div.wrapper{
        h1.heading{
            font-size: 28px;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--white-color);
            margin-bottom: 30px;
            @media all and (max-width:640px){
                padding-left: 10px;
            }
            @media all and (max-width:480px){
                font-size: 24px;
            }
        }
    }
}