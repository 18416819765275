@mixin buttonStyle{
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    outline: none;
    font-size: 14px;
    padding: 6px 30px;
    border-radius: 6px;
    @media all and (max-width:1400px){
            font-size: 13px;
    }
    @media all and (max-width:1200px){
        padding: 5px 25px;
    }
    @media all and (max-width:480px){
        padding: 5px 11px;
        font-size: 11px;
    }
    @media all and (max-width:360px){
        padding: 5px 12px;
    }
  }
div.showContainer { 

	div.details { 
        background:
        linear-gradient(var(--secondary-bg-color) 50%, #fff 0) 0 var(--_d, 0) /100% 200% no-repeat,
        linear-gradient(var(--secondary-bg-color) 50%, var(--hover-color) 0) 0 var(--_d, 0) /100% 200% no-repeat;
        -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
        transition: 0.5s;
        padding: 6px 15px 30px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        z-index: -1;
        &:hover{
            --_d: 100%;
            
        }
        &:hover div.contents div.right div.buttonContainer button.hover-effect{
            display: block;
            animation: hoverEffect 0.4s;
            transition: 0.4s;
        }

        h3 { 
            color: var(--icons-color);
            font-size: 14px;
            margin-bottom: 10px;
            z-index: 999;
            
        }
        div.contents{
            justify-content: space-between;
            align-items: center;
            @media all and (max-width:768px){
                flex-direction: column;
            }
            div.left { 
                width: 25%;
                @media all and (max-width:1400px){
                    width: 29%;
                }
                @media all and (max-width:980px){
                    width: 44%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                }
                div.thumbnail { 
                    width: 100%;
                    img { 
                        width: 100%;
                        display: block;
                        border-radius: 6px;
                    }
                }
            }
    
            div.right { 
                width: 73%;
                @media all and (max-width:1400px){
                    width: 69%;
                }
                @media all and (max-width:980px){
                    width: 54%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                    margin-top: 20px;
                }
                h1.title { 
                    font-size: 22px;
                    @media all and (max-width:1400px){
                        font-size: 19px;
                    }
                }
    
                p.description { 
                    font-size: 15px;
                    font-weight: 400;
                    opacity: 0.8;
                    letter-spacing: .6px;
                    margin-top: 15px;
                    @media all and (max-width:1400px){
                        font-size: 13px;
                    }
                    @media all and (max-width:1200px){
                    margin-top: 8px;
                    margin-bottom: 8px;
                    }
                }
                div.buttonContainer{
                    display: flex;
                    button { 
                        @include buttonStyle;
                        margin-right: 20px;
                        border: 1px solid var(--primary-color);
                        transition: 0.4s ease-in-out;

                        &:last-child{
                            margin-right: 0;
                        }
                        &.hover-effect{
                            display: none;
                            animation: hoverEffect 0.4s;
                        transition: 0.4s ease-in-out;
                            &:hover{
                                color: var(--primary-color);
                            }

                        }
                        span{
                            transition: 0.4s ease-in-out;
                        }
                        svg{
                            transition: 0.4s ease-in-out;

                        }
                        &:hover{
                            background-color: var(--white-color);
                            color: var(--primary-color);
                            span{
                                color: var(--primary-color);
                            }
                            span.onDemand{
                                display: inline-block;
                                transform: translateY(-2px);

                            }
                            svg{
                                fill: var(--primary-color);
                            }
                        }
                        
                    }
                }
            }
        }
	}
}

@keyframes hoverEffect{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;

    }

}