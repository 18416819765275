div.israel { 
    padding: 0 0 0;
	div.wrapper { 

		h1.heading { 
            font-size: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
            @media all and (max-width:641px){
                padding-left: 20px;
            }
		}

		div.videoContainer { 
            width: 80%;
            margin: 30px auto 0;
            @media all and (max-width:480px){
                width: 90%;
             }
			video { 
                width: 100%;
                display: block;
			}
		}

		div.details { 
            width: 80%;
            margin: 20px auto 0;
            @media all and (max-width:480px){
                width: 90%;
             }
             h1.title{
                font-size: 22px;
                color: var(--white-color);
                margin-bottom: 10px;
                opacity: 0.9;
                @media all and (max-width:640px){
                    font-size: 20px;
                }
                @media all and (max-width:480px){
                    font-size: 18px;
                }
             }
			p { 
                font-size: 15px;
                margin-bottom: 20px;
                b{
                    color: var(--white-color);
                }
                @media all and (max-width:768px){
                    font-size: 14px;
                }
			}
            span{
                display: block;
                color: var(--text-color);
                font-weight: 600;
            }
			span.link { 
                display: inline-block;
                color: var(--primary-color);
                font-size: 16px;
                cursor: pointer;
                @media all and (max-width:480px){
                    font-size: 14px;
                 }
			}
		}

		
	}
    div.gallery { 
        display: flex;
        margin-top: 30px;
        position: relative;
        .swiper-slide {
            margin-right: 1px !important;
            width: 50% !important;
            @media all and (min-width:320px){
                width: 100% !important;
            }
            @media all and (min-width:640px){
                width: 50% !important;
            }
        }
        .swiper{
            padding: 0 !important;
            width: 100% !important;
        }
        div.right{
            position: absolute;
            right: 0;
            height: 100%;
            background: transparent linear-gradient(96deg, #10101000 11%, #101010 100%) 0% 0% no-repeat padding-box;
            width: 10%;
            z-index: 9;
            top: 0;
        }
        div.left{
            position: absolute;
            left: 0;
            height: 100%;
            background: transparent linear-gradient(269deg, #10101000 11%, #101010 100%) 0% 0% no-repeat padding-box;
            width: 10%;
            z-index: 9;
            top: 0;
        }
        div.imageContainer { 
            width: 100%;
            span{
                width: 100% !important;
                display: block !important;
            }
            img { 
                width: 100%;
                display: block;
                height: 290px;
                object-fit: cover;
                @media all and (max-width:980px){
                    height: 200px;
                }
                @media all and (max-width:641px){
                    height: 170px;
                }
                @media all and (max-width:480px){
                    height: 130px;
                 }
            }
        }
    }

   
}