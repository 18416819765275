div.spotify{
    padding: 30px 0;
    div.top{
        div.head{
            background-color: #008000;
            padding: 15px 50px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            border-radius: 20px;
            margin-bottom: 20px;
            @media all and (max-width:640px){
                width: 95%;
                flex-direction: column;
                margin:  0 auto 20px;
            }
            div.left{
                @media all and (max-width:640px){
                    margin-bottom: 10px;
                }
                h1{
                    font-size: 58px;
                    font-family: 'Lato', sans-serif!important;
                    @media all and (max-width:640px){
                        font-size: 38px;
                    }
                }
            }
            div.right{
                div.imageContainer{
                    width: 150px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    div.bottom {

        div.podcastsContainer {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media all and (max-width:768px){
                flex-direction: column;
            }
            div.podcast {
                width: 49%;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                @media all and (max-width:768px){
                    width: 95%;
                    margin: 0 auto 20px;
                }
                iframe{
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}