div.accountSettings { 
  padding: 0 0 130px;
	div.wrapper { 

		div.head { 

			h1.heading { 
				font-size: 24px;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--heading-color);	
			}
			@media all and (max-width:640px){
				padding-left: 20px;
			}
		}

		div.contents { 
			display: flex;
			justify-content: space-between;
			width: 80%;
			margin: 0 auto;
			padding-top: 100px;
			@media all and (max-width:1200px){
				width: 90%;
			}
			@media all and (max-width:980px){
				width: 100%;
			}
			@media all and (max-width:768px){
				flex-direction: column;
				justify-content: center;
			}
			@media all and (max-width:640px){
				padding: 80px 20px;
			}
			div.left { 
				width: 40%;
				@media all and (max-width:768px){
					width: 100%;
				}
				div.profileImageContainer { 
					width: 250px;
					text-align: center;
					@media all and (max-width:768px){
						margin:  0 auto;
					}
					img { 
						display: block;
						width: 100%;
						border-radius: 50%;
						height: 250px;
						object-fit: cover;
					}
					input{
						display: none;
					}
					label { 
						color: var(--primary-color);
						font-size: 14px;
						display: inline-block;
						margin-top: 15px;
						cursor: pointer;
					}
				}
			}

			div.right { 
				width: 55%;
				@media all and (max-width:768px){
					width: 100%;
				}
				div.formSection { 

					form { 
						input{
							border-radius: 6px;
							outline: none;
							border: none;
							padding: 8px 10px;
							width: 100%;
							font-size: 14px;
						}
						span.error{
							font-size: 12px;
							color: var(--primary-color);
						}
						span.success{
							font-size: 12px;
						}
						label { 
							font-size: 14px;
							color: var(--heading-color);
						}
						select{
							-webkit-appearance: none;
							padding: 8px 6px;
							border: none;
							outline: none;
							border-radius: 6px;
							font-size: 14px;
							display: block;
							width: 100%;
						}
						div.half { 
							display: flex;
							justify-content: space-between;
							margin-bottom: 30px;
							@media all and (max-width:480px){
								flex-direction: column;
								margin-bottom: 20px;
							}
							div.fname,div.email { 
								width: 49%;
								
								@media all and (max-width:480px){
									width: 100%;
									margin-bottom: 20px;
								}
								
							}
							div.email{
								input{
									background-color: #fff;
								}
							}

							div.lname,div.country { 
								width: 49%;
								@media all and (max-width:480px){
									width: 100%;
								}
								label { 

								}

								#lname { 

								}
							}
						}

						div.full { 

							div.password { 
								width: 49%;
								@media all and (max-width:480px){
									width: 100%;
								}
								label { 

								}

								#password { 

								}
							}

							span { 
								color: var(--primary-color);
								font-size: 12px;
							}
						}

						div.buttonContainer { 
							margin-top: 30px;
							button.bg-button { 

							}
						}
					}
				}
			}
		}
	}
}