div.presenters{
    padding: 0 0 0;
    @media all and (max-width:640px){
    padding: 0 20px 0;
        
    }
    h1.heading{
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        margin-bottom: 20px;
    }
    div.content{
        h1.title{
            text-align: center;
            font-size: 22px;
            margin-bottom: 20px;
        }
        p{
            text-align: center;

        }
        div.presentersDetails{
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
                div.item{
                    width: 23%;
                    margin-bottom: 160px;
                    margin-right: 46px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    p{
                        text-align: left;
                    }
                    @media all and (max-width:2200px){
                        margin-right: 42px;
                    }
                    @media all and (max-width:1980px){
                        margin-right: 37px;
                    }
                    @media all and (max-width:1768px){
                        margin-right: 34px;
                    }
                    @media all and (max-width:1600px){
                        margin-right: 29px;
                    }
                    @media all and (max-width:1400px){
                        margin-right: 25px;
                    }
                    @media all and (max-width:1200px){
                        margin-right: 23px;
                    }
                    @media all and (max-width:980px){
                        width: 32%;
                        margin-right: 13px;
                        &:nth-child(4n){
                            margin-right: 13px;
                        }
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                    }
                    @media all and (max-width:768px){
                        width: 31%;
                        margin-right: 11px;    
                    }
                    @media all and (max-width:640px){
                        width: 48%;   
                        margin-right: 10px; 
                        &:nth-child(3n){
                            margin-right: 10px;
                        }
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                    }
                    @media all and (max-width:480px){
                        width: 100%;    
                        margin-right: 0; 
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                    }
                }
        }
    }
}