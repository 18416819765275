div.products{
    background-color: #000;
    padding: 60px 0;
    div.watchSection{
    width: 48%;
    margin: 0 auto;
    @media all and (max-width:980px){
            width: 67%;
        }
    h1.title{
        font-size: 22px;
        @media all and (max-width:640px){
            padding-left: 20px;
        }
    }
    ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 30px auto 0;
        justify-content: space-between;
        @media all and (max-width:768px){
            justify-content: center;
        }
        li{
            width: 16%;
            margin: 0 auto;
            transition: 0.4s ease-in-out;
            text-align: center;
            cursor: pointer;
            @media all and (max-width:768px){
                width: 22%;
                margin-bottom: 20px;
                &:nth-child(5),&:nth-child(6){
                    margin: 0;
                }
            }
            @media all and (max-width:480px){
                width: 35%;
                &:nth-child(5),&:nth-child(6){
                    margin: 0 auto;
                }
            }
            img{
                width: 60%;
                display: block;
                margin: 0 auto;
            }
            span.name{
                font-size: 14px;
                color: var(--text-color);
                display: inline-block;
                &::after{
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background: var(--white-color);
                    transition: width .4s;
                }
                

            }
            &:hover{
                transform: scale(1.1);
                span.name{
                    &::after{
                        width: 100%;
                    }
                }
                
            }
        }
    }
}
}