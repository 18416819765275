div.ChangePassword { 
  padding: 0 0 120px;
span.error{
    font-size: 14px;
    color: var(--primary-color);
    display: block;
    margin-top: 10px;
    &.center{
        text-align: center;
    }
}
span.success{
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 10px;
}
h1.heading { 
      font-size: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--white-color);
      @media all and (max-width:640px){
          font-size: 22px;
          margin: 0 20px;
      }
}

div.contents { 
      margin-top: 40px;
      @media all and (max-width:480px){
          padding: 0 10px;
      }
  



  div.formSection { 
          width: 30%;
          margin: 0 auto;
          @media all and (max-width:1200px){
              width: 55%;
          }
          @media all and (max-width:980px){
              width: 75%;
          }
          @media all and (max-width:640px){
              width: 85%;
          }
          form{
            div.inputContainer{
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                &:nth-child(3){
                    margin-bottom: 60px;
                }
                label{
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                input{
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    padding: 8px 10px;
                    font-size: 14px;
                }
            }
            div.buttonContainer{
                button{
                    margin: 0 auto;

                }
            }
          }
  }
}
}