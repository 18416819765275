
div.subscribe { 
  padding: 60px 0 100px;
  @media all and (max-width:640px){
    padding: 200px 0 100px;
  }
  span.error{
    font-size: 14px;
    color: var(--primary-color);
    &.contact{
      text-align: center;
      display: block;
    }
  }
  span.success{
    font-size: 14px;
    text-align: center;
    display: block;
  }

	div.wrapper { 

		div.subscribeContainer { 
      background-color: var(--secondary-bg-color);
      position: relative;
      padding: 100px 90px;
      border-radius: 5px;
      width: 90%;
      margin: 0 auto;
      @media all and (max-width:980px){
        padding: 100px 40px;
      }
			div.logoContainer { 
        width: 300px;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media all and (max-width:980px){
          width:250px;
          top: -50px;
        }
				img { 
          display: block;
          width: 100%;
				}
			}

			h1.heading { 
        text-align: center;
        font-size: 22px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        max-width: 40%;
        margin: 0 auto;
        @media all and (max-width:1400px){
          max-width: 40%;
        }
        @media all and (max-width:980px){
          max-width: 50%;
        }
        @media all and (max-width:640px){
          max-width: 60%;
        }
        @media all and (max-width:480px){
          max-width: 100%;
        }
			}

			div.formSection { 
        margin:  40px 0 0;
				form { 
          div.inputSections{
            width: 60%;
            margin:  0 auto 40px  ;
            @media all and (max-width:980px){
              width: 80%;
            }
            @media all and (max-width:768px){
              width: 90%;
            }
            div.half { 
              display: flex;
              justify-content: space-between;
              @media all and (max-width:640px){
                flex-direction: column;
              }
              div.inputContainer { 
                display: flex;
                flex-direction: column;
                width: 47%;
                margin-bottom: 10px;
                @media all and (max-width:640px){
                  width: 100%;
                }
                label { 
                  font-size: 13px;
                  margin-bottom:5px;
                  color: var(--white-color);
                }
  
                input { 
                  outline: none;
                  border: none;
                  border-radius: 5px;
                  font-size: 14px;
                  padding: 6px 10px;
                }
                select{
                  outline: none;
                  border: none;
                  border-radius: 5px;
                  font-size: 14px;
                  padding: 6px 10px;
                }
              }
            }
            div.full{
              width: 100%;
              label{
                font-size: 13px;
                  margin-bottom:5px;
                  color: var(--white-color);
                  display: block;
              }
              input{
                outline: none;
                  border: none;
                  border-radius: 5px;
                  font-size: 14px;
                  padding: 6px 10px;
                  width: 47%;
                  @media all and (max-width:640px){
                    width: 100%;
                  }
              }
            }
          }

          div.checkboxSection{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 20%;
            margin:  0 auto;
            @media all and (max-width:980px){
              width: 25%;;
            }
            @media all and (max-width:768px){
              width: 30%;
            }
            @media all and (max-width:640px){
              width: 40%;
            }
            @media all and (max-width:480px){
              width: 60%;
            }
            div.inputContainer{
              label.Squarecontainer{
                font-size: 14px;
                color: var(--text-color);
              }
            }
          }
					div.full { 

						p { 
              font-size: 14px;
              text-align: center;
              &:nth-child(2){
                margin-bottom: 40px;
              }
              &:nth-child(3){
                margin-bottom: 40px;
              }
              @media all and (max-width:640px){
                font-size: 13px;
              }
						}
					}
          p.contact{
            font-size: 14px;
              text-align: center;
              @media all and (max-width:640px){
                font-size: 13px;
              }
          }

					div.buttonContainer { 
            text-align: center;
            margin-top: 30px;
						button { 
              width: 23%;
              padding: 6px 8px;
              margin:  0 auto;
              @media all and (max-width:768px){
                width: 30%;
              }
              @media all and (max-width:640px){
                width: 35%;
              }
              @media all and (max-width:480px){
                width: 50%;
              }
						}
					}
				}
			}
      div.magazineSection{
        display: flex;
        width: 80%;
        margin:  30px auto 0;
        align-items: center;
        @media all and (max-width:768px){
         flex-direction: column;
        }
        @media all and (max-width:640px){
          width: 90%;
        }
        @media all and (max-width:480px){
          width: 100%;
        }
        div.left{
          width: 40%;
          margin-right: 50px;
          
          @media all and (max-width:1200px){
            margin-right: 40px;
          }
          @media all and (max-width:980px){
            margin-right: 30px;
          }
          @media all and (max-width:768px){
            width: 60%;
            margin-right: 0;
            margin-bottom: 10px;
          }
          div.imageContainer{
            width: 100%;
            img{
              display: block;
              width: 100%;
              border-radius: 5px;
            }
          }
        }
        div.right{
          right: 60%;
          h1{
            font-size: 22px;
            margin-bottom: 40px;
            @media all and (max-width:1400px){
              font-size: 20px;
            }
            @media all and (max-width:980px){
              font-size: 18px;
            }
            @media all and (max-width:768px){
              margin-bottom: 10px;
              font-size: 16px;
            }
            
          }
          div.downloadbuttonContainer{
            svg{
                color: var(--primary-color);
            }
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--primary-color);
                    background-color: transparent;
                    color: var(--primary-color);
                    padding: 5px 20px;
                    font-size: 12px;
                    border-radius: 6px;
                    width: 50%;
                    transition: 0.3s ease-in-out;
                    @media all and (max-width:980px){
                      width: 60%;
                    }
                    @media all and (max-width:768px){
                      width: 80%;
                    }
                    svg{
                      margin-right: 10px;
                      
                    }
                    &:hover{

                        border-color: var(--icons-color);
                        color: var(--icons-color);
                        svg{
                            polygon{
                                fill: var(--icons-color);
                            }
                        }
                    }
            }
        }
        }
      }
		}
	}
  .Squarecontainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .Squarecontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .Squarecheckmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .Squarecontainer:hover input  {
    ~ .Squarecheckmark{
        background-color: #ccc;
    }
  }
  
  /* When the checkbox is checked, add a blue background */
  .Squarecontainer input:checked {
    ~ .Squarecheckmark {

        background-color: var(--primary-color);
    }
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Squarecheckmark {
    &::after{

        content: "";
        position: absolute;
        display: none;
    }
  }
  
  /* Show the checkmark when checked */
  .Squarecontainer input:checked  {
    ~ .Squarecheckmark{
        &::after{

            display: block;
        }
    }
  }
  
  /* Style the checkmark/indicator */
  .Squarecontainer .Squarecheckmark {
    &::after{

        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(45deg) ;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;  
    }
  }
  
}


