div.Reminder-favouritesSection{
    padding: 0 0 130px;
    position: relative;
    @media all and (max-width:640px){
        padding: 0 30px 60px;
    }
    h1.heading{
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        margin-bottom: 30px;
    }
    div.favourites{
        h1.nodata{
            text-align: center;
            color: var(--primary-color);
            padding:80px 0
        }
        div.head{
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
               div.left{
                   display: flex;
                   align-items: center;
                   span.title{
                       font-size: 18px;
                       display: inline-block;
                       margin-right: 10px;
                   }
                   span.icon{
                       display: inline-block;
                       width: 18px;
                       margin-bottom: 5px;
                       img{
                           display: block;
                           width: 100%;
                       }
                   }
               }
               div.right{
           
                   span.icon{
                       border: 2px solid var(--white-color);
                       display: inline-block;
                       border-radius: 50%;
                       padding: 4px;
                       cursor: pointer;
                       width: 30px;
               height: 30px;
               display: flex;
               justify-content: center;
               align-items: center;
                       transform: scale(0.8);
                       
                       &:hover svg{
                           transform: rotate(-90deg) scale(1.2);
                       }
                       svg{
                           transition: 0.4s ease-in-out;
                           
                       }
                   }
               }
           }
        div.favouritesContainer{
            display: flex;
            flex-wrap: wrap;
        margin-bottom: 140px;

            div.item{
                width: 22%;
                margin-right: 35px;
                &:last-child{
                    margin-right: 0;
                }
                @media all and (max-width:980px){
                    width: 44%;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    margin-bottom: 130px;
                    &:nth-child(3),&:nth-child(4){
                        margin-bottom: 0;
                    }
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(3){
                        margin-bottom: 130px;
                    }
                }
            }
        }
        div.tileContainer{
    
            div.left{
        
                span.icon{
                    width: 20px;
                    svg{
                        fill: var(--primary-color);
                    }
                }
            }
        }
    }
    div.reminders{
    }
    div.gradientBg{
        bottom: 123px !important;
        height: 68px !important;
        button.showMore{
            margin-top: 94px !important;
        }
        @media all and (max-width:640px){
            button.showMore{
                margin-top: 71px !important;
            }
            bottom: 59px !important;
        }
    }
}