
div.ondemand{
    padding: 0 0 0;
    .swiper-pagination-bullet{
        background-color: #ffff !important;
        opacity: 1 !important;
    }
    .swiper-pagination-bullet-active{
        background-color: var(--primary-color) !important;
        opacity: 1 !important;
    }
    @media all and (max-width:1200px){
        .swiper-slide{
            margin-right: 0 !important;
        }
    }
    @media all and (max-width:640px){
        padding: 0 20px 80px;
    }
    div.wrapper1{
        width: 70%;
        margin: 0 auto;
        @media all and (max-width:1200px){
            width: 80%;
        }
        @media all and (max-width:640px){
            width: 100%;
        }
    }
    
    div.swiper-pagination{
        bottom: 79px !important;
        left: 0;
        width: 100%;
        z-index: 999 !important;
        @media all and (max-width:1200px){
                bottom: 103px !important;
        }
        @media all and (max-width:980px){
            bottom: 126px !important;
        }
        @media all and (max-width:768px){
            bottom: 170px !important;
        }
        @media all and (max-width:640px){
            bottom: 188px !important;
        }
        @media all and (max-width:480px){
            bottom: 177px !important;
        }
    }
    div.spotlight{
        .swiper {
            padding: 0 !important
        }
        div.featuredContainer{
            position: relative;
            padding: 36px 50px;
            @media all and (max-width:480px){
                padding: 30px 20px;
            }
            div.gradientBg{
                bottom: 0 !important;
            }
            div.background{
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0;
                right: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                filter: blur(10px);
                overflow: hidden;
                img{
                    display: block;
                    width: 100%;
                }
                @media all and (max-width:640px){
                    top: 32px !important;
                }
                @media all and (max-width:480px){
                    top: 8px !important;
                }
            }
            div.imageContainer{
                display: block;
                max-width: 54%;
                height: auto;
                margin: 0 auto;
                @media all and (max-width:768px){
                    max-width: 75%;
                }
                @media all and (max-width:640px){
                    max-width: 90%;
                }
                @media all and (max-width:480px){
                    max-width: 80%;
                }
                img{
                    display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                border-radius: 10px;
                }
            }
        }
        div.details{
            background-color: var(--bg-color);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            @media all and (max-width:480px){
                padding: 10px 0;
             }
            @media all and (max-width:768px){
                flex-direction: column;
            }
            div.left{
                width: 60%;
                @media all and (max-width:1400px){
                    width: 70%;
                }
                @media all and (max-width:1200px){
                    width: 60%;
                }
                @media all and (max-width:980px){
                    width: 70%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                h1{
                    font-size: 22px;
                    @media all and (max-width:768px){
                        font-size: 20px;
                    }
                    @media all and (max-width:480px){
                       font-size: 18px;
                    }
                }
                p{
                    margin-bottom: 0;
                    font-size: 14px;
                    @media all and (max-width:768px){
                        font-size: 13px;
                    }
                }
            }
            div.right{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: right;
                @media all and (max-width:1400px){
                    width: 30%;
                }
                @media all and (max-width:1200px){
                    width: 40%;
                }
                @media all and (max-width:980px){
                    width: 45%;
                }
                @media all and (max-width:768px){
                    width: 100%;
                    justify-content: left;
                }
                @media all and (max-width:480px){
                    justify-content: center;
                }
                button.transparent{
                    padding: 4px 3px;
                    width: 100%;
                    span{
                        color: var(--primary-color);
                        margin: 0 10px;
                        transition: 0.4s ease-in-out;
                    }
                    @media all and (max-width:1200px){
                        padding: 3px ;
                    }
                    
                    
                    &:hover{
                        span{
                            color: var(--icons-color);
                        }
                    }
                    
                    
                    svg{
                        width: 13px !important;
                        height: 13px !important;
                        line{
                            transition: 0.3s ease-in-out;
                            stroke: var(--primary-color) !important;
                        }
                    }
                    &:hover{
                        svg{
                            line{
                                stroke: var(--icons-color) !important;
                            }
                        }
                    }
                    
                    @media all and (max-width:1200px){
                    }
                    @media all and (max-width:980px){
                    }
                    @media all and (max-width:768px){
                        font-size: 12px;
                    }
                    @media all and (max-width:480px){
                    }
                    @media all and (max-width:360px){
                        padding: 3px 6px;
                    }
                }
                button.transparent{
                    margin-right: 20px;
                    width: 25%;
                    @media all and (max-width:1400px){
                        width: 40%;
                    }
                    @media all and (max-width:1200px){
                        width: 40%;
                    }
                    @media all and (max-width:980px){
                        width: 50%;
                    }
                    @media all and (max-width:768px){
                        font-size: 12px;
                        width: 20%;
                    }
                    @media all and (max-width:640px){
                        font-size: 12px;
                        width: 27%;
                    }
                    @media all and (max-width:480px){
                        width: 50%;
                    }
                    @media all and (max-width:360px){
                        width: 65%;
                        padding: 6px 6px;
                    }
                    
                }
                button.bg-button{
                    padding: 4px 16px 4px 3px;
                    width: 25%;
                    justify-content: right;
                    font-size: 12px;
                    svg{
                        height: 20px !important;
                        width: 20px !important;
                    }
                    @media all and (max-width:1400px){
                        width: 43%;
                    }
                    @media all and (max-width:1200px){
                        padding: 3px 16px 4px 3px ;
                        width: 35%;
                    }
                    @media all and (max-width:980px){
                        width: 50%;
                    }
                    @media all and (max-width:768px){
                        font-size: 12px;
                        width: 20%;
                        padding: 2px 16px 2px 3px;
                    }
                    @media all and (max-width:640px){
                        width: 28%;
                    }
                    @media all and (max-width:480px){
                        width: 40%;
                    }
                }
            }
        }
    }
    div.librarySection{
        margin-top: 6px;
        h1.heading{
            font-size: 22px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
        }
        div.libraries{
            padding-top: 30px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            div.items{
                width: 23%;
                margin-bottom: 150px;
                margin-right: 24px;
                @media all and (max-width:1600px){
                   margin-right: 20px;
                }
                @media all and (max-width:1400px){
                    margin-right: 19px;
                 }
                @media all and (max-width:1200px){
                    width: 30%;
                    margin-right: 15px;
                    margin-bottom: 125px;
                }
                @media all and (max-width:980px){
                    width: 30%;
                }
                @media all and (max-width:980px){
                    width: 48%;
                    margin-right: 15px;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-right: 0;
                }
                @media all and (max-width:480px){
                    width: 100%;
                }
            }
        }
    }
}