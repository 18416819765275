@charset "UTF-8";
:root {
  --primary-color:#e72a31;
  --bg-color:#101010;
  --secondary-bg-color:#181818;
  --white-color:#f8f8f8;
  --icons-color:#ff0103;
  --hover-color:#1d2124;
  --text-color:#979797;
  --heading-color:#e5e5e5;
}

div.liveContainer {
  padding: 0 0 0;
}
div.liveContainer div.wrapper div.liveVideoContainer {
  width: 100%;
}
div.liveContainer div.wrapper div.liveVideoContainer .vjs-big-play-button {
  display: none !important;
}
@media all and (max-width: 640px) {
  div.liveContainer div.wrapper div.liveVideoContainer {
    width: 90%;
    margin: 0 auto;
  }
}
div.liveContainer div.wrapper div.liveVideoContainer img {
  width: 100%;
  display: block;
}
div.liveContainer div.wrapper h1.nowPlaying {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.liveContainer div.wrapper h1.nowPlaying {
    padding-left: 10px;
  }
}
div.scheduleContainer h1.heading {
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 30px;
}
@media all and (max-width: 640px) {
  div.scheduleContainer h1.heading {
    padding-left: 10px;
  }
}
div.scheduleContainer div.daysContainer div.day {
  text-align: center;
}
div.scheduleContainer div.daysContainer div.day button {
  background-color: var(--secondary-bg-color);
  color: var(--white-color);
  border: none;
  outline: none;
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 6px;
  width: 90%;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 640px) {
  div.scheduleContainer div.daysContainer div.day button {
    width: 68%;
  }
}
div.scheduleContainer div.daysContainer div.day button.active {
  background-color: var(--primary-color);
}
@media all and (min-width: 980px) {
  div.scheduleContainer div.daysContainer div.day button:hover {
    background-color: var(--hover-color);
  }
  div.scheduleContainer div.daysContainer div.day button.active:hover {
    background-color: var(--primary-color);
  }
}
div.scheduleContainer div.earlierShows {
  text-align: center;
  margin-top: 30px;
}
div.scheduleContainer div.earlierShows button {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.scheduleContainer div.earlierShows button {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.scheduleContainer div.earlierShows button {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.scheduleContainer div.earlierShows button {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.scheduleContainer div.earlierShows button {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.scheduleContainer div.earlierShows button {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.scheduleContainer div.earlierShows button {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.scheduleContainer div.earlierShows button {
    width: 50%;
  }
}
div.scheduleContainer div.earlierShows button svg {
  transition: 0.3s ease-in-out;
}
div.scheduleContainer div.earlierShows button:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
div.scheduleContainer div.earlierShows button:hover svg {
  fill: var(--icons-color);
}

div.schedulebox {
  padding-bottom: 130px;
}
div.schedulebox div.gradientBg {
  bottom: -21px;
  height: 102px;
}
div.schedulebox div.gradientBg button.showMore {
  margin-top: 126px;
}

div.paddingShowContainer {
  padding-bottom: 30px !important;
}

h1.marginScheduleContainer {
  margin-bottom: 0px !important;
}

div.showContainer div.details {
  background: linear-gradient(var(--secondary-bg-color) 50%, #fff 0) 0 var(--_d, 0)/100% 200% no-repeat, linear-gradient(var(--secondary-bg-color) 50%, var(--hover-color) 0) 0 var(--_d, 0)/100% 200% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
  padding: 6px 15px 30px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: -1;
}
div.showContainer div.details:hover {
  --_d: 100%;
}
div.showContainer div.details:hover div.contents div.right div.buttonContainer button.hover-effect {
  display: block;
  animation: hoverEffect 0.4s;
  transition: 0.4s;
}
div.showContainer div.details h3 {
  color: var(--icons-color);
  font-size: 14px;
  margin-bottom: 10px;
  z-index: 999;
}
div.showContainer div.details div.contents {
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 768px) {
  div.showContainer div.details div.contents {
    flex-direction: column;
  }
}
div.showContainer div.details div.contents div.left {
  width: 25%;
}
@media all and (max-width: 1400px) {
  div.showContainer div.details div.contents div.left {
    width: 29%;
  }
}
@media all and (max-width: 980px) {
  div.showContainer div.details div.contents div.left {
    width: 44%;
  }
}
@media all and (max-width: 768px) {
  div.showContainer div.details div.contents div.left {
    width: 100%;
  }
}
div.showContainer div.details div.contents div.left div.thumbnail {
  width: 100%;
}
div.showContainer div.details div.contents div.left div.thumbnail img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
div.showContainer div.details div.contents div.right {
  width: 73%;
}
@media all and (max-width: 1400px) {
  div.showContainer div.details div.contents div.right {
    width: 69%;
  }
}
@media all and (max-width: 980px) {
  div.showContainer div.details div.contents div.right {
    width: 54%;
  }
}
@media all and (max-width: 768px) {
  div.showContainer div.details div.contents div.right {
    width: 100%;
    margin-top: 20px;
  }
}
div.showContainer div.details div.contents div.right h1.title {
  font-size: 22px;
}
@media all and (max-width: 1400px) {
  div.showContainer div.details div.contents div.right h1.title {
    font-size: 19px;
  }
}
div.showContainer div.details div.contents div.right p.description {
  font-size: 15px;
  font-weight: 400;
  opacity: 0.8;
  letter-spacing: 0.6px;
  margin-top: 15px;
}
@media all and (max-width: 1400px) {
  div.showContainer div.details div.contents div.right p.description {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  div.showContainer div.details div.contents div.right p.description {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
div.showContainer div.details div.contents div.right div.buttonContainer {
  display: flex;
}
div.showContainer div.details div.contents div.right div.buttonContainer button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  outline: none;
  font-size: 14px;
  padding: 6px 30px;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid var(--primary-color);
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 1400px) {
  div.showContainer div.details div.contents div.right div.buttonContainer button {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  div.showContainer div.details div.contents div.right div.buttonContainer button {
    padding: 5px 25px;
  }
}
@media all and (max-width: 480px) {
  div.showContainer div.details div.contents div.right div.buttonContainer button {
    padding: 5px 11px;
    font-size: 11px;
  }
}
@media all and (max-width: 360px) {
  div.showContainer div.details div.contents div.right div.buttonContainer button {
    padding: 5px 12px;
  }
}
div.showContainer div.details div.contents div.right div.buttonContainer button:last-child {
  margin-right: 0;
}
div.showContainer div.details div.contents div.right div.buttonContainer button.hover-effect {
  display: none;
  animation: hoverEffect 0.4s;
  transition: 0.4s ease-in-out;
}
div.showContainer div.details div.contents div.right div.buttonContainer button.hover-effect:hover {
  color: var(--primary-color);
}
div.showContainer div.details div.contents div.right div.buttonContainer button span {
  transition: 0.4s ease-in-out;
}
div.showContainer div.details div.contents div.right div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.showContainer div.details div.contents div.right div.buttonContainer button:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}
div.showContainer div.details div.contents div.right div.buttonContainer button:hover span {
  color: var(--primary-color);
}
div.showContainer div.details div.contents div.right div.buttonContainer button:hover span.onDemand {
  display: inline-block;
  transform: translateY(-2px);
}
div.showContainer div.details div.contents div.right div.buttonContainer button:hover svg {
  fill: var(--primary-color);
}

@keyframes hoverEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.cardContainer h1.title {
  font-size: 24px;
}
@media all and (max-width: 480px) {
  div.cardContainer h1.title {
    font-size: 20px;
  }
}
div.cardContainer div.cards {
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}
@media all and (max-width: 768px) {
  div.cardContainer div.cards {
    flex-direction: column;
  }
}
div.cardContainer div.cards div.imageContainer {
  width: 48%;
  margin-bottom: 30px;
  transition: 0.4s ease-in-out;
  overflow: hidden;
}
div.cardContainer div.cards div.imageContainer button.pointer {
  cursor: pointer;
}
div.cardContainer div.cards div.imageContainer button.name {
  cursor: default;
}
@media all and (max-width: 768px) {
  div.cardContainer div.cards div.imageContainer {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.cardContainer div.cards div.imageContainer {
    width: 90%;
    margin: 0 auto 30px;
  }
}
div.cardContainer div.cards div.imageContainer:hover {
  transform: scale(1.1);
}
div.cardContainer div.cards div.imageContainer:hover div.cardGradient {
  transform: translateY(10px);
}
div.cardContainer div.cards div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 12px;
}

div.leaderShipContainer {
  padding: 0 0 100px;
}
div.leaderShipContainer div.wrapper h1.heading {
  font-size: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 30px;
}
@media all and (max-width: 640px) {
  div.leaderShipContainer div.wrapper h1.heading {
    padding-left: 10px;
  }
}
@media all and (max-width: 480px) {
  div.leaderShipContainer div.wrapper h1.heading {
    font-size: 24px;
  }
}

div.homeContainer {
  padding: 0 0 0;
}
@media all and (max-width: 1200px) {
  div.homeContainer {
    padding: 0 0 130px;
  }
}
div.homeContainer div.wrapper div.categoriesSection {
  padding-top: 20px;
}

div.tileContainer.magazine {
  width: 70%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.tileContainer.magazine {
    width: 82%;
  }
}
@media all and (max-width: 768px) {
  div.tileContainer.magazine {
    margin-top: 30px;
  }
}
div.tileContainer.magazine div.item {
  width: 30%;
  margin-right: 30px;
  margin-bottom: 100px;
  margin-top: 20px;
}
@media all and (max-width: 980px) {
  div.tileContainer.magazine div.item {
    width: 47%;
    margin-bottom: 19px;
  }
  div.tileContainer.magazine div.item:nth-child(2) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.tileContainer.magazine div.item {
    width: 46%;
    margin-right: 25px;
  }
}
@media all and (max-width: 640px) {
  div.tileContainer.magazine div.item {
    width: 100%;
    margin-right: 0;
  }
}
div.tileContainer.magazine div.item:last-child {
  margin-right: 0;
}
div.tileContainer div.head {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.tileContainer div.head div.left {
  display: flex;
  align-items: center;
}
div.tileContainer div.head div.left span.title {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
}
div.tileContainer div.head div.left span.icon {
  display: inline-block;
  width: 28px;
}
div.tileContainer div.head div.left span.icon img {
  display: block;
  width: 100%;
}
div.tileContainer div.head div.right span.icon {
  border: 2px solid var(--white-color);
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  transform: scale(0.8);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.tileContainer div.head div.right span.icon:hover svg {
  transform: rotate(-90deg) scale(1.2);
}
div.tileContainer div.head div.right span.icon svg {
  transition: 0.4s ease-in-out;
}
div.tileContainer div.body.buttonCard div.showCard div.bottom {
  padding: 4px 0 15px !important;
}
@media all and (max-width: 1600px) {
  div.tileContainer div.body.buttonCard .swiper-button-prev {
    top: 37% !important;
  }
  div.tileContainer div.body.buttonCard .swiper-button-next {
    top: 37% !important;
  }
}
@media all and (max-width: 1400px) {
  div.tileContainer div.body.buttonCard .swiper-button-prev {
    top: 36% !important;
  }
  div.tileContainer div.body.buttonCard .swiper-button-next {
    top: 36% !important;
  }
}
@media all and (max-width: 980px) {
  div.tileContainer div.body.buttonCard .swiper-button-prev {
    top: 34% !important;
  }
  div.tileContainer div.body.buttonCard .swiper-button-next {
    top: 34% !important;
  }
}
@media all and (max-width: 980px) {
  div.tileContainer div.body.descriptionCard .swiper-button-prev {
    top: 40% !important;
  }
  div.tileContainer div.body.descriptionCard .swiper-button-next {
    top: 40% !important;
  }
}
@media all and (max-width: 640px) {
  div.tileContainer div.body.descriptionCard .swiper-button-prev {
    top: 34% !important;
  }
  div.tileContainer div.body.descriptionCard .swiper-button-next {
    top: 34% !important;
  }
}
div.tileContainer div.body div.mgzine {
  display: flex;
  flex-wrap: wrap;
}
div.tileContainer div.body div.swiper-slide div.item div.showMoreOverlay {
  display: none;
}
div.tileContainer div.body div.swiper-slide:last-child div.item {
  position: relative;
}
div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showMoreOverlay {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-color);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showMoreOverlay h2 {
  font-size: 20px;
}
@media all and (max-width: 980px) {
  div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showMoreOverlay h2 {
    font-size: 16px;
  }
}
@media all and (max-width: 768px) {
  div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showMoreOverlay h2 {
    font-size: 18px;
  }
}
div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showCard:hover {
  transform: none;
}
div.tileContainer div.body div.swiper-slide:last-child div.item.visibleShowAllButton div.showCard div.bottom {
  display: none;
}

div.showCard {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
div.showCard.hovCard div.bottom {
  animation: cardAnimationOut 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCard.hovCard div.bottom {
    animation: none;
  }
}
div.showCard:hover {
  transform: scale(1.1);
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.showCard:hover {
    transform: none;
  }
}
div.showCard:hover div.bottom {
  animation: cardAnimationIn 0.3s ease-in-out forwards;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom {
    animation: none;
  }
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom.desc {
    animation: none;
  }
}
div.showCard:hover div.bottom div.content {
  padding-bottom: 70px;
}
@media all and (max-width: 1400px) {
  div.showCard:hover div.bottom div.content {
    height: 60px;
  }
}
@media all and (max-width: 1200px) {
  div.showCard:hover div.bottom div.content {
    height: 80px;
  }
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom div.content {
    height: 60px;
  }
}
div.showCard:hover div.bottom div.content div.dateSection {
  animation: textAnimationIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom div.content div.dateSection {
    animation: none;
  }
}
div.showCard:hover div.bottom div.content p {
  animation: textAnimationIn 0.3s ease-in-out;
  transform: scale(1);
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom div.content p {
    animation: none;
  }
}
div.showCard:hover div.bottom div.content div.buttonContainer {
  animation: textAnimationIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCard:hover div.bottom div.content div.buttonContainer {
    animation: none;
  }
}
div.showCard:hover div.top {
  z-index: 9;
}
div.showCard:hover div.top span.playIcon {
  bottom: 10px;
}
div.showCard:hover div.top span.playIcon div.hoverEffect {
  width: 10px;
  height: 10px;
  background-color: var(--white-color);
  border-radius: 50%;
  position: absolute;
  top: 32%;
  left: 32%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.3s ease-in-out;
  z-index: 3;
}
div.showCard:hover div.top span.star {
  display: block;
  animation: textAnimationIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCard:hover div.top span.star {
    animation: none;
  }
}
div.showCard:hover div.top span.star.star-filled svg {
  fill: var(--primary-color);
}
div.showCard div.top {
  overflow: hidden;
}
div.showCard div.top div.imageContainer {
  width: 100%;
  display: block;
  z-index: -1;
}
div.showCard div.top div.imageContainer img {
  width: 100%;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
div.showCard div.top span.star {
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 10px;
  display: none;
  z-index: 8;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCard div.top span.star {
    display: block;
  }
  div.showCard div.top span.star.star-filled svg {
    fill: var(--primary-color);
  }
}
div.showCard div.top span.playIcon {
  display: inline-block;
  background-color: var(--primary-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: -60px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  z-index: 8;
}
@media all and (max-width: 980px) {
  div.showCard div.top span.playIcon {
    bottom: 10px;
  }
}
div.showCard div.top span.playIcon:hover {
  background-color: var(--white-color);
}
div.showCard div.top span.playIcon:hover svg {
  fill: var(--primary-color);
  z-index: 8;
}
div.showCard div.top span.playIcon:hover div.hoverEffect {
  transform: scale(2.8);
  transition: 0.3s ease-in-out;
  animation: zoom 0.3s ease-in-out;
}
div.showCard div.top span.playIcon svg {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}
div.showCard div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 60px;
}
div.showCard div.bottom.dateCard {
  height: 60px;
}
@media all and (max-width: 980px) {
  div.showCard div.bottom.dateCard {
    height: 70px;
  }
}
@media all and (max-width: 980px) {
  div.showCard div.bottom.desc {
    height: 71px;
  }
}
@media all and (max-width: 1400px) {
  div.showCard div.bottom {
    padding: 4px 15px 15px;
  }
}
div.showCard div.bottom h3.title {
  font-size: 14px;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media all and (max-width: 1400px) {
  div.showCard div.bottom h3.title {
    font-size: 12px;
  }
}
@media all and (max-width: 980px) {
  div.showCard div.bottom h3.title.buttonCard {
    min-height: 30px;
  }
}
@media all and (max-width: 768px) {
  div.showCard div.bottom h3.title {
    font-size: 14px;
  }
}
div.showCard div.bottom div.content {
  overflow: hidden;
  height: 0;
}
@media all and (max-width: 980px) {
  div.showCard div.bottom div.content {
    height: 60px;
  }
}
div.showCard div.bottom div.content div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
div.showCard div.bottom div.content div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-right: 8px;
}
div.showCard div.bottom div.content div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.showCard div.bottom div.content div.dateSection span.date {
  display: inline-block;
  font-size: 12px;
  font-weight: 100;
}
div.showCard div.bottom div.content p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation: textAnimationOut 0.3s ease-in-out;
}
@media all and (max-width: 1600px) {
  div.showCard div.bottom div.content p {
    font-size: 12px;
  }
}
@media all and (max-width: 980px) {
  div.showCard div.bottom div.content p {
    animation: none;
  }
}
div.showCard div.bottom div.content div.buttonContainer button {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 6px 20px;
  font-size: 12px;
  border-radius: 6px;
  width: 70%;
  transition: 0.3s ease-in-out;
}
div.showCard div.bottom div.content div.buttonContainer button:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
@media all and (max-width: 980px) {
  div.showCard div.bottom div.content div.buttonContainer button {
    padding: 2px 10px;
    font-size: 11px;
    width: 72%;
  }
}

@keyframes cardAnimationIn {
  from {
    height: 60px;
  }
  to {
    height: 70px;
  }
}
@keyframes cardAnimationIn2 {
  from {
    height: 60px;
  }
  to {
    height: 65px;
  }
}
@keyframes cardAnimationOut {
  from {
    height: 70px;
  }
  to {
    height: 60px;
  }
}
@keyframes textAnimationIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes textAnimationOut {
  to {
    transform: translateY(0);
  }
  from {
    transform: translateY(-20px);
  }
}
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(2.8);
  }
}
div.magazineCard {
  width: 100%;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.magazineCard:hover {
  transform: scale(1.1);
}
@media all and (max-width: 980px) {
  div.magazineCard:hover {
    transform: scale(1);
  }
}
div.magazineCard:hover div.bottom {
  display: block;
  animation: textAnimationIn 0.4s ease-in-out;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.magazineCard:hover div.bottom {
    animation: none;
  }
}
div.magazineCard div.top div.imageContainer {
  width: 100%;
}
div.magazineCard div.top div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 6px;
}
div.magazineCard div.bottom {
  display: none;
  position: absolute;
}
@media all and (max-width: 980px) {
  div.magazineCard div.bottom {
    display: block;
    animation: none;
    position: relative;
  }
}
div.magazineCard div.bottom p {
  margin-bottom: 5px;
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.magazineCard div.bottom p {
    max-width: 90%;
    font-size: 12px;
  }
}
@media all and (max-width: 641px) {
  div.magazineCard div.bottom p {
    max-width: 100%;
    font-size: 14px;
  }
}
div.magazineCard div.bottom div.buttonContainer svg {
  color: var(--primary-color);
}
div.magazineCard div.bottom div.buttonContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 6px 20px;
  font-size: 12px;
  border-radius: 6px;
  width: 70%;
  transition: 0.3s ease-in-out;
}
div.magazineCard div.bottom div.buttonContainer button:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
div.magazineCard div.bottom div.buttonContainer button:hover svg polygon {
  fill: var(--icons-color);
}

@keyframes textAnimationIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
div.aboutRevelation {
  padding: 20% 0 0;
}
@media all and (max-width: 1200px) {
  div.aboutRevelation {
    padding: 25% 0 5%;
  }
}
@media all and (max-width: 981px) {
  div.aboutRevelation {
    padding: 35% 0 5%;
  }
}
@media all and (max-width: 768px) {
  div.aboutRevelation {
    padding: 40% 0 5%;
  }
}
@media all and (max-width: 480px) {
  div.aboutRevelation {
    padding: 70% 0 5%;
  }
}
div.aboutRevelation div.headContainer div.glowWhite {
  position: absolute;
  top: -135px;
  left: 38%;
  border-radius: 50%;
  background-color: #f8f8f8;
  box-shadow: 10px 10px 86px 26px #f8f8f8, 4px 0 79px 35px #f8f8f8, 10px 0 52px 10px #f8f8f8;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  opacity: 0.4;
}
div.aboutRevelation div.headContainer div.glowWhite:hover {
  box-shadow: 10px 10px 37px 26px #f8f8f8, 4px 0 96px 69px #f8f8f8, 10px 0 17px 2px #f8f8f8;
  opacity: 1;
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.headContainer div.glowWhite {
    top: -176px;
    left: 34%;
  }
}
div.aboutRevelation div.headContainer div.glowRed1 {
  position: absolute;
  top: 101px;
  left: 24%;
  border-radius: 50%;
  background-color: #e72a32;
  z-index: 8;
  box-shadow: 10px 10px 37px 26px #e72a32, 4px 0 96px 43px #e72a31, 10px 0 4px -7px #e72a32;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  opacity: 0.4;
}
div.aboutRevelation div.headContainer div.glowRed1:hover {
  box-shadow: 10px 10px 37px 26px #e72a32, 4px 0 96px 69px #e72a31, 10px 0 17px 2px #e72a32;
  opacity: 1;
}
@media all and (max-width: 980px) {
  div.aboutRevelation div.headContainer div.glowRed1 {
    top: 33px;
  }
}
@media all and (max-width: 480px) {
  div.aboutRevelation div.headContainer div.glowRed1 {
    top: 106px;
    left: 9%;
  }
}
div.aboutRevelation div.headContainer div.glowRed2 {
  position: absolute;
  top: 65px;
  right: 31%;
  border-radius: 50%;
  background-color: #e72a31;
  box-shadow: 10px 10px 81px -6px #e72a31, 10px 0 112px 62px #e72a31, 10px 0 38px 6px #e72a32;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  opacity: 0.3;
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.headContainer div.glowRed2 {
    top: -25px;
    right: 19%;
  }
}
@media all and (max-width: 768px) {
  div.aboutRevelation div.headContainer div.glowRed2 {
    top: 51px;
    right: 8%;
  }
}
div.aboutRevelation div.headContainer div.glowRed2:hover {
  opacity: 1;
}
div.aboutRevelation div.headContainer p {
  text-align: center;
  font-size: 22px;
  max-width: 75%;
  margin: 0 auto;
}
@media all and (max-width: 1600px) {
  div.aboutRevelation div.headContainer p {
    max-width: 81%;
  }
}
@media all and (max-width: 1400px) {
  div.aboutRevelation div.headContainer p {
    max-width: 95%;
  }
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.headContainer p {
    max-width: 80%;
    font-size: 17px;
  }
}
@media all and (max-width: 980px) {
  div.aboutRevelation div.headContainer p {
    max-width: 100%;
  }
}
div.aboutRevelation div.headContainer div.revLogoContainer {
  width: 60%;
  margin: 0 auto;
  position: absolute;
  top: -192px;
  left: 50%;
  transform: translateX(-50%) !important;
  z-index: -1;
}
@media all and (max-width: 768px) {
  div.aboutRevelation div.headContainer div.revLogoContainer {
    top: -65px;
  }
}
@media all and (max-width: 480px) {
  div.aboutRevelation div.headContainer div.revLogoContainer {
    width: 96%;
  }
}
div.aboutRevelation div.headContainer div.revLogoContainer img {
  display: block;
  width: 100%;
}
div.aboutRevelation div.videoContainer {
  width: 65%;
  margin: 0 auto 30px;
  padding: 15% 0 0;
  position: relative;
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.videoContainer {
    padding: 21% 0 0;
  }
}
@media all and (max-width: 980px) {
  div.aboutRevelation div.videoContainer {
    padding: 20% 0 0;
  }
}
@media all and (max-width: 768px) {
  div.aboutRevelation div.videoContainer {
    padding: 23% 0 0;
    width: 72%;
  }
}
@media all and (max-width: 640px) {
  div.aboutRevelation div.videoContainer {
    padding: 23% 0 0;
  }
}
@media all and (max-width: 480px) {
  div.aboutRevelation div.videoContainer {
    padding: 41% 0 5%;
  }
}
div.aboutRevelation div.videoContainer video {
  width: 100%;
  display: block;
}
div.aboutRevelation div.about {
  text-align: center;
}
div.aboutRevelation div.about p {
  max-width: 70%;
  margin: 0 auto 50px;
  color: var(--white-color);
  opacity: 0.7;
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.about p {
    font-size: 12px;
  }
}
@media all and (max-width: 768px) {
  div.aboutRevelation div.about p {
    max-width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.aboutRevelation div.about p {
    font-size: 14px;
    max-width: 90%;
  }
}
div.aboutRevelation div.details {
  width: 65%;
  margin: 0 auto;
  padding-left: 30px;
  border-left: 2px solid var(--text-color);
}
@media all and (max-width: 768px) {
  div.aboutRevelation div.details {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.aboutRevelation div.details {
    width: 80%;
  }
}
@media all and (max-width: 1200px) {
  div.aboutRevelation div.details ul li p {
    font-size: 12px;
  }
}

div.subscribeBanner {
  margin-top: 100px;
}
div.subscribeBanner div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
}
div.subscribeBanner div.imageContainer img {
  width: 100%;
  display: block;
}
div.subscribeBanner div.imageContainer div.buttonContainer {
  text-align: center;
  position: absolute;
  bottom: 20%;
  width: 15%;
  left: 50%;
  transform: translateX(-50%);
}
div.subscribeBanner div.imageContainer div.buttonContainer button.subscribe {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  outline: none;
  font-size: 14px;
  padding: 6px 30px;
  width: 84%;
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.subscribeBanner div.imageContainer div.buttonContainer button.subscribe {
    font-size: 12px;
    padding: 6px 16px;
    width: 100% _Pe;
  }
}
@media all and (max-width: 768px) {
  div.subscribeBanner div.imageContainer div.buttonContainer button.subscribe {
    font-size: 9px;
    padding: 6px 16px;
  }
}
@media all and (max-width: 480px) {
  div.subscribeBanner div.imageContainer div.buttonContainer button.subscribe {
    font-size: 8px;
    padding: 2px 1px;
  }
}
div.subscribeBanner div.imageContainer div.buttonContainer button.subscribe:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}

div.products {
  background-color: #000;
  padding: 60px 0;
}
div.products div.watchSection {
  width: 48%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.products div.watchSection {
    width: 67%;
  }
}
div.products div.watchSection h1.title {
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.products div.watchSection h1.title {
    padding-left: 20px;
  }
}
div.products div.watchSection ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px auto 0;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  div.products div.watchSection ul {
    justify-content: center;
  }
}
div.products div.watchSection ul li {
  width: 16%;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
  text-align: center;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.products div.watchSection ul li {
    width: 22%;
    margin-bottom: 20px;
  }
  div.products div.watchSection ul li:nth-child(5), div.products div.watchSection ul li:nth-child(6) {
    margin: 0;
  }
}
@media all and (max-width: 480px) {
  div.products div.watchSection ul li {
    width: 35%;
  }
  div.products div.watchSection ul li:nth-child(5), div.products div.watchSection ul li:nth-child(6) {
    margin: 0 auto;
  }
}
div.products div.watchSection ul li img {
  width: 60%;
  display: block;
  margin: 0 auto;
}
div.products div.watchSection ul li span.name {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
}
div.products div.watchSection ul li span.name::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--white-color);
  transition: width 0.4s;
}
div.products div.watchSection ul li:hover {
  transform: scale(1.1);
}
div.products div.watchSection ul li:hover span.name::after {
  width: 100%;
}

div.latestNews {
  padding: 0 0 80px;
}
div.latestNews div.head {
  display: flex;
  justify-content: right;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-right: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
  cursor: pointer;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer:hover img {
  transform: scale(1.1);
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
  transition: 0.5s ease-in-out;
}
div.latestNews div.body h1.heading {
  font-size: 24px;
  border-bottom: 1px solid var(--white-color);
  padding-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.latestNews div.body h1.heading {
    padding-left: 20px;
  }
}
div.latestNews div.gradientBg {
  bottom: -36px;
}
div.latestNews div.gradientBg button.showMore {
  margin-top: 90px;
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--secondary-bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--hover-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 48%;
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 48%;
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title {
  font-size: 22px;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.ondemand {
  padding: 0 0 0;
}
div.ondemand .swiper-pagination-bullet {
  background-color: white !important;
  opacity: 1 !important;
}
div.ondemand .swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
  opacity: 1 !important;
}
@media all and (max-width: 1200px) {
  div.ondemand .swiper-slide {
    margin-right: 0 !important;
  }
}
@media all and (max-width: 640px) {
  div.ondemand {
    padding: 0 20px 80px;
  }
}
div.ondemand div.wrapper1 {
  width: 70%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.ondemand div.wrapper1 {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.wrapper1 {
    width: 100%;
  }
}
div.ondemand div.swiper-pagination {
  bottom: 79px !important;
  left: 0;
  width: 100%;
  z-index: 999 !important;
}
@media all and (max-width: 1200px) {
  div.ondemand div.swiper-pagination {
    bottom: 103px !important;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.swiper-pagination {
    bottom: 126px !important;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.swiper-pagination {
    bottom: 170px !important;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.swiper-pagination {
    bottom: 188px !important;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.swiper-pagination {
    bottom: 177px !important;
  }
}
div.ondemand div.spotlight .swiper {
  padding: 0 !important;
}
div.ondemand div.spotlight div.featuredContainer {
  position: relative;
  padding: 36px 50px;
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.featuredContainer {
    padding: 30px 20px;
  }
}
div.ondemand div.spotlight div.featuredContainer div.gradientBg {
  bottom: 0 !important;
}
div.ondemand div.spotlight div.featuredContainer div.background {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(10px);
  overflow: hidden;
}
div.ondemand div.spotlight div.featuredContainer div.background img {
  display: block;
  width: 100%;
}
@media all and (max-width: 640px) {
  div.ondemand div.spotlight div.featuredContainer div.background {
    top: 32px !important;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.featuredContainer div.background {
    top: 8px !important;
  }
}
div.ondemand div.spotlight div.featuredContainer div.imageContainer {
  display: block;
  max-width: 54%;
  height: auto;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.featuredContainer div.imageContainer {
    max-width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.spotlight div.featuredContainer div.imageContainer {
    max-width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.featuredContainer div.imageContainer {
    max-width: 80%;
  }
}
div.ondemand div.spotlight div.featuredContainer div.imageContainer img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}
div.ondemand div.spotlight div.details {
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.details {
    padding: 10px 0;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details {
    flex-direction: column;
  }
}
div.ondemand div.spotlight div.details div.left {
  width: 60%;
}
@media all and (max-width: 1400px) {
  div.ondemand div.spotlight div.details div.left {
    width: 70%;
  }
}
@media all and (max-width: 1200px) {
  div.ondemand div.spotlight div.details div.left {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.spotlight div.details div.left {
    width: 70%;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.ondemand div.spotlight div.details div.left h1 {
  font-size: 22px;
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.left h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.details div.left h1 {
    font-size: 18px;
  }
}
div.ondemand div.spotlight div.details div.left p {
  margin-bottom: 0;
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.left p {
    font-size: 13px;
  }
}
div.ondemand div.spotlight div.details div.right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: right;
}
@media all and (max-width: 1400px) {
  div.ondemand div.spotlight div.details div.right {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.ondemand div.spotlight div.details div.right {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.spotlight div.details div.right {
    width: 45%;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.right {
    width: 100%;
    justify-content: left;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.details div.right {
    justify-content: center;
  }
}
div.ondemand div.spotlight div.details div.right button.transparent {
  padding: 4px 3px;
  width: 100%;
}
div.ondemand div.spotlight div.details div.right button.transparent span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    padding: 3px;
  }
}
div.ondemand div.spotlight div.details div.right button.transparent:hover span {
  color: var(--icons-color);
}
div.ondemand div.spotlight div.details div.right button.transparent svg {
  width: 13px !important;
  height: 13px !important;
}
div.ondemand div.spotlight div.details div.right button.transparent svg line {
  transition: 0.3s ease-in-out;
  stroke: var(--primary-color) !important;
}
div.ondemand div.spotlight div.details div.right button.transparent:hover svg line {
  stroke: var(--icons-color) !important;
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    padding: 3px 6px;
  }
}
div.ondemand div.spotlight div.details div.right button.transparent {
  margin-right: 20px;
  width: 25%;
}
@media all and (max-width: 1400px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    width: 40%;
  }
}
@media all and (max-width: 1200px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    font-size: 12px;
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    font-size: 12px;
    width: 27%;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    width: 50%;
  }
}
@media all and (max-width: 360px) {
  div.ondemand div.spotlight div.details div.right button.transparent {
    width: 65%;
    padding: 6px 6px;
  }
}
div.ondemand div.spotlight div.details div.right button.bg-button {
  padding: 4px 16px 4px 3px;
  width: 25%;
  justify-content: right;
  font-size: 12px;
}
div.ondemand div.spotlight div.details div.right button.bg-button svg {
  height: 20px !important;
  width: 20px !important;
}
@media all and (max-width: 1400px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    width: 43%;
  }
}
@media all and (max-width: 1200px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    padding: 3px 16px 4px 3px;
    width: 35%;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    font-size: 12px;
    width: 20%;
    padding: 2px 16px 2px 3px;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    width: 28%;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.spotlight div.details div.right button.bg-button {
    width: 40%;
  }
}
div.ondemand div.librarySection {
  margin-top: 6px;
}
div.ondemand div.librarySection h1.heading {
  font-size: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.ondemand div.librarySection div.libraries {
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
div.ondemand div.librarySection div.libraries div.items {
  width: 23%;
  margin-bottom: 150px;
  margin-right: 24px;
}
@media all and (max-width: 1600px) {
  div.ondemand div.librarySection div.libraries div.items {
    margin-right: 20px;
  }
}
@media all and (max-width: 1400px) {
  div.ondemand div.librarySection div.libraries div.items {
    margin-right: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.ondemand div.librarySection div.libraries div.items {
    width: 30%;
    margin-right: 15px;
    margin-bottom: 125px;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.librarySection div.libraries div.items {
    width: 30%;
  }
}
@media all and (max-width: 980px) {
  div.ondemand div.librarySection div.libraries div.items {
    width: 48%;
    margin-right: 15px;
  }
  div.ondemand div.librarySection div.libraries div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.ondemand div.librarySection div.libraries div.items {
    width: 100%;
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.ondemand div.librarySection div.libraries div.items {
    width: 100%;
  }
}

div.prayerSection {
  padding: 0 0 120px;
}
div.prayerSection span.error {
  font-size: 12px;
  text-align: left;
}
div.prayerSection span.success {
  font-size: 12px;
}
div.prayerSection h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.prayerSection h1.heading {
    padding-left: 20px;
    font-size: 22px;
  }
}
div.prayerSection div.contents {
  margin-top: 40px;
  text-align: center;
}
@media all and (max-width: 480px) {
  div.prayerSection div.contents {
    padding: 0 10px;
  }
}
div.prayerSection div.contents h2.title {
  font-size: 22px;
  margin-bottom: 30px;
  color: var(--white-color);
  opacity: 0.8;
}
@media all and (max-width: 640px) {
  div.prayerSection div.contents h2.title {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.prayerSection div.contents h2.title {
    font-size: 18px;
  }
}
div.prayerSection div.contents p {
  max-width: 50%;
  margin: 0 auto 40px;
  font-size: 15px;
}
@media all and (max-width: 980px) {
  div.prayerSection div.contents p {
    max-width: 100%;
    font-size: 14px;
  }
}
@media all and (max-width: 640px) {
  div.prayerSection div.contents p {
    font-size: 13px;
  }
}
div.prayerSection div.contents p a {
  display: inline-block;
  color: var(--primary-color);
  font-weight: 600;
}
div.prayerSection div.contents div.formSection {
  width: 42%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.prayerSection div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.prayerSection div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.prayerSection div.contents div.formSection {
    width: 85%;
  }
}
div.prayerSection div.contents div.formSection form input {
  border-radius: 6px;
  padding: 6px 10px;
}
div.prayerSection div.contents div.formSection form textarea {
  border-radius: 6px;
  resize: none;
  height: 150px;
}
div.prayerSection div.contents div.formSection form label {
  margin-bottom: 6px;
}
@media all and (max-width: 640px) {
  div.prayerSection div.contents div.formSection form label {
    font-size: 13px;
  }
}
div.prayerSection div.contents div.formSection form div.half {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.prayerSection div.contents div.formSection form div.half {
    flex-direction: column;
  }
}
div.prayerSection div.contents div.formSection form div.half div.fname {
  display: flex;
  flex-direction: column;
  width: 49%;
}
@media all and (max-width: 480px) {
  div.prayerSection div.contents div.formSection form div.half div.fname {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.prayerSection div.contents div.formSection form div.half div.fname label {
  text-align: left;
}
div.prayerSection div.contents div.formSection form div.half div.lname {
  width: 49%;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 480px) {
  div.prayerSection div.contents div.formSection form div.half div.lname {
    width: 100%;
  }
}
div.prayerSection div.contents div.formSection form div.half div.lname label {
  text-align: left;
}
div.prayerSection div.contents div.formSection form div.full {
  width: 100%;
  margin-bottom: 10px;
}
div.prayerSection div.contents div.formSection form div.full div.email {
  display: flex;
  flex-direction: column;
}
div.prayerSection div.contents div.formSection form div.full div.email label {
  text-align: left;
}
div.prayerSection div.contents div.formSection form div.full div.messageBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
div.prayerSection div.contents div.formSection form div.full div.messageBox label {
  text-align: left;
}
div.prayerSection div.contents div.formSection form div.buttonContainer {
  text-align: center;
}
div.prayerSection div.contents div.formSection form div.buttonContainer button.bg-button {
  margin: 0 auto;
  width: 30%;
}

div.Reminder-favouritesSection {
  padding: 0 0 130px;
  position: relative;
}
@media all and (max-width: 640px) {
  div.Reminder-favouritesSection {
    padding: 0 30px 60px;
  }
}
div.Reminder-favouritesSection h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 30px;
}
div.Reminder-favouritesSection div.favourites h1.nodata {
  text-align: center;
  color: var(--primary-color);
  padding: 80px 0;
}
div.Reminder-favouritesSection div.favourites div.head {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
div.Reminder-favouritesSection div.favourites div.head div.left {
  display: flex;
  align-items: center;
}
div.Reminder-favouritesSection div.favourites div.head div.left span.title {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
}
div.Reminder-favouritesSection div.favourites div.head div.left span.icon {
  display: inline-block;
  width: 18px;
  margin-bottom: 5px;
}
div.Reminder-favouritesSection div.favourites div.head div.left span.icon img {
  display: block;
  width: 100%;
}
div.Reminder-favouritesSection div.favourites div.head div.right span.icon {
  border: 2px solid var(--white-color);
  display: inline-block;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}
div.Reminder-favouritesSection div.favourites div.head div.right span.icon:hover svg {
  transform: rotate(-90deg) scale(1.2);
}
div.Reminder-favouritesSection div.favourites div.head div.right span.icon svg {
  transition: 0.4s ease-in-out;
}
div.Reminder-favouritesSection div.favourites div.favouritesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 140px;
}
div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item {
  width: 22%;
  margin-right: 35px;
}
div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item:last-child {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item {
    width: 44%;
    margin-bottom: 130px;
  }
  div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item:nth-child(2n) {
    margin-right: 0;
  }
  div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item:nth-child(3), div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item:nth-child(4) {
    margin-bottom: 0;
  }
}
@media all and (max-width: 640px) {
  div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item {
    width: 100%;
    margin-right: 0;
  }
  div.Reminder-favouritesSection div.favourites div.favouritesContainer div.item:nth-child(3) {
    margin-bottom: 130px;
  }
}
div.Reminder-favouritesSection div.favourites div.tileContainer div.left span.icon {
  width: 20px;
}
div.Reminder-favouritesSection div.favourites div.tileContainer div.left span.icon svg {
  fill: var(--primary-color);
}
div.Reminder-favouritesSection div.gradientBg {
  bottom: 123px !important;
  height: 68px !important;
}
div.Reminder-favouritesSection div.gradientBg button.showMore {
  margin-top: 94px !important;
}
@media all and (max-width: 640px) {
  div.Reminder-favouritesSection div.gradientBg {
    bottom: 59px !important;
  }
  div.Reminder-favouritesSection div.gradientBg button.showMore {
    margin-top: 71px !important;
  }
}

div.presenters {
  padding: 0 0 0;
}
@media all and (max-width: 640px) {
  div.presenters {
    padding: 0 20px 0;
  }
}
div.presenters h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 20px;
}
div.presenters div.content h1.title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}
div.presenters div.content p {
  text-align: center;
}
div.presenters div.content div.presentersDetails {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
div.presenters div.content div.presentersDetails div.item {
  width: 23%;
  margin-bottom: 160px;
  margin-right: 46px;
}
div.presenters div.content div.presentersDetails div.item:nth-child(4n) {
  margin-right: 0;
}
div.presenters div.content div.presentersDetails div.item p {
  text-align: left;
}
@media all and (max-width: 2200px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 42px;
  }
}
@media all and (max-width: 1980px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 37px;
  }
}
@media all and (max-width: 1768px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 34px;
  }
}
@media all and (max-width: 1600px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 29px;
  }
}
@media all and (max-width: 1400px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 25px;
  }
}
@media all and (max-width: 1200px) {
  div.presenters div.content div.presentersDetails div.item {
    margin-right: 23px;
  }
}
@media all and (max-width: 980px) {
  div.presenters div.content div.presentersDetails div.item {
    width: 32%;
    margin-right: 13px;
  }
  div.presenters div.content div.presentersDetails div.item:nth-child(4n) {
    margin-right: 13px;
  }
  div.presenters div.content div.presentersDetails div.item:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.presenters div.content div.presentersDetails div.item {
    width: 31%;
    margin-right: 11px;
  }
}
@media all and (max-width: 640px) {
  div.presenters div.content div.presentersDetails div.item {
    width: 48%;
    margin-right: 10px;
  }
  div.presenters div.content div.presentersDetails div.item:nth-child(3n) {
    margin-right: 10px;
  }
  div.presenters div.content div.presentersDetails div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.presenters div.content div.presentersDetails div.item {
    width: 100%;
    margin-right: 0;
  }
  div.presenters div.content div.presentersDetails div.item:nth-child(3n) {
    margin-right: 0;
  }
}

div.presenters-info {
  padding: 0 0 80px;
}
@media all and (max-width: 640px) {
  div.presenters-info {
    padding: 0 20px 80px;
  }
}
div.presenters-info div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.presenters-info div.imageContainer {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.presenters-info div.imageContainer {
    width: 80%;
  }
}
div.presenters-info div.imageContainer img {
  display: block;
  width: 100%;
}
div.presenters-info h1.name {
  font-size: 22px;
  text-align: center;
  margin-top: 30px;
}
div.presenters-info p.presenter-info {
  text-align: center;
  font-size: 18px;
  margin-bottom: 40px;
}
div.presenters-info p.presenter-details {
  font-size: 15px;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.presenters-info p.presenter-details {
    max-width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.presenters-info p.presenter-details {
    max-width: 95%;
  }
}
@media all and (max-width: 480px) {
  div.presenters-info p.presenter-details {
    max-width: 100%;
  }
}
div.presenters-info div.details {
  margin-top: 50px;
}
div.presenters-info div.details h1 {
  font-size: 22px;
  margin-bottom: 20px;
}
div.presenters-info div.details div.description {
  border-left: 2px solid var(--text-color);
  padding-left: 15px;
}
div.presenters-info div.details div.description p {
  font-size: 16px;
}
@media all and (max-width: 640px) {
  div.presenters-info div.details div.description p {
    font-size: 15px;
  }
}
div.donation {
  padding: 0 0 80px;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
@media all and (max-width: 640px) {
  div.donation {
    padding: 0 20px 80px;
  }
}
div.donation span.error {
  font-size: 14px;
  color: var(--primary-color);
}
div.donation div.wrapper {
  width: 85%;
}
@media all and (max-width: 640px) {
  div.donation div.wrapper {
    width: 100%;
  }
}
div.donation h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.donation div.details {
  padding-top: 50px;
  text-align: center;
}
div.donation div.details h1.title {
  font-size: 22px;
  margin-bottom: 30px;
}
div.donation div.details p {
  font-size: 16px;
  max-width: 85%;
  margin: 0 auto;
}
@media all and (max-width: 480px) {
  div.donation div.details p {
    max-width: 100%;
    font-size: 14px;
  }
}
div.donation div.donationContainer {
  margin-top: 50px;
  background-color: var(--secondary-bg-color);
  padding: 30px 25px 30px 35px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}
@media all and (max-width: 480px) {
  div.donation div.donationContainer {
    padding-left: 25px;
    padding-right: 20px;
  }
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer {
    flex-direction: column;
  }
}
div.donation div.donationContainer div.leftContainer {
  width: 70%;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.leftContainer {
    width: 66%;
  }
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.leftContainer {
    width: 100%;
  }
}
div.donation div.donationContainer div.leftContainer p.description {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 980px) {
  div.donation div.donationContainer div.leftContainer p.description {
    font-size: 14px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne {
  display: flex;
  margin-bottom: 30px;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne {
    flex-direction: column;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.left {
  width: 40%;
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.left {
    width: 35%;
  }
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.left {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.left label {
  color: var(--text-color);
  font-weight: 400;
}
@media all and (max-width: 980px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.left label {
    font-size: 15px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right {
  display: flex;
  width: 60%;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right {
    flex-direction: column;
    width: 100%;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer {
  margin-right: 40px;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer {
    margin-bottom: 20px;
    width: 100%;
  }
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer:last-child {
    margin-bottom: 0;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer:last-child {
  margin-right: 0;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer label {
  font-size: 14px;
  color: var(--text-color);
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer label span.para {
  font-size: 14px;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainer label {
    font-size: 13px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainerFull {
  width: 60%;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainerFull {
    width: 90%;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainerFull input {
  padding: 6px 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
}
@media all and (max-width: 480px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainerFull input {
    padding: 3px 10px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right div.inputContainerFull select {
  padding: 6px 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 {
  width: 48%;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 {
    margin-bottom: 20px;
    width: 60%;
  }
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2:last-child {
    margin-bottom: 0;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 select {
  padding: 8px 10px;
  border-radius: 6px;
  width: 100%;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 select {
    font-size: 14px;
    padding: 6px 10px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 input {
  padding: 6px 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.oneByOne div.right2 div.inputContainer2 input {
    font-size: 14px;
    padding: 6px 10px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description {
  padding-bottom: 30px;
  margin-bottom: 20px;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description label {
  color: var(--text-color);
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay span.error {
  position: absolute;
  right: 143px;
  top: -4px;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay span.error {
    font-size: 13px;
  }
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay span.error {
    right: 0;
    top: 20px;
  }
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay span.error {
    font-size: 10px;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay:last-child {
  margin-bottom: 0;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay div.left span {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color);
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay div.right {
  display: flex;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay div.right label.container {
  font-size: 14px;
  padding-left: 30px;
  margin-right: 20px;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.description div.contactWay div.right label.container:last-child {
  margin-right: 0;
}
div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer {
  margin-top: 30px;
  display: flex;
  border-top: 1px solid var(--white-color);
  padding-top: 20px;
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer {
    justify-content: center;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.bg-button {
  margin-right: 30px;
  padding: 6px 12px;
  width: 20%;
  font-size: 16px;
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.bg-button {
    font-size: 13px;
  }
}
@media all and (max-width: 640px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.bg-button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.bg-button {
    width: 35%;
  }
}
div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.transparent {
  padding: 6px 12px;
  width: 20%;
  font-size: 16px;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.transparent {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.transparent {
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.transparent {
    width: 40%;
  }
}
@media all and (max-width: 360px) {
  div.donation div.donationContainer div.leftContainer div.formSection form div.buttonContainer button.transparent {
    width: 45%;
  }
}
div.donation div.donationContainer div.rightContainer {
  width: 23%;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.rightContainer {
    width: 28%;
  }
}
@media all and (max-width: 768px) {
  div.donation div.donationContainer div.rightContainer {
    width: 100%;
    margin-top: 40px;
  }
}
div.donation div.donationContainer div.rightContainer div.debitDonate {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
div.donation div.donationContainer div.rightContainer div.debitDonate h4 {
  font-size: 22px;
  margin-bottom: 15px;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.rightContainer div.debitDonate h4 {
    font-size: 20px;
  }
}
div.donation div.donationContainer div.rightContainer div.debitDonate p {
  font-size: 15px;
}
div.donation div.donationContainer div.rightContainer div.debitDonate button {
  background: transparent;
  color: var(--primary-color);
}
div.donation div.donationContainer div.rightContainer div.otherDonate {
  margin-top: 20px;
}
div.donation div.donationContainer div.rightContainer div.otherDonate h4 {
  font-size: 22px;
  margin-bottom: 15px;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.rightContainer div.otherDonate h4 {
    font-size: 20px;
  }
}
div.donation div.donationContainer div.rightContainer div.otherDonate span {
  font-size: 15px;
  color: var(--primary-color);
  opacity: 0.8;
}
@media all and (max-width: 1200px) {
  div.donation div.donationContainer div.rightContainer div.otherDonate p {
    font-size: 13px;
  }
}
div.donation .container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.donation .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
div.donation .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
div.donation .container:hover span.checkmark {
  background-color: #ccc;
}
div.donation .container input:checked ~ span.checkmark {
  background-color: var(--primary-color);
}
div.donation .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
div.donation .container input:checked ~ .checkmark:after {
  display: block;
}
div.donation .container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
div.donation .Squarecontainer {
  display: block;
  position: relative;
  margin-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.donation .Squarecontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
div.donation .Squarecheckmark {
  position: absolute;
  top: -1px;
  left: -33px;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background-color: #eee;
}
div.donation .Squarecontainer:hover input ~ .Squarecheckmark {
  background-color: #ccc;
}
div.donation .Squarecontainer input:checked ~ .Squarecheckmark {
  background-color: var(--primary-color);
}
div.donation .Squarecheckmark::after {
  content: "";
  position: absolute;
  display: none;
}
div.donation .Squarecontainer input:checked ~ .Squarecheckmark::after {
  display: block;
}
div.donation .Squarecontainer .Squarecheckmark::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
}
div.donation .descriptionContainer {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 14px;
}
@media all and (max-width: 480px) {
  div.donation .descriptionContainer {
    font-size: 15px;
  }
}
div.donation .descriptionContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
div.donation .descCheckmark {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  background-color: #eee;
}
div.donation .descCheckmark.right {
  margin-left: 10px;
}
div.donation .descriptionContainer:hover input ~ .descCheckmark {
  background-color: #ccc;
}
div.donation .descriptionContainer input:checked ~ .descCheckmark {
  background-color: var(--primary-color);
}
div.donation .descCheckmark::after {
  content: "";
  position: absolute;
  display: none;
}
div.donation .descriptionContainer input:checked ~ .descCheckmark::after {
  display: block;
}
div.donation .descriptionContainer .descCheckmark::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
}

div.buildingFund {
  padding: 0 0 80px;
}
div.buildingFund div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 641px) {
  div.buildingFund div.wrapper h1.heading {
    padding-left: 20px;
  }
}
div.buildingFund div.wrapper div.videoContainer {
  width: 80%;
  margin: 30px auto 0;
}
@media all and (max-width: 480px) {
  div.buildingFund div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.buildingFund div.wrapper div.videoContainer video {
  width: 100%;
  display: block;
}
div.buildingFund div.wrapper div.details {
  width: 80%;
  margin: 20px auto 0;
}
@media all and (max-width: 480px) {
  div.buildingFund div.wrapper div.details {
    width: 90%;
  }
}
div.buildingFund div.wrapper div.details p {
  font-size: 15px;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.buildingFund div.wrapper div.details p {
    font-size: 14px;
  }
}
div.buildingFund div.wrapper div.details span.link {
  display: inline-block;
  color: var(--primary-color);
  font-size: 16px;
  display: block;
  text-align: center;
}
@media all and (max-width: 480px) {
  div.buildingFund div.wrapper div.details span.link {
    font-size: 14px;
  }
}
div.buildingFund div.gallery {
  display: flex;
  margin-top: 30px;
}
div.buildingFund div.gallery div.imageContainer {
  width: 34%;
}
div.buildingFund div.gallery div.imageContainer img {
  width: 100%;
  display: block;
  height: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 980px) {
  div.buildingFund div.gallery div.imageContainer img {
    height: 200px;
  }
}
@media all and (max-width: 641px) {
  div.buildingFund div.gallery div.imageContainer img {
    height: 170px;
  }
}
@media all and (max-width: 480px) {
  div.buildingFund div.gallery div.imageContainer img {
    height: 130px;
  }
}
div.buildingFund div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.buildingFund div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--secondary-bg-color);
  width: 15%;
  animation: slideDown 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.buildingFund div.buttonContainer div.shareContainer {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.buildingFund div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.buildingFund div.buttonContainer div.shareContainer {
    width: 40%;
  }
}
div.buildingFund div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.buildingFund div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.buildingFund div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.buildingFund div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.buildingFund div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.buildingFund div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.israel {
  padding: 0 0 0;
}
div.israel div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 641px) {
  div.israel div.wrapper h1.heading {
    padding-left: 20px;
  }
}
div.israel div.wrapper div.videoContainer {
  width: 80%;
  margin: 30px auto 0;
}
@media all and (max-width: 480px) {
  div.israel div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.israel div.wrapper div.videoContainer video {
  width: 100%;
  display: block;
}
div.israel div.wrapper div.details {
  width: 80%;
  margin: 20px auto 0;
}
@media all and (max-width: 480px) {
  div.israel div.wrapper div.details {
    width: 90%;
  }
}
div.israel div.wrapper div.details h1.title {
  font-size: 22px;
  color: var(--white-color);
  margin-bottom: 10px;
  opacity: 0.9;
}
@media all and (max-width: 640px) {
  div.israel div.wrapper div.details h1.title {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.israel div.wrapper div.details h1.title {
    font-size: 18px;
  }
}
div.israel div.wrapper div.details p {
  font-size: 15px;
  margin-bottom: 20px;
}
div.israel div.wrapper div.details p b {
  color: var(--white-color);
}
@media all and (max-width: 768px) {
  div.israel div.wrapper div.details p {
    font-size: 14px;
  }
}
div.israel div.wrapper div.details span {
  display: block;
  color: var(--text-color);
  font-weight: 600;
}
div.israel div.wrapper div.details span.link {
  display: inline-block;
  color: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.israel div.wrapper div.details span.link {
    font-size: 14px;
  }
}
div.israel div.gallery {
  display: flex;
  margin-top: 30px;
  position: relative;
}
div.israel div.gallery .swiper-slide {
  margin-right: 1px !important;
  width: 50% !important;
}
@media all and (min-width: 320px) {
  div.israel div.gallery .swiper-slide {
    width: 100% !important;
  }
}
@media all and (min-width: 640px) {
  div.israel div.gallery .swiper-slide {
    width: 50% !important;
  }
}
div.israel div.gallery .swiper {
  padding: 0 !important;
  width: 100% !important;
}
div.israel div.gallery div.right {
  position: absolute;
  right: 0;
  height: 100%;
  background: transparent linear-gradient(96deg, rgba(16, 16, 16, 0) 11%, #101010 100%) 0% 0% no-repeat padding-box;
  width: 10%;
  z-index: 9;
  top: 0;
}
div.israel div.gallery div.left {
  position: absolute;
  left: 0;
  height: 100%;
  background: transparent linear-gradient(269deg, rgba(16, 16, 16, 0) 11%, #101010 100%) 0% 0% no-repeat padding-box;
  width: 10%;
  z-index: 9;
  top: 0;
}
div.israel div.gallery div.imageContainer {
  width: 100%;
}
div.israel div.gallery div.imageContainer span {
  width: 100% !important;
  display: block !important;
}
div.israel div.gallery div.imageContainer img {
  width: 100%;
  display: block;
  height: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 980px) {
  div.israel div.gallery div.imageContainer img {
    height: 200px;
  }
}
@media all and (max-width: 641px) {
  div.israel div.gallery div.imageContainer img {
    height: 170px;
  }
}
@media all and (max-width: 480px) {
  div.israel div.gallery div.imageContainer img {
    height: 130px;
  }
}

div.articleInfo {
  padding: 0 0 180px;
}
div.articleInfo div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-right: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-color);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto 0;
  flex-direction: column;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  height: 100%;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 6px;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 14px;
  margin-bottom: 20px;
  letter-spacing: 0.3px;
  line-height: 1.8em;
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button.sharebutton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button.sharebutton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button.sharebutton {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton svg.active {
  transform: rotate(180deg);
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button.sharebutton:hover svg {
  fill: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button.active svg.active {
  transform: rotate(0deg);
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--secondary-bg-color);
  width: 15%;
  animation: slideDown 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
    width: 17%;
  }
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
    width: 21%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer div.shareContainer {
    width: 45%;
  }
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.articleInfo div.wrapper div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
div.statementOfFaith {
  padding: 0 0 80px;
}
@media all and (max-width: 641px) {
  div.statementOfFaith {
    padding: 0 20px 80px;
  }
}
div.statementOfFaith div.wrapper div.imageContainer {
  width: 60%;
  margin: 0 auto 30px;
}
div.statementOfFaith div.wrapper div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 6px;
}
@media all and (max-width: 1200px) {
  div.statementOfFaith div.wrapper div.imageContainer {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.statementOfFaith div.wrapper div.imageContainer {
    width: 80%;
  }
}
@media all and (max-width: 481px) {
  div.statementOfFaith div.wrapper div.imageContainer {
    width: 100%;
  }
}
div.statementOfFaith div.wrapper p.head {
  font-size: 20px;
  text-align: center;
  margin-bottom: 80px;
  color: var(--white-color);
  font-weight: normal;
}
@media all and (max-width: 1200px) {
  div.statementOfFaith div.wrapper p.head {
    font-size: 18px;
  }
}
@media all and (max-width: 980px) {
  div.statementOfFaith div.wrapper p.head {
    font-size: 16px;
  }
}
@media all and (max-width: 768px) {
  div.statementOfFaith div.wrapper p.head {
    margin-bottom: 50px;
  }
}
@media all and (max-width: 480px) {
  div.statementOfFaith div.wrapper p.head {
    font-size: 16px;
  }
}
div.statementOfFaith div.wrapper div.contentsContainer {
  width: 70%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.statementOfFaith div.wrapper div.contentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.statementOfFaith div.wrapper div.contentsContainer {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.statementOfFaith div.wrapper div.contentsContainer {
    width: 90%;
  }
}
div.statementOfFaith div.wrapper div.contentsContainer div.contents {
  margin-bottom: 50px;
}
div.statementOfFaith div.wrapper div.contentsContainer div.contents h1.title {
  font-size: 18px;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.statementOfFaith div.wrapper div.contentsContainer div.contents h1.title {
    font-size: 16px;
  }
}
div.statementOfFaith div.wrapper div.contentsContainer div.contents div.details {
  border-left: 2px solid var(--text-color);
  padding-left: 15px;
}
div.statementOfFaith div.wrapper div.contentsContainer div.contents div.details p {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.statementOfFaith div.wrapper div.contentsContainer div.contents div.details p {
    font-size: 14px;
  }
}
div.statementOfFaith div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 3px 10px;
  width: 10%;
  border-radius: 6px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
  font-size: 13px;
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton.active svg.active {
  transform: rotate(0deg);
}
@media all and (max-width: 1400px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 12%;
  }
}
@media all and (max-width: 1200px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 14%;
  }
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton svg.share {
    margin-right: 6px;
  }
}
@media all and (max-width: 980px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 640px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 40%;
    padding: 3px 15px;
  }
}
@media all and (max-width: 360px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton {
    width: 45%;
    padding: 3px 15px;
  }
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton svg.share {
  margin-right: 8px;
}
@media all and (max-width: 1200px) {
  div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton svg.share {
    margin-right: 6px;
  }
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton svg {
  transition: 0.4s ease-in-out;
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton svg.active {
  transform: rotate(180deg);
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton:hover {
  border-color: var(--icons-color);
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton:hover span {
  color: var(--icons-color);
}
div.statementOfFaith div.wrapper div.buttonContainer button.sharebutton:hover svg {
  fill: var(--icons-color);
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--secondary-bg-color);
  width: 15%;
  animation: slideDown 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer {
    width: 40%;
  }
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer span.whatsapp {
  color: #2a9b77;
  font-size: 14px;
}
div.statementOfFaith div.wrapper div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.healingScriptures {
  padding: 0 0 80px;
}
@media all and (max-width: 641px) {
  div.healingScriptures {
    padding: 0 20px 80px;
  }
}
div.healingScriptures div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.healingScriptures div.wrapper div.imageContainer {
  width: 50%;
  margin: 30px auto 30px;
}
@media all and (max-width: 768px) {
  div.healingScriptures div.wrapper div.imageContainer {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.healingScriptures div.wrapper div.imageContainer {
    width: 80%;
  }
}
div.healingScriptures div.wrapper div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 6px;
}
div.healingScriptures div.wrapper p {
  font-size: 20px;
  text-align: center;
  margin-bottom: 80px;
}
@media all and (max-width: 980px) {
  div.healingScriptures div.wrapper p {
    font-size: 18px;
  }
}
@media all and (max-width: 480px) {
  div.healingScriptures div.wrapper p {
    font-size: 16px;
  }
}
div.healingScriptures div.wrapper div.contents {
  margin-bottom: 50px;
  width: 70%;
  margin: 0 auto 50px;
}
@media all and (max-width: 768px) {
  div.healingScriptures div.wrapper div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.healingScriptures div.wrapper div.contents {
    width: 90%;
  }
}
div.healingScriptures div.wrapper div.contents h1.title {
  font-size: 18px;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.healingScriptures div.wrapper div.contents h1.title {
    font-size: 16px;
  }
}
div.healingScriptures div.wrapper div.contents div.details {
  border-left: 2px solid var(--text-color);
  padding-left: 15px;
}
div.healingScriptures div.wrapper div.contents div.details p {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.healingScriptures div.wrapper div.contents div.details p {
    font-size: 14px;
  }
}
div.healingScriptures div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton.active svg.active {
  transform: rotate(0deg);
}
@media all and (max-width: 980px) {
  div.healingScriptures div.wrapper div.buttonContainer button.sharebutton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.healingScriptures div.wrapper div.buttonContainer button.sharebutton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.healingScriptures div.wrapper div.buttonContainer button.sharebutton {
    width: 35%;
    padding: 6px 15px;
  }
}
@media all and (max-width: 360px) {
  div.healingScriptures div.wrapper div.buttonContainer button.sharebutton {
    width: 45%;
    padding: 6px 15px;
  }
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton svg {
  transition: 0.4s ease-in-out;
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton svg.active {
  transform: rotate(180deg);
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton:hover {
  border-color: var(--icons-color);
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton:hover span {
  color: var(--icons-color);
}
div.healingScriptures div.wrapper div.buttonContainer button.sharebutton:hover svg {
  fill: var(--icons-color);
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--secondary-bg-color);
  width: 15%;
  animation: slideDown 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.healingScriptures div.wrapper div.buttonContainer div.shareContainer {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.healingScriptures div.wrapper div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.healingScriptures div.wrapper div.buttonContainer div.shareContainer {
    width: 40%;
  }
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.healingScriptures div.wrapper div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.videoPlayer {
  padding: 0 0 16px;
}
@media all and (max-width: 640px) {
  div.videoPlayer {
    padding: 0 20px 80px;
  }
}
div.videoPlayer div.wrapper div.videoContainer {
  width: 100%;
}
div.videoPlayer div.wrapper div.videoContainer video {
  width: 100%;
  display: block;
}
div.videoPlayer div.wrapper div.detailsContainer {
  margin-top: 20px;
}
div.videoPlayer div.wrapper div.detailsContainer div.top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.videoPlayer div.wrapper div.detailsContainer div.top div.left h1.title {
  font-size: 22px;
}
div.videoPlayer div.wrapper div.detailsContainer div.top div.right div.dateSection {
  display: flex;
  align-items: center;
}
div.videoPlayer div.wrapper div.detailsContainer div.top div.right div.dateSection span.icon {
  width: 15px;
  margin-right: 10px;
  display: block;
}
div.videoPlayer div.wrapper div.detailsContainer div.top div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.videoPlayer div.wrapper div.detailsContainer div.top div.right div.dateSection span.date {
  font-size: 14px;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom {
  margin-top: 20px;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom p {
  font-size: 14px;
  margin-bottom: 0;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
  margin-top: 10px;
  position: relative;
  width: 10%;
}
@media all and (max-width: 1400px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
    width: 13%;
  }
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer svg.share {
    width: 14px !important;
    height: 14px !important;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
    width: 25%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer {
    width: 40%;
  }
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: var(--secondary-bg-color);
  width: 100%;
  animation: slideDown 0.4s ease-in-out;
  z-index: 9;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer svg.active {
  width: 12px !important;
  height: 12px !important;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 4px 10px;
  width: 100%;
  border-radius: 6px;
  color: var(--primary-color);
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share.active svg.active {
  transform: rotate(0deg);
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share svg {
  transition: 0.4s ease-in-out;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share svg.active {
  transform: rotate(180deg);
}
@media all and (max-width: 980px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share {
    font-size: 14px;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share {
    padding: 6px 15px;
  }
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share svg {
  transition: 0.4s ease-in-out;
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share:hover {
  border-color: var(--icons-color);
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share:hover span {
  color: var(--icons-color);
}
div.videoPlayer div.wrapper div.detailsContainer div.bottom div.buttonContainer button.share:hover svg {
  fill: var(--icons-color);
}
div.videoPlayer div.wrapper div.episodesContainer {
  margin-top: 30px;
}
div.videoPlayer div.wrapper div.episodesContainer h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.videoPlayer div.wrapper div.episodesContainer div.episodes {
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
  width: 23%;
  margin-right: 30px;
  margin-bottom: 150px;
}
div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1600px) {
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
    width: 22%;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
    width: 30%;
    margin-bottom: 125px;
  }
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(4n) {
    margin-right: 30px;
  }
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
    width: 47%;
  }
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(3n) {
    margin-right: 30px;
  }
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
    width: 100%;
    margin-right: 0;
  }
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayer div.wrapper div.episodesContainer div.episodes div.episode {
    width: 100%;
  }
}
div.videoPlayer div.wrapper div.episodesContainer div.gradientBg {
  z-index: 9;
  bottom: 112px;
}
div.videoPlayer div.wrapper div.episodesContainer div.gradientBg div.buttonContainer {
  position: absolute;
  bottom: -66px;
  width: 100%;
  z-index: 9;
  background: var(--bg-color);
}
div.videoPlayer div.wrapper div.episodesContainer div.gradientBg div.buttonContainer button.showMore {
  margin-top: 38px;
}
div.donate-by-card {
  padding: 0 0 80px;
}
@media all and (max-width: 640px) {
  div.donate-by-card {
    padding: 0 20px 80px;
  }
}
div.donate-by-card span.error {
  font-size: 14px;
  color: var(--primary-color);
  display: inline-block;
  margin-top: 5px;
}
@media all and (max-width: 768px) {
  div.donate-by-card span.error {
    font-size: 12px;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card span.error {
    font-size: 10px;
  }
}
div.donate-by-card label {
  padding: 0;
  color: var(--text-color);
  font-size: 13px;
  font-weight: 600;
}
div.donate-by-card label span {
  color: var(--primary-color);
}
div.donate-by-card div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white-color);
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer {
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer {
    flex-direction: column;
  }
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer {
    flex-direction: column;
  }
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer p {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 0;
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer p {
    font-size: 14px;
  }
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer p span {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--primary-color);
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
  width: 18%;
  margin-right: 10px;
}
@media all and (max-width: 1400px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    width: 28%;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    width: 40%;
  }
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    width: 50%;
  }
}
@media all and (max-width: 360px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer span.error {
    font-size: 12px;
  }
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer button.transparent {
  display: block;
  width: 100%;
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer button.transparent {
    margin: 20px auto 0;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.buttonContainer {
    margin-top: 10px;
  }
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.inputContainer {
  background-color: var(--bg-color);
  border: 1px solid var(--primary-color);
  padding: 6px;
  border-radius: 6px;
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper div.currentDonationAmountContainer div.inputContainer {
    margin-top: 10px;
  }
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.inputContainer input {
  background-color: transparent;
  outline: none;
  border: none;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.inputContainer span {
  color: var(--primary-color);
  cursor: pointer;
}
div.donate-by-card div.wrapper div.currentDonationAmountContainer div.inputContainer label {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection {
  display: flex;
  align-items: flex-start;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background-color: #eee;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container:hover input ~ .checkmark {
  background-color: #ccc;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container input:checked ~ .checkmark {
  background-color: var(--primary-color);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container input:checked ~ .checkmark:after {
  display: block;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection .container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
  content: "1";
  width: 100px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
@media all and (max-width: 1400px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
    width: 122px;
  }
}
@media all and (max-width: 1200px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
    width: 141px;
  }
}
@media all and (max-width: 980px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
    width: 166px;
    height: 40px;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
    width: 186px;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection::before {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 0;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection h1.subHeading {
  font-size: 20px;
  margin-bottom: 20px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection p {
  font-size: 16px;
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection p {
    font-size: 14px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.giftSection label.container {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails::before {
  content: "2";
  width: 50px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails::before {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 0;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer {
  width: 100%;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer h1.subHeading {
  font-size: 20px;
  margin-bottom: 20px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo {
  margin-bottom: 20px;
  display: flex;
  width: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo {
    width: 100%;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo div.Container {
  display: flex;
  flex-direction: column;
  width: 48%;
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo div.Container {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo div.Container label {
  margin-bottom: 8px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo div.Container input {
  padding: 6px 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.personDetails div.personDetailsContainer form div.oneByTwo div.Container select {
  padding: 6px 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection {
  display: flex;
  margin-bottom: 30px;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container:hover input ~ .checkmark {
  background-color: #ccc;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container input:checked ~ .checkmark {
  background-color: var(--primary-color);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container input:checked ~ .checkmark:after {
  display: block;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection .container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection::before {
  content: "3";
  width: 65px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
@media all and (max-width: 1200px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection::before {
    width: 85px;
  }
}
@media all and (max-width: 980px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection::before {
    width: 100px;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection::before {
    width: 130px;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection::before {
    width: 50px;
    height: 50px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer h1.subHeading {
  font-size: 20px;
  margin-bottom: 20px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer p {
  font-size: 16px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay span.error {
  position: absolute;
  right: 143px;
  top: -4px;
}
@media all and (max-width: 1200px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay span.error {
    font-size: 13px;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay span.error {
    right: 0;
    top: 20px;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay span.error {
    font-size: 10px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay:last-child {
  margin-bottom: 0;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay div.left span {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay div.right {
  display: flex;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay div.right label.container {
  font-size: 14px;
  padding-left: 30px;
  margin-right: 20px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.contactSection div.contactContainer div.contactWay div.right label.container:last-child {
  margin-right: 0;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails {
  display: flex;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container:hover input ~ .checkmark {
  background-color: #ccc;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container input:checked ~ .checkmark {
  background-color: var(--primary-color);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container input:checked ~ .checkmark:after {
  display: block;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails .container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails::before {
  content: "4";
  width: 48px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails::before {
    width: 50px;
    height: 50px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer {
  width: 100%;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer h1.subHeading {
  font-size: 20px;
  margin-bottom: 20px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form {
  width: 100%;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.oneByOne {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.oneByOne {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.oneByOne {
    width: 100%;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.oneByOne label {
  margin-bottom: 10px;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.oneByOne input {
  padding: 6px 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection span.error {
  position: absolute;
  right: 153px;
  top: -4px;
}
@media all and (max-width: 1200px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection span.error {
    font-size: 13px;
  }
}
@media all and (max-width: 768px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection span.error {
    right: 0;
    top: 20px;
  }
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection span.error {
    font-size: 10px;
  }
}
@media all and (max-width: 480px) {
  div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection span.error {
    top: 31px;
  }
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection div.left span {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 600;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection div.left span span {
  color: var(--primary-color);
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection div.right {
  display: flex;
  align-items: center;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection div.right label.container {
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 0;
}
div.donate-by-card div.wrapper ul.donationFormContainer li.accountDetails div.accountDetailsContainer form div.dateSection div.right label.container:last-child {
  margin-right: 0;
}
div.donate-by-card div.wrapper div.donateButtonContainer {
  text-align: center;
  margin-top: 80px;
}
div.donate-by-card div.wrapper div.donateButtonContainer button.bg-button {
  margin: 0 auto;
  width: 15%;
}
@media all and (max-width: 640px) {
  div.donate-by-card div.wrapper div.donateButtonContainer button.bg-button {
    width: 30%;
  }
}

div.show-information {
  padding: 0 0 80px;
}
@media all and (max-width: 640px) {
  div.show-information {
    padding: 0 20px 80px;
  }
}
div.show-information div.spotlight div.featuredContainer {
  position: relative;
  padding: 80px 50px;
  overflow: hidden;
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.featuredContainer {
    padding: 30px 20px;
  }
}
div.show-information div.spotlight div.featuredContainer div.gradientBg {
  bottom: 0 !important;
}
div.show-information div.spotlight div.featuredContainer div.background {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(80px);
}
div.show-information div.spotlight div.featuredContainer div.background img {
  display: block;
  width: 100%;
}
div.show-information div.spotlight div.featuredContainer div.imageContainer {
  display: block;
  max-width: 60%;
  height: auto;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.featuredContainer div.imageContainer {
    max-width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.spotlight div.featuredContainer div.imageContainer {
    max-width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.featuredContainer div.imageContainer {
    max-width: 100%;
  }
}
div.show-information div.spotlight div.featuredContainer div.imageContainer img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}
div.show-information div.spotlight div.details {
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.details {
    padding: 10px 0;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details {
    flex-direction: column;
  }
}
div.show-information div.spotlight div.details div.left {
  width: 60%;
}
@media all and (max-width: 1400px) {
  div.show-information div.spotlight div.details div.left {
    width: 70%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.spotlight div.details div.left {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.spotlight div.details div.left {
    width: 70%;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.show-information div.spotlight div.details div.left h1 {
  font-size: 22px;
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.left h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.details div.left h1 {
    font-size: 18px;
  }
}
div.show-information div.spotlight div.details div.left p {
  margin-bottom: 0;
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.left p {
    font-size: 13px;
  }
}
div.show-information div.spotlight div.details div.right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: right;
}
@media all and (max-width: 1400px) {
  div.show-information div.spotlight div.details div.right {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.spotlight div.details div.right {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.spotlight div.details div.right {
    width: 45%;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.right {
    width: 100%;
    justify-content: left;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.details div.right {
    justify-content: center;
  }
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer {
  width: 25%;
  margin-right: 20px;
  position: relative;
}
@media all and (max-width: 1400px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 40%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 33%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 28%;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 50%;
  }
}
@media all and (max-width: 360px) {
  div.show-information div.spotlight div.details div.right div.shareButtonContainer {
    width: 65%;
  }
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer {
  border-radius: 3px;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  width: 100%;
  animation: slideDown 0.4s ease-in-out;
  z-index: 9;
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.show-information div.spotlight div.details div.right div.shareButtonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}
div.show-information div.spotlight div.details div.right button.transparent {
  padding: 4px 3px;
  width: 100%;
}
div.show-information div.spotlight div.details div.right button.transparent span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.show-information div.spotlight div.details div.right button.transparent {
    padding: 3px;
  }
}
div.show-information div.spotlight div.details div.right button.transparent.active svg.active {
  transform: rotate(0deg);
}
div.show-information div.spotlight div.details div.right button.transparent:hover span {
  color: var(--icons-color);
}
div.show-information div.spotlight div.details div.right button.transparent svg.share {
  width: 15px !important;
  height: 15px !important;
}
@media all and (max-width: 1400px) {
  div.show-information div.spotlight div.details div.right button.transparent svg.share {
    width: 14px !important;
    height: 14px !important;
  }
}
div.show-information div.spotlight div.details div.right button.transparent svg.active {
  transform: rotate(180deg);
  transition: 0.4s ease-in-out;
  width: 10px !important;
  height: 10px !important;
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.right button.transparent {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.show-information div.spotlight div.details div.right button.transparent {
    padding: 6px 6px;
  }
}
div.show-information div.spotlight div.details div.right button.bg-button {
  padding: 4px 16px 4px 3px;
  width: 25%;
  justify-content: right;
  font-size: 12px;
}
div.show-information div.spotlight div.details div.right button.bg-button svg {
  height: 20px !important;
  width: 20px !important;
}
@media all and (max-width: 1400px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    width: 43%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    padding: 3px 16px 4px 3px;
    width: 35%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    font-size: 12px;
    width: 20%;
    padding: 2px 16px 2px 3px;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    width: 28%;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.spotlight div.details div.right button.bg-button {
    width: 40%;
  }
}
div.show-information div.scheduleContainer {
  margin-top: 40px;
}
div.show-information div.scheduleContainer h1.heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
  font-size: 22px;
  margin-bottom: 20px;
}
div.show-information div.scheduleContainer div.detailsContainer {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.show-information div.scheduleContainer div.detailsContainer {
    flex-direction: column;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.left {
  width: 48%;
}
@media all and (max-width: 768px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.left h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime {
  border-left: 1px solid var(--white-color);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime {
    width: 100%;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
}
@media all and (max-width: 360px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span {
    font-size: 13px;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
  width: 26%;
  margin-bottom: 10px;
}
div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1400px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
    width: 32%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
    width: 35%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
    width: 34%;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.top {
    width: 50%;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.left div.scheduleTime span.time {
  color: var(--primary-color);
}
div.show-information div.scheduleContainer div.detailsContainer div.right {
  width: 48%;
}
@media all and (max-width: 768px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right {
    width: 58%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.right h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime {
  border-left: 1px solid var(--white-color);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime {
    width: 100%;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
}
@media all and (max-width: 360px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span {
    font-size: 13px;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
  margin-bottom: 10px;
  width: 26%;
}
div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1400px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 32%;
  }
}
@media all and (max-width: 1200px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 35%;
  }
}
@media all and (max-width: 980px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 39%;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 44%;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 34%;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.top {
    width: 50%;
  }
}
div.show-information div.scheduleContainer div.detailsContainer div.right div.scheduleTime span.time {
  color: var(--primary-color);
}
div.show-information div.librarySection h1.heading {
  font-size: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
div.show-information div.librarySection div.libraries {
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
div.show-information div.librarySection div.libraries div.items {
  width: 22%;
  margin-bottom: 150px;
  margin-right: 30px;
}
div.show-information div.librarySection div.libraries div.items:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.show-information div.librarySection div.libraries div.items {
    width: 30%;
    margin-bottom: 125px;
  }
  div.show-information div.librarySection div.libraries div.items:nth-child(4n) {
    margin-right: 30px;
  }
  div.show-information div.librarySection div.libraries div.items:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.show-information div.librarySection div.libraries div.items {
    width: 47%;
  }
  div.show-information div.librarySection div.libraries div.items:nth-child(3n) {
    margin-right: 30px;
  }
  div.show-information div.librarySection div.libraries div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.show-information div.librarySection div.libraries div.items {
    width: 100%;
    margin-right: 0;
  }
  div.show-information div.librarySection div.libraries div.items:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.show-information div.librarySection div.libraries div.items {
    width: 100%;
  }
}
div.show-information div.buttonContainer {
  position: absolute;
  bottom: -71px;
  width: 100%;
  z-index: 9;
  background: var(--bg-color);
}

div.contact {
  padding: 0 0 80px;
}
@media all and (max-width: 641px) {
  div.contact {
    padding: 0 20px 80px;
  }
}
div.contact h1.heading {
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
  padding-left: 20px;
}
div.contact div.contactContainer {
  background-color: var(--secondary-bg-color);
  padding: 80px 80px 120px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media all and (max-width: 768px) {
  div.contact div.contactContainer {
    padding: 60px 60px 80px;
  }
}
@media all and (max-width: 480px) {
  div.contact div.contactContainer {
    padding: 30px 30px 40px;
  }
}
@media all and (max-width: 360px) {
  div.contact div.contactContainer {
    padding: 30px 10px 40px;
  }
}
@media all and (max-width: 768px) {
  div.contact div.contactContainer {
    flex-direction: column;
  }
}
div.contact div.contactContainer div.left {
  width: 40%;
}
@media all and (max-width: 980px) {
  div.contact div.contactContainer div.left {
    width: 49%;
  }
}
@media all and (max-width: 768px) {
  div.contact div.contactContainer div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.contact div.contactContainer div.left h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
div.contact div.contactContainer div.left div.UKContactContainer {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid var(--text-color);
}
div.contact div.contactContainer div.left div.UKContactContainer div.oneByTwo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.contact div.contactContainer div.left div.UKContactContainer div.oneByTwo div.logoContainer {
  width: 15px;
  margin-right: 10px;
}
div.contact div.contactContainer div.left div.UKContactContainer div.oneByTwo div.logoContainer img {
  display: block;
  width: 100%;
}
div.contact div.contactContainer div.left div.UKContactContainer div.oneByTwo span {
  color: var(--text-color);
  font-size: 14px;
  display: block;
}
div.contact div.contactContainer div.right {
  width: 40%;
}
@media all and (max-width: 980px) {
  div.contact div.contactContainer div.right {
    width: 49%;
  }
}
@media all and (max-width: 768px) {
  div.contact div.contactContainer div.right {
    width: 100%;
  }
}
div.contact div.contactContainer div.right h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
div.contact div.contactContainer div.right div.USAContactContainer {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid var(--text-color);
}
div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo div.logoContainer {
  width: 15px;
  margin-right: 10px;
}
div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo div.logoContainer img {
  display: block;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo div.logoContainer.location {
    font-size: 15px;
  }
}
@media all and (max-width: 1200px) {
  div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo div.prayer a.tel {
    font-size: 15px;
  }
}
div.contact div.contactContainer div.right div.USAContactContainer div.oneByTwo span {
  color: var(--text-color);
  font-size: 14px;
  display: block;
}
div.contact div.contactContainer div.technicalSupport {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
div.contact div.contactContainer div.technicalSupport span {
  color: var(--text-color);
  font-size: 14px;
}
div.contact div.contactContainer div.technicalSupport span span {
  color: var(--primary-color);
  cursor: pointer;
  margin-left: 6px;
}

div.searchResult {
  padding: 0 0 60px;
}
@media all and (max-width: 1200px) {
  div.searchResult {
    padding: 0 0 10px;
  }
}
div.searchResult h1.heading {
  font-size: 24px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.searchResult h1.heading {
    padding-left: 20px;
  }
}
div.searchResult h1.noData {
  color: var(--primary-color);
  text-align: center;
  padding: 100px 0;
}

div.accountSettings {
  padding: 0 0 130px;
}
div.accountSettings div.wrapper div.head h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--heading-color);
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.head {
    padding-left: 20px;
  }
}
div.accountSettings div.wrapper div.contents {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.wrapper div.contents {
    width: 90%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.wrapper div.contents {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents {
    flex-direction: column;
    justify-content: center;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.wrapper div.contents {
    padding: 80px 20px;
  }
}
div.accountSettings div.wrapper div.contents div.left {
  width: 40%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
  width: 250px;
  text-align: center;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.left div.profileImageContainer {
    margin: 0 auto;
  }
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer img {
  display: block;
  width: 100%;
  border-radius: 50%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer input {
  display: none;
}
div.accountSettings div.wrapper div.contents div.left div.profileImageContainer label {
  color: var(--primary-color);
  font-size: 14px;
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;
}
div.accountSettings div.wrapper div.contents div.right {
  width: 55%;
}
@media all and (max-width: 768px) {
  div.accountSettings div.wrapper div.contents div.right {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form input {
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 8px 10px;
  width: 100%;
  font-size: 14px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span.error {
  font-size: 12px;
  color: var(--primary-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form span.success {
  font-size: 12px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form label {
  font-size: 14px;
  color: var(--heading-color);
}
div.accountSettings div.wrapper div.contents div.right div.formSection form select {
  -webkit-appearance: none;
  padding: 8px 6px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
  display: block;
  width: 100%;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.fname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.email input {
  background-color: #fff;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.lname, div.accountSettings div.wrapper div.contents div.right div.formSection form div.half div.country {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.password {
  width: 49%;
}
@media all and (max-width: 480px) {
  div.accountSettings div.wrapper div.contents div.right div.formSection form div.full div.password {
    width: 100%;
  }
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.full span {
  color: var(--primary-color);
  font-size: 12px;
}
div.accountSettings div.wrapper div.contents div.right div.formSection form div.buttonContainer {
  margin-top: 30px;
}
div.ChangePassword {
  padding: 0 0 120px;
}
div.ChangePassword span.error {
  font-size: 14px;
  color: var(--primary-color);
  display: block;
  margin-top: 10px;
}
div.ChangePassword span.error.center {
  text-align: center;
}
div.ChangePassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.ChangePassword h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.ChangePassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.ChangePassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.ChangePassword div.contents {
    padding: 0 10px;
  }
}
div.ChangePassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.ChangePassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.ChangePassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.ChangePassword div.contents div.formSection {
    width: 85%;
  }
}
div.ChangePassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer label {
  font-size: 14px;
  margin-bottom: 8px;
}
div.ChangePassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
}
div.ChangePassword div.contents div.formSection form div.buttonContainer button {
  margin: 0 auto;
}

div.subscribe {
  padding: 60px 0 100px;
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
@media all and (max-width: 640px) {
  div.subscribe {
    padding: 200px 0 100px;
  }
}
div.subscribe span.error {
  font-size: 14px;
  color: var(--primary-color);
}
div.subscribe span.error.contact {
  text-align: center;
  display: block;
}
div.subscribe span.success {
  font-size: 14px;
  text-align: center;
  display: block;
}
div.subscribe div.wrapper div.subscribeContainer {
  background-color: var(--secondary-bg-color);
  position: relative;
  padding: 100px 90px;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer {
    padding: 100px 40px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.logoContainer {
  width: 300px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.logoContainer {
    width: 250px;
    top: -50px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.logoContainer img {
  display: block;
  width: 100%;
}
div.subscribe div.wrapper div.subscribeContainer h1.heading {
  text-align: center;
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
  max-width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.subscribe div.wrapper div.subscribeContainer h1.heading {
    max-width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer h1.heading {
    max-width: 50%;
  }
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer h1.heading {
    max-width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.subscribe div.wrapper div.subscribeContainer h1.heading {
    max-width: 100%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection {
  margin: 40px 0 0;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections {
  width: 60%;
  margin: 0 auto 40px;
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections {
    width: 90%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half {
    flex-direction: column;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half div.inputContainer {
  display: flex;
  flex-direction: column;
  width: 47%;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half div.inputContainer {
    width: 100%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half div.inputContainer label {
  font-size: 13px;
  margin-bottom: 5px;
  color: var(--white-color);
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half div.inputContainer input {
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 10px;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.half div.inputContainer select {
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 10px;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.full {
  width: 100%;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.full label {
  font-size: 13px;
  margin-bottom: 5px;
  color: var(--white-color);
  display: block;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.full input {
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 10px;
  width: 47%;
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.inputSections div.full input {
    width: 100%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection {
    width: 25%;
  }
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection {
    width: 40%;
  }
}
@media all and (max-width: 480px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection {
    width: 60%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.checkboxSection div.inputContainer label.Squarecontainer {
  font-size: 14px;
  color: var(--text-color);
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.full p {
  font-size: 14px;
  text-align: center;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.full p:nth-child(2) {
  margin-bottom: 40px;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.full p:nth-child(3) {
  margin-bottom: 40px;
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.full p {
    font-size: 13px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form p.contact {
  font-size: 14px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form p.contact {
    font-size: 13px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.buttonContainer {
  text-align: center;
  margin-top: 30px;
}
div.subscribe div.wrapper div.subscribeContainer div.formSection form div.buttonContainer button {
  width: 23%;
  padding: 6px 8px;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.buttonContainer button {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.buttonContainer button {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.subscribe div.wrapper div.subscribeContainer div.formSection form div.buttonContainer button {
    width: 50%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection {
  display: flex;
  width: 80%;
  margin: 30px auto 0;
  align-items: center;
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection {
    flex-direction: column;
  }
}
@media all and (max-width: 640px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection {
    width: 100%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left {
  width: 40%;
  margin-right: 50px;
}
@media all and (max-width: 1200px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left {
    margin-right: 40px;
  }
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left {
    margin-right: 30px;
  }
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left {
    width: 60%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left div.imageContainer {
  width: 100%;
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.left div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 5px;
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right {
  right: 60%;
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right h1 {
  font-size: 22px;
  margin-bottom: 40px;
}
@media all and (max-width: 1400px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right h1 {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer svg {
  color: var(--primary-color);
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 6px;
  width: 50%;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button {
    width: 80%;
  }
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button svg {
  margin-right: 10px;
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
div.subscribe div.wrapper div.subscribeContainer div.magazineSection div.right div.downloadbuttonContainer button:hover svg polygon {
  fill: var(--icons-color);
}
div.subscribe .Squarecontainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
div.subscribe .Squarecontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
div.subscribe .Squarecheckmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  background-color: #eee;
}
div.subscribe .Squarecontainer:hover input ~ .Squarecheckmark {
  background-color: #ccc;
}
div.subscribe .Squarecontainer input:checked ~ .Squarecheckmark {
  background-color: var(--primary-color);
}
div.subscribe .Squarecheckmark::after {
  content: "";
  position: absolute;
  display: none;
}
div.subscribe .Squarecontainer input:checked ~ .Squarecheckmark::after {
  display: block;
}
div.subscribe .Squarecontainer .Squarecheckmark::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
}

div.allshows {
  padding: 0 0 60px;
}
@media all and (max-width: 1200px) {
  div.allshows {
    padding: 0 0 10px;
  }
}
div.allshows h1.heading {
  font-size: 24px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.allshows h1.heading {
    padding-left: 20px;
  }
}
div.allshows div.showsList {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 640px) {
  div.allshows div.showsList {
    padding: 0 20px;
  }
}
div.allshows div.showsList div.items {
  width: 22%;
  margin-bottom: 150px;
  margin-right: 28px;
}
div.allshows div.showsList div.items:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.allshows div.showsList div.items {
    width: 30%;
  }
  div.allshows div.showsList div.items:nth-child(4n) {
    margin-right: 28px;
  }
  div.allshows div.showsList div.items:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.allshows div.showsList div.items {
    width: 47%;
  }
  div.allshows div.showsList div.items:nth-child(4n) {
    margin-right: 0;
  }
  div.allshows div.showsList div.items:nth-child(3n) {
    margin-right: 20px;
  }
  div.allshows div.showsList div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.allshows div.showsList div.items {
    width: 100%;
    margin-right: 0 !important;
  }
}

div.register-login {
  padding: 150px 0;
  position: relative;
}
div.register-login span.forgot {
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  display: block;
  margin-top: 10px;
}
@media all and (max-width: 640px) {
  div.register-login {
    padding: 150px 30px;
  }
}
div.register-login h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
}
div.register-login span.error {
  color: var(--primary-color);
  font-size: 12px;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}
div.register-login span.success {
  font-size: 12px;
}
div.register-login div.registerLoginContainer {
  border-top: 1px solid var(--white-color);
  background-color: var(--secondary-bg-color);
  padding: 70px 70px 40px 70px;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.register-login div.registerLoginContainer {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer {
    padding: 30px;
  }
}
div.register-login div.registerLoginContainer div.register {
  width: 53%;
}
@media all and (max-width: 980px) {
  div.register-login div.registerLoginContainer div.register {
    width: 100%;
  }
}
div.register-login div.registerLoginContainer div.register h1.title {
  font-size: 20px;
}
@media all and (max-width: 480px) {
  div.register-login div.registerLoginContainer div.register h1.title {
    font-size: 17px;
  }
}
div.register-login div.registerLoginContainer div.register div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.register-login div.registerLoginContainer div.register div.otpContainer {
  z-index: 9;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--secondary-bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px;
  border-radius: 6px;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.register-login div.registerLoginContainer div.register div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.register-login div.registerLoginContainer div.register div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.register-login div.registerLoginContainer div.register div.otpContainer {
    width: 80%;
  }
}
div.register-login div.registerLoginContainer div.register div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
}
div.register-login div.registerLoginContainer div.register div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.register-login div.registerLoginContainer div.register div.otpContainer input {
  display: block;
  margin: 0 auto;
  padding: 6px 8px;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  color: var(--text-color);
  width: 60%;
}
@media all and (max-width: 1200px) {
  div.register-login div.registerLoginContainer div.register div.otpContainer input {
    width: 80%;
  }
}
div.register-login div.registerLoginContainer div.register div.otpContainer input::-webkit-outer-spin-button,
div.register-login div.registerLoginContainer div.register div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.register-login div.registerLoginContainer div.register div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.register-login div.registerLoginContainer div.register span.error {
  color: #ff5159;
  padding-left: 6px;
  display: block;
}
div.register-login div.registerLoginContainer div.register input[type=number] {
  background-color: #141414;
  border: none;
  transition: all ease-in-out 0.5s;
}
div.register-login div.registerLoginContainer div.register span.otpError {
  color: #ff5159;
  padding-left: 6px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.register-login div.registerLoginContainer div.register span.resendCode {
  margin-top: 10px;
  cursor: pointer;
}
div.register-login div.registerLoginContainer div.register span.resendCode:hover {
  text-decoration: underline;
}
div.register-login div.registerLoginContainer div.register div.formContainer {
  margin-top: 50px;
  padding-left: 30px;
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer div.register div.formContainer {
    padding-left: 0;
  }
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo {
    flex-direction: column;
  }
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo:nth-child(3) {
  margin-bottom: 0;
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer {
  display: flex;
  flex-direction: column;
  width: 48%;
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer span.error {
  text-align: left;
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer label {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 6px;
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer input {
  padding: 8px 6px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.oneByTwo div.inputContainer select {
  -webkit-appearance: none;
  padding: 8px 6px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.buttonContainer {
  margin-top: 130px;
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer div.register div.formContainer form div.buttonContainer {
    margin-top: 30px;
  }
}
div.register-login div.registerLoginContainer div.register div.formContainer form div.buttonContainer button.bg-button {
  margin: 0 auto;
  width: 35%;
}
@media all and (max-width: 640px) {
  div.register-login div.registerLoginContainer div.register div.formContainer form div.buttonContainer button.bg-button {
    width: 50%;
  }
}
div.register-login div.registerLoginContainer div.login {
  width: 30%;
}
@media all and (max-width: 980px) {
  div.register-login div.registerLoginContainer div.login {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.register-login div.registerLoginContainer div.login span.error {
  margin-top: 20px;
}
div.register-login div.registerLoginContainer div.login h1.title {
  font-size: 20px;
}
@media all and (max-width: 480px) {
  div.register-login div.registerLoginContainer div.login h1.title {
    font-size: 17px;
  }
}
div.register-login div.registerLoginContainer div.login div.formContainer {
  width: 100%;
  margin-top: 50px;
}
div.register-login div.registerLoginContainer div.login div.formContainer form {
  width: 100%;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.full {
  width: 100%;
  margin-bottom: 20px;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.full:nth-child(2) {
  margin-bottom: 0;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.full div.inputContainer {
  display: flex;
  flex-direction: column;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.full div.inputContainer label {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 6px;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.full div.inputContainer input {
  padding: 8px 6px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.buttonContainer {
  margin-top: 40px;
}
div.register-login div.registerLoginContainer div.login div.formContainer form div.buttonContainer button {
  width: 50%;
  margin: 0 auto;
}

div.forgotPassword {
  padding: 150px 0 0;
}
div.forgotPassword span.error {
  font-size: 14px;
  color: var(--primary-color);
  text-align: left;
  display: block;
  margin-top: 10px;
}
div.forgotPassword span.success {
  font-size: 14px;
  text-align: left;
  display: block;
  margin-top: 10px;
}
div.forgotPassword h1.heading {
  font-size: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.forgotPassword h1.heading {
    padding-left: 20px;
  }
}
div.forgotPassword div.resetEmailContainer {
  width: 50%;
  margin: 70px auto;
  text-align: center;
  background-color: var(--secondary-bg-color);
  padding: 60px 60px;
  border-radius: 6px;
}
@media all and (max-width: 980px) {
  div.forgotPassword div.resetEmailContainer {
    width: 70%;
  }
}
@media all and (max-width: 768px) {
  div.forgotPassword div.resetEmailContainer {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.resetEmailContainer {
    width: 90%;
    padding: 40px;
  }
}
div.forgotPassword div.resetEmailContainer div.inputContainer {
  display: flex;
  flex-direction: column;
}
div.forgotPassword div.resetEmailContainer div.inputContainer label {
  text-align: left;
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 10px;
}
div.forgotPassword div.resetEmailContainer div.inputContainer input {
  border: none;
  outline: none;
  padding: 8px 8px;
  border-radius: 5px;
}
div.forgotPassword div.resetEmailContainer div.inputContainer button {
  width: 40%;
  margin: 30px auto 0;
}
@media all and (max-width: 1400px) {
  div.forgotPassword div.resetEmailContainer div.inputContainer button {
    width: 47%;
  }
}
@media all and (max-width: 640px) {
  div.forgotPassword div.resetEmailContainer div.inputContainer button {
    width: 60%;
  }
}
div.forgotPassword div.resetEmailContainer div.inputContainer button.buttonDisabled {
  color: var(--text-color);
  border: none;
  outline: none;
  font-size: 13px;
  padding: 6px 30px;
  border-radius: 6px;
}
div.resetPassword {
  padding: 150px 0;
}
div.resetPassword span.error {
  font-size: 14px;
  color: var(--primary-color);
  display: block;
  margin-top: 10px;
}
div.resetPassword span.error.center {
  text-align: center;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 85%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 14px;
  margin-bottom: 8px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  margin: 0 auto;
}

div.giftAidInfo {
  padding-bottom: 200px;
}
div.giftAidInfo h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.giftAidInfo h1.heading {
    padding-left: 20px;
    font-size: 22px;
  }
}
div.giftAidInfo div.contentsContainer {
  width: 70%;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.giftAidInfo div.contentsContainer {
    width: 77%;
  }
}
@media all and (max-width: 980px) {
  div.giftAidInfo div.contentsContainer {
    width: 87%;
  }
}
@media all and (max-width: 768px) {
  div.giftAidInfo div.contentsContainer {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.giftAidInfo div.contentsContainer {
    padding: 30px 20px 0;
  }
}
div.giftAidInfo div.contentsContainer div.imageContainer {
  width: 70%;
  margin: 0 auto 50px;
}
div.giftAidInfo div.contentsContainer div.imageContainer img {
  display: block;
  width: 100%;
}
@media all and (max-width: 640px) {
  div.giftAidInfo div.contentsContainer p {
    font-size: 13px;
  }
}

div.privacyPolicy {
  padding-bottom: 150px;
}
@media all and (max-width: 640px) {
  div.privacyPolicy {
    padding: 0 20px 120px;
  }
}
div.privacyPolicy h1.heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--white-color);
  font-size: 24px;
}
div.privacyPolicy h6 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
}
div.privacyPolicy h6.bottom-distance {
  margin-bottom: 30px;
}
div.privacyPolicy p {
  color: var(--text-color);
  font-size: 16px;
  font-weight: normal;
  font-weight: 600;
}
div.privacyPolicy p.bottom-distance {
  margin-bottom: 70px;
}
div.privacyPolicy h4 {
  font-size: 18px;
  color: var(--text-color);
  font-weight: 600;
}
div.privacyPolicy ul li {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 20px;
}

div.pageNotFound h1 {
  font-size: 45px;
  color: var(--primary-color);
  padding: 270px 0;
  text-align: center;
}

div.ourStudio {
  padding: 0 0 80px;
}
@media all and (max-width: 641px) {
  div.ourStudio {
    padding: 0 20px 80px;
  }
}
div.ourStudio div.wrapper div.videoContainer {
  width: 90%;
  margin: 0 auto 30px;
}
div.ourStudio div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 1200px) {
  div.ourStudio div.wrapper div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.ourStudio div.wrapper div.videoContainer {
    width: 80%;
  }
}
@media all and (max-width: 481px) {
  div.ourStudio div.wrapper div.videoContainer {
    width: 100%;
  }
}
div.ourStudio div.wrapper div.contentsContainer {
  width: 90%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.ourStudio div.wrapper div.contentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.ourStudio div.wrapper div.contentsContainer {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.ourStudio div.wrapper div.contentsContainer {
    width: 90%;
  }
}
div.ourStudio div.wrapper div.contentsContainer div.contents {
  margin-bottom: 50px;
}
div.ourStudio div.wrapper div.contentsContainer div.contents h1.title {
  font-size: 18px;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.ourStudio div.wrapper div.contentsContainer div.contents h1.title {
    font-size: 16px;
  }
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details {
  border-left: 2px solid var(--text-color);
  padding-left: 15px;
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list {
  border: none;
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li {
  display: flex;
  margin-bottom: 10px;
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li:before {
  content: "◦";
  margin-right: 10px;
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li ul li:before {
  content: "»";
  margin-right: 10px;
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li p {
  font-size: 16px;
  letter-spacing: 0.5px;
}
@media all and (max-width: 980px) {
  div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li p {
    font-size: 14px;
  }
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li p span {
  font-size: 16px;
  color: var(--primary-color);
}
@media all and (max-width: 980px) {
  div.ourStudio div.wrapper div.contentsContainer div.contents div.details.list ul li p span {
    font-size: 14px;
  }
}
div.ourStudio div.wrapper div.contentsContainer div.contents div.details p {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.ourStudio div.wrapper div.contentsContainer div.contents div.details p {
    font-size: 14px;
  }
}

div.spotify {
  padding: 30px 0;
}
div.spotify div.top div.head {
  background-color: #008000;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.spotify div.top div.head {
    width: 95%;
    flex-direction: column;
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 640px) {
  div.spotify div.top div.head div.left {
    margin-bottom: 10px;
  }
}
div.spotify div.top div.head div.left h1 {
  font-size: 58px;
  font-family: "Lato", sans-serif !important;
}
@media all and (max-width: 640px) {
  div.spotify div.top div.head div.left h1 {
    font-size: 38px;
  }
}
div.spotify div.top div.head div.right div.imageContainer {
  width: 150px;
}
div.spotify div.top div.head div.right div.imageContainer img {
  display: block;
  width: 100%;
}
div.spotify div.bottom div.podcastsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 768px) {
  div.spotify div.bottom div.podcastsContainer {
    flex-direction: column;
  }
}
div.spotify div.bottom div.podcastsContainer div.podcast {
  width: 49%;
  margin-bottom: 20px;
}
div.spotify div.bottom div.podcastsContainer div.podcast:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 768px) {
  div.spotify div.bottom div.podcastsContainer div.podcast {
    width: 95%;
    margin: 0 auto 20px;
  }
}
div.spotify div.bottom div.podcastsContainer div.podcast iframe {
  width: 100%;
  display: block;
}

div.otherDevices {
  background-color: #fff;
  padding: 60px 0;
}
div.otherDevices h1.heading {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
  max-width: 25%;
  color: #000;
  margin-bottom: 40px;
}
@media all and (max-width: 640px) {
  div.otherDevices h1.heading {
    margin-left: 20px;
  }
}
@media all and (max-width: 640px) {
  div.otherDevices div.contents {
    width: 90%;
    margin: 0 auto;
  }
}
div.otherDevices div.contents div.sections {
  margin-bottom: 60px;
}
div.otherDevices div.contents div.sections:last-child div.imageContainer {
  width: 400px;
}
div.otherDevices div.contents div.sections div.imageContainer {
  width: 300px;
  margin-bottom: 20px;
}
div.otherDevices div.contents div.sections div.imageContainer img {
  display: block;
  width: 100%;
}
div.otherDevices div.contents div.sections div.description p {
  color: var(--bg-color);
  font-size: 15px;
}
div.otherDevices div.contents div.sections div.description p span.link {
  color: rgb(53, 53, 248);
}
div.otherDevices div.contents div.sections div.description p span.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
div.otherDevices div.contents div.date {
  display: flex;
  align-items: center;
}
div.otherDevices div.contents div.date div.icon {
  margin-right: 10px;
  width: 20px;
}
div.otherDevices div.contents div.date div.icon img {
  width: 100%;
  display: block;
}
div.otherDevices div.contents div.date span {
  color: var(--bg-color);
}
div.otherDevices div.contents div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.otherDevices div.contents div.buttonContainer button.shareButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 10px 10px;
  width: 10%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 !important;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.otherDevices div.contents div.buttonContainer button.shareButton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.otherDevices div.contents div.buttonContainer button.shareButton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.otherDevices div.contents div.buttonContainer button.shareButton {
    width: 35%;
    padding: 6px 15px;
  }
}
div.otherDevices div.contents div.buttonContainer button.shareButton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.otherDevices div.contents div.buttonContainer button.shareButton svg {
  transition: 0.4s ease-in-out;
}
div.otherDevices div.contents div.buttonContainer button.shareButton svg.active {
  transform: rotate(180deg);
}
div.otherDevices div.contents div.buttonContainer button.shareButton:hover {
  border-color: var(--icons-color);
}
div.otherDevices div.contents div.buttonContainer button.shareButton:hover span {
  color: var(--icons-color);
}
div.otherDevices div.contents div.buttonContainer button.shareButton:hover svg {
  fill: var(--icons-color);
}
div.otherDevices div.contents div.buttonContainer button.active svg.active {
  transform: rotate(0deg);
}
div.otherDevices div.contents div.buttonContainer div.shareContainer {
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  width: 10%;
  animation: slideUp 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.otherDevices div.contents div.buttonContainer div.shareContainer {
    width: 17%;
  }
}
@media all and (max-width: 980px) {
  div.otherDevices div.contents div.buttonContainer div.shareContainer {
    width: 21%;
  }
}
@media all and (max-width: 768px) {
  div.otherDevices div.contents div.buttonContainer div.shareContainer {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.otherDevices div.contents div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.otherDevices div.contents div.buttonContainer div.shareContainer {
    width: 45%;
  }
}
div.otherDevices div.contents div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.otherDevices div.contents div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.otherDevices div.contents div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.otherDevices div.contents div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.otherDevices div.contents div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.otherDevices div.contents div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.smartDevices {
  background-color: var(--bg-color);
  padding: 30px 0;
}
@media all and (max-width: 640px) {
  div.smartDevices div.wrapper {
    width: 95%;
  }
}
div.smartDevices div.smart {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
}
div.smartDevices div.smart h1.heading {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg-color);
  color: var(--primary-color);
  margin-bottom: 25px;
  max-width: 25%;
}
@media all and (max-width: 768px) {
  div.smartDevices div.smart h1.heading {
    max-width: 35%;
  }
}
@media all and (max-width: 640px) {
  div.smartDevices div.smart h1.heading {
    margin-left: 20px;
  }
}
@media all and (max-width: 480px) {
  div.smartDevices div.smart h1.heading {
    max-width: 51%;
  }
}
@media all and (max-width: 640px) {
  div.smartDevices div.smart div.contents {
    width: 90%;
    margin: 0 auto;
  }
}
div.smartDevices div.smart div.contents div.sections:last-child div.imageContainer {
  width: 400px;
}
div.smartDevices div.smart div.contents div.sections div.imageContainer {
  width: 300px;
  margin-bottom: 20px;
}
div.smartDevices div.smart div.contents div.sections div.imageContainer img {
  display: block;
  width: 100%;
}
div.smartDevices div.smart div.contents div.sections div.description p {
  color: var(--text-color);
  font-size: 15px;
}
div.smartDevices div.smart div.contents div.sections div.description p:last-child {
  margin-bottom: 0;
}
div.smartDevices div.smart div.contents div.sections div.description p span.link {
  color: rgb(53, 53, 248);
}
div.smartDevices div.smart div.contents div.sections div.description p span.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
div.smartDevices div.smart div.contents div.date {
  display: flex;
  align-items: center;
}
div.smartDevices div.smart div.contents div.date div.icon {
  margin-right: 10px;
  width: 20px;
}
div.smartDevices div.smart div.contents div.date div.icon img {
  width: 100%;
  display: block;
}
div.smartDevices div.smart div.contents div.date span {
  color: var(--bg-color);
}
div.smartDevices div.smart div.contents div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 10px 10px;
  width: 10%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 !important;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.smartDevices div.smart div.contents div.buttonContainer button.shareButton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.smartDevices div.smart div.contents div.buttonContainer button.shareButton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.smartDevices div.smart div.contents div.buttonContainer button.shareButton {
    width: 35%;
    padding: 6px 15px;
  }
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton svg {
  transition: 0.4s ease-in-out;
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton svg.active {
  transform: rotate(180deg);
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton:hover {
  border-color: var(--icons-color);
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton:hover span {
  color: var(--icons-color);
}
div.smartDevices div.smart div.contents div.buttonContainer button.shareButton:hover svg {
  fill: var(--icons-color);
}
div.smartDevices div.smart div.contents div.buttonContainer button.active svg.active {
  transform: rotate(0deg);
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  width: 10%;
  animation: slideUp 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
    width: 17%;
  }
}
@media all and (max-width: 980px) {
  div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
    width: 21%;
  }
}
@media all and (max-width: 768px) {
  div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer {
    width: 35%;
  }
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.smartDevices div.smart div.contents div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}
div.smartDevices div.others {
  margin-top: 50px;
}
div.smartDevices div.others div.threeByOne {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 980px) {
  div.smartDevices div.others div.threeByOne {
    flex-direction: column;
  }
}
div.smartDevices div.others div.threeByOne p {
  color: var(--text-color);
  font-size: 15px;
}
div.smartDevices div.others div.threeByOne p:last-child {
  margin-bottom: 0;
}
div.smartDevices div.others div.threeByOne div.freeView {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 48%;
}
@media all and (max-width: 1200px) {
  div.smartDevices div.others div.threeByOne div.freeView {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.smartDevices div.others div.threeByOne div.freeView {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.smartDevices div.others div.threeByOne div.freeView div.imageContainer {
  width: 200px;
  margin-bottom: 20px;
}
div.smartDevices div.others div.threeByOne div.freeView div.imageContainer img {
  display: block;
  width: 100%;
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast {
  width: 48%;
}
@media all and (max-width: 1200px) {
  div.smartDevices div.others div.threeByOne div.visionAndChromecast {
    width: 42%;
  }
}
@media all and (max-width: 980px) {
  div.smartDevices div.others div.threeByOne div.visionAndChromecast {
    width: 100%;
  }
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.vision {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 70px;
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.vision div.imageContainer {
  width: 200px;
  margin-bottom: 20px;
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.vision div.imageContainer img {
  display: block;
  width: 100%;
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.chromecast {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.chromecast div.imageContainer {
  width: 400px;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.smartDevices div.others div.threeByOne div.visionAndChromecast div.chromecast div.imageContainer {
    width: 300px;
  }
}
div.smartDevices div.others div.threeByOne div.visionAndChromecast div.chromecast div.imageContainer img {
  display: block;
  width: 100%;
}
div.smartDevices div.others div.full {
  margin-top: 40px;
}
div.smartDevices div.others div.full div.sky {
  background-color: #fff;
  padding: 30px 30px 60px;
  border-radius: 10px;
}
div.smartDevices div.others div.full div.sky div.images {
  display: flex;
}
div.smartDevices div.others div.full div.sky div.images div.imageContainer {
  margin-right: 30px;
  width: 200px;
  margin-bottom: 30px;
}
div.smartDevices div.others div.full div.sky div.images div.imageContainer:last-child {
  margin-right: 0;
  width: 150px;
}
div.smartDevices div.others div.full div.sky div.images div.imageContainer img {
  width: 100%;
  display: block;
}
div.smartDevices div.others div.full div.sky div.content p {
  font-size: 15px;
}
div.smartDevices div.others div.full div.sky div.content p:last-child {
  margin-bottom: 0;
}
div.smartDevices div.others div.full div.sky div.content p:first-child {
  margin-bottom: 30px;
}
div.smartDevices div.others div.full div.sky div.content ul {
  margin-bottom: 20px;
}
div.smartDevices div.others div.full div.sky div.content ul li {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}
div.smartDevices div.others div.full div.sky div.content ul li span {
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}

div.rokuDevices {
  background-color: #fff;
  padding: 20px;
}
div.rokuDevices h1.heading {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
  max-width: 80%;
  color: #000;
  margin-bottom: 40px;
}
@media all and (max-width: 640px) {
  div.rokuDevices h1.heading {
    margin-left: 20px;
    font-size: 19px;
    max-width: 85%;
  }
}
@media all and (max-width: 1200px) {
  div.rokuDevices h1.heading {
    margin-left: 20px;
    font-size: 24px;
    max-width: 88%;
  }
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents {
    width: 100%;
  }
}
div.rokuDevices div.contents div.sections {
  margin-bottom: 60px;
}
div.rokuDevices div.contents div.sections:last-child div.imageContainer {
  width: 400px;
}
div.rokuDevices div.contents div.sections div.imageContainer {
  border-style: solid;
  padding: -7px;
  justify-content: center;
  border-color: #000000;
  float: left;
  height: 100%;
  margin: 10px;
  border-width: 10px;
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents div.sections div.imageContainer {
    width: 314px;
    margin-bottom: 10px;
  }
}
div.rokuDevices div.contents div.sections div.imageContainer img {
  display: block;
  width: 100%;
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents div.sections div.description {
    max-width: 40%;
  }
}
@media all and (max-width: 1200px) {
  div.rokuDevices div.contents div.sections div.description {
    max-width: 100%;
  }
}
div.rokuDevices div.contents div.sections div.description p {
  color: var(--bg-color);
  font-size: 15px;
}
div.rokuDevices div.contents div.sections div.description p span.link {
  color: rgb(53, 53, 248);
}
div.rokuDevices div.contents div.sections div.description p span.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
div.rokuDevices div.contents div.sections div.description .italic {
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-bg-color);
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents div.sections div.description .italic {
    font-size: 10px;
  }
}
div.rokuDevices div.contents div.sections div.description .content-list {
  height: 100px;
  color: #000000;
  list-style: disc;
  margin-left: 39px;
}
div.rokuDevices div.contents div.date {
  display: flex;
  align-items: center;
}
div.rokuDevices div.contents div.date div.icon {
  margin-right: 10px;
  width: 20px;
}
div.rokuDevices div.contents div.date div.icon img {
  width: 100%;
  display: block;
}
div.rokuDevices div.contents div.date span {
  color: var(--bg-color);
}
div.rokuDevices div.contents div.video-player-container {
  width: 100%;
  margin: 0 auto 30px;
  padding: 0.5% 0 0;
  position: relative;
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.rokuDevices div.contents div.video-player-container {
    padding: 7% 0 0;
  }
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents div.video-player-container {
    padding: 5% 0 0;
  }
}
div.rokuDevices div.contents div.video-player-container video {
  width: 100%;
  display: block;
}
div.rokuDevices div.contents div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.rokuDevices div.contents div.buttonContainer button.shareButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 10px 10px;
  width: 10%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 !important;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.rokuDevices div.contents div.buttonContainer button.shareButton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.rokuDevices div.contents div.buttonContainer button.shareButton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.rokuDevices div.contents div.buttonContainer button.shareButton {
    width: 35%;
    padding: 6px 15px;
  }
}
div.rokuDevices div.contents div.buttonContainer button.shareButton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.rokuDevices div.contents div.buttonContainer button.shareButton svg {
  transition: 0.4s ease-in-out;
}
div.rokuDevices div.contents div.buttonContainer button.shareButton svg.active {
  transform: rotate(180deg);
}
div.rokuDevices div.contents div.buttonContainer button.shareButton:hover {
  border-color: var(--icons-color);
}
div.rokuDevices div.contents div.buttonContainer button.shareButton:hover span {
  color: var(--icons-color);
}
div.rokuDevices div.contents div.buttonContainer button.shareButton:hover svg {
  fill: var(--icons-color);
}
div.rokuDevices div.contents div.buttonContainer button.active svg.active {
  transform: rotate(0deg);
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer {
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: var(--secondary-bg-color);
  width: 10%;
  animation: slideUp 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.rokuDevices div.contents div.buttonContainer div.shareContainer {
    width: 17%;
  }
}
@media all and (max-width: 980px) {
  div.rokuDevices div.contents div.buttonContainer div.shareContainer {
    width: 21%;
  }
}
@media all and (max-width: 768px) {
  div.rokuDevices div.contents div.buttonContainer div.shareContainer {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.rokuDevices div.contents div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.rokuDevices div.contents div.buttonContainer div.shareContainer {
    width: 35%;
  }
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.rokuDevices div.contents div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.satteliteDevices {
  background-color: #fff;
  padding: 60px 0;
}
div.satteliteDevices h1.heading {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
  max-width: 45%;
  color: #000;
  margin-bottom: 40px;
}
@media all and (max-width: 640px) {
  div.satteliteDevices h1.heading {
    margin-left: 20px;
    max-width: 85%;
  }
}
@media all and (max-width: 640px) {
  div.satteliteDevices div.contents {
    width: 90%;
    margin: 0 auto;
  }
}
div.satteliteDevices div.contents div.sections {
  margin-bottom: 60px;
}
div.satteliteDevices div.contents div.sections:last-child div.imageContainer {
  width: 400px;
}
div.satteliteDevices div.contents div.sections div.imageContainer {
  width: 300px;
  margin-bottom: 20px;
}
div.satteliteDevices div.contents div.sections div.imageContainer img {
  display: block;
  width: 80%;
}
div.satteliteDevices div.contents div.sections div.description p {
  color: var(--bg-color);
  font-size: 15px;
}
div.satteliteDevices div.contents div.sections div.description p span.link {
  color: rgb(53, 53, 248);
}
div.satteliteDevices div.contents div.sections div.description p span.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
div.satteliteDevices div.contents div.sections div.description .content-list {
  height: 100px;
  color: #000000;
  list-style: disc;
  margin-left: 39px;
}
div.satteliteDevices div.contents div.date {
  display: flex;
  align-items: center;
}
div.satteliteDevices div.contents div.date div.icon {
  margin-right: 10px;
  width: 20px;
}
div.satteliteDevices div.contents div.date div.icon img {
  width: 100%;
  display: block;
}
div.satteliteDevices div.contents div.date span {
  color: var(--bg-color);
}
div.satteliteDevices div.contents div.buttonContainer {
  text-align: center;
  margin-top: 50px;
  position: relative;
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 10px 10px;
  width: 10%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 !important;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.satteliteDevices div.contents div.buttonContainer button.shareButton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.satteliteDevices div.contents div.buttonContainer button.shareButton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.satteliteDevices div.contents div.buttonContainer button.shareButton {
    width: 35%;
    padding: 6px 15px;
  }
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton svg {
  transition: 0.4s ease-in-out;
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton svg.active {
  transform: rotate(180deg);
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton:hover {
  border-color: var(--icons-color);
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton:hover span {
  color: var(--icons-color);
}
div.satteliteDevices div.contents div.buttonContainer button.shareButton:hover svg {
  fill: var(--icons-color);
}
div.satteliteDevices div.contents div.buttonContainer button.active svg.active {
  transform: rotate(0deg);
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  background-color: var(--secondary-bg-color);
  width: 10%;
  animation: slideUp 0.4s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
    width: 17%;
  }
}
@media all and (max-width: 980px) {
  div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
    width: 21%;
  }
}
@media all and (max-width: 768px) {
  div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.satteliteDevices div.contents div.buttonContainer div.shareContainer {
    width: 35%;
  }
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer button {
  padding: 8px 0 !important;
  padding: 8px 0 !important;
  display: block;
  text-align: center;
  margin: 0 auto;
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer button.clipboard {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer span.name {
  font-size: 14px;
  color: var(--primary-color);
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer span.twitter {
  color: #00aceb;
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer span.facebook {
  color: #3a5896;
  font-size: 14px;
}
div.satteliteDevices div.contents div.buttonContainer div.shareContainer:hover svg.bi-facebook {
  fill: #1877f2 !important;
}

div.prayerSuccessPage {
  padding: 40px 0 160px;
}
@media all and (max-width: 640px) {
  div.prayerSuccessPage {
    padding: 40px 0 120px;
  }
}
div.prayerSuccessPage div.wrapper p.message {
  font-size: 18px;
  max-width: 85%;
  margin: 0 auto;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.prayerSuccessPage div.wrapper p.message {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.prayerSuccessPage div.wrapper p.message {
    font-size: 16px;
  }
}
div.prayerSuccessPage div.wrapper p.message span {
  color: var(--primary-color);
  cursor: pointer;
}

@media all and (max-width: 640px) {
  div.magazinesList div.wrapper {
    width: 90%;
  }
}
div.magazinesList div.wrapper h1.heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.magazinesList div.wrapper h1.heading {
    padding-left: 20px;
    font-size: 22px;
  }
}
div.magazinesList div.wrapper div.magazines {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
div.magazinesList div.wrapper div.magazines div.item {
  width: 23%;
  margin-right: 30px;
  margin-bottom: 100px;
  margin-top: 20px;
}
div.magazinesList div.wrapper div.magazines div.item:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1600px) {
  div.magazinesList div.wrapper div.magazines div.item {
    margin-right: 29px;
  }
}
@media all and (max-width: 1400px) {
  div.magazinesList div.wrapper div.magazines div.item {
    margin-right: 25px;
  }
}
@media all and (max-width: 1200px) {
  div.magazinesList div.wrapper div.magazines div.item {
    margin-right: 23px;
  }
}
@media all and (max-width: 980px) {
  div.magazinesList div.wrapper div.magazines div.item {
    width: 47%;
    margin-bottom: 19px;
  }
  div.magazinesList div.wrapper div.magazines div.item:nth-child(4n) {
    margin-right: 23px;
  }
  div.magazinesList div.wrapper div.magazines div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.magazinesList div.wrapper div.magazines div.item {
    width: 46%;
    margin-right: 25px;
  }
}
@media all and (max-width: 640px) {
  div.magazinesList div.wrapper div.magazines div.item {
    width: 48%;
    margin-right: 17px;
  }
}
@media all and (max-width: 480px) {
  div.magazinesList div.wrapper div.magazines div.item {
    width: 100%;
    margin-right: 0;
  }
}
div.magazinesList div.wrapper div.magazines div.item:last-child {
  margin-right: 0;
}

div.searchallshows {
  padding: 0 0 60px;
}
@media all and (max-width: 1200px) {
  div.searchallshows {
    padding: 0 0 10px;
  }
}
div.searchallshows h1.heading {
  font-size: 24px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--white-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.searchallshows h1.heading {
    padding-left: 20px;
  }
}
div.searchallshows div.showsList {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 640px) {
  div.searchallshows div.showsList {
    padding: 0 20px;
  }
}
div.searchallshows div.showsList div.items {
  width: 22%;
  margin-bottom: 150px;
  margin-right: 28px;
}
div.searchallshows div.showsList div.items:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.searchallshows div.showsList div.items {
    width: 30%;
  }
  div.searchallshows div.showsList div.items:nth-child(4n) {
    margin-right: 28px;
  }
  div.searchallshows div.showsList div.items:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchallshows div.showsList div.items {
    width: 47%;
  }
  div.searchallshows div.showsList div.items:nth-child(4n) {
    margin-right: 0;
  }
  div.searchallshows div.showsList div.items:nth-child(3n) {
    margin-right: 20px;
  }
  div.searchallshows div.showsList div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchallshows div.showsList div.items {
    width: 100%;
    margin-right: 0 !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1.nodatas {
  text-align: center;
  color: var(--primary-color);
  padding: 72px 0;
}

.uv-popover {
  z-index: 999999999 !important;
}

div.topSpace {
  padding-top: 130px;
}
div.topSpace.emptySpace {
  padding-top: 230px;
}

div.bannerSection {
  padding-top: 118px;
  margin-bottom: 21px;
  text-align: center;
}
div.bannerSection.topSpace {
  padding-top: 222px;
}
div.bannerSection div.imageContainer {
  width: 53% !important;
  display: block !important;
  margin: 0 auto !important;
  cursor: pointer;
}
div.bannerSection div.imageContainer img {
  width: 100%;
  display: block;
}
@media all and (max-width: 640px) {
  div.bannerSection div.imageContainer {
    width: 91% !important;
    margin: 0 auto;
  }
}

.slick-prev {
  left: -20px !important;
}

.swiper-button-prev {
  left: -5px !important;
}

@media all and (max-width: 640px) {
  .slick-prev {
    left: 19px !important;
  }
  .slick-next {
    right: 17px !important;
  }
}
.swiper {
  padding: 0 40px !important;
}

.slick-prev {
  transform: rotate(-180deg) !important;
  top: 22% !important;
}
.slick-prev::before {
  color: transparent !important;
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.slick-next {
  top: 50% !important;
}
.slick-next::before {
  color: transparent !important;
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next {
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 38% !important;
  transition: 0.3s ease-in-out;
}
.swiper-button-next:hover {
  right: 2px !important;
}
@media all and (max-width: 1600px) {
  .swiper-button-next {
    top: 46% !important;
  }
}
@media all and (max-width: 980px) {
  .swiper-button-next {
    top: 50% !important;
  }
}
@media all and (max-width: 768px) {
  .swiper-button-next {
    top: 46% !important;
  }
}
@media all and (max-width: 640px) {
  .swiper-button-next {
    top: 50% !important;
  }
}
@media all and (max-width: 480px) {
  .swiper-button-next {
    top: 45% !important;
  }
}

.inner.lds-dual-ring {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  margin: 0 auto;
}

.inner.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #e50914 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

div.loadingContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.spinnerIcon {
  z-index: 99999;
  text-align: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.swiper-button-prev {
  background-image: url(./images/svg/nav-arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transform: rotate(-180deg);
  top: 38% !important;
  transition: 0.3s ease-in-out;
}
.swiper-button-prev:hover {
  left: -9px !important;
}
@media all and (max-width: 1600px) {
  .swiper-button-prev {
    top: 46% !important;
  }
}
@media all and (max-width: 980px) {
  .swiper-button-prev {
    top: 50% !important;
  }
}
@media all and (max-width: 768px) {
  .swiper-button-prev {
    top: 46% !important;
  }
}
@media all and (max-width: 640px) {
  .swiper-button-prev {
    top: 50% !important;
  }
}
@media all and (max-width: 480px) {
  .swiper-button-prev {
    top: 45% !important;
  }
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

@media all and (min-width: 1200px) {
  div.ondemand .swiper-slide {
    margin-right: 0 !important;
  }
}

div.tileContainer .slick-track {
  padding: 100px 0 168px !important;
}
div.tileContainer .swiper-wrapper {
  padding-top: 15px !important;
  position: relative;
  margin-bottom: 93px;
}

span.whatsapp {
  color: #2a9b77 !important;
  font-size: 14px !important;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

a:hover {
  color: var(--text-color);
}

span {
  color: var(--white-color);
  font-weight: 400;
}

ul {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  margin: 0;
}

p {
  color: var(--text-color);
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
}

div.cardGradient {
  background: url(./images/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 87px;
  transform: translateY(100px);
  transition: 0.4s ease-in-out;
  padding: 25px 0;
}
div.cardGradient button {
  width: 30%;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 8px 0;
  font-size: 14px;
  border-radius: 6px;
}
@media all and (max-width: 1200px) {
  div.cardGradient button {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.cardGradient button {
    font-size: 13px;
    padding: 6px 0;
  }
}

button.shareButton {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 3px 10px;
  width: 10%;
  border-radius: 6px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
  font-size: 13px;
}
button.shareButton.active svg.active {
  transform: rotate(0deg);
}
@media all and (max-width: 1400px) {
  button.shareButton {
    width: 12%;
  }
}
@media all and (max-width: 1200px) {
  button.shareButton {
    width: 14%;
  }
  button.shareButton svg.share {
    margin-right: 6px;
  }
}
@media all and (max-width: 980px) {
  button.shareButton {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 640px) {
  button.shareButton {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  button.shareButton {
    width: 40%;
    padding: 3px 15px;
  }
}
@media all and (max-width: 360px) {
  button.shareButton {
    width: 45%;
    padding: 3px 15px;
  }
}
button.shareButton span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
button.shareButton svg.share {
  margin-right: 8px;
}
@media all and (max-width: 1200px) {
  button.shareButton svg.share {
    margin-right: 6px;
  }
}
button.shareButton svg {
  transition: 0.4s ease-in-out;
}
button.shareButton svg.active {
  transform: rotate(180deg);
}
button.shareButton:hover {
  border-color: var(--icons-color);
}
button.shareButton:hover span {
  color: var(--icons-color);
}
button.shareButton:hover svg {
  fill: var(--icons-color);
}

div.gradientBg {
  background: url(./images/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 91px;
  width: 100%;
  z-index: 9;
  height: 101px;
}
div.gradientBg button.showMore {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 4px 10px 4px 15px;
  font-size: 13px;
  border-radius: 6px;
  width: 10rem;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.gradientBg button.showMore {
    font-size: 13px;
  }
}
div.gradientBg button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-left: 12px;
  width: 17px !important;
  height: 17px !important;
}
div.gradientBg button.showMore:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
div.gradientBg button.showMore:hover svg {
  fill: var(--icons-color);
}

button.bg-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  outline: none;
  font-size: 13px;
  padding: 6px 30px;
  border-radius: 6px;
  transition: 0.4s ease-in-out;
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
button.bg-button svg {
  margin-left: 6px;
  width: 25px !important;
  height: 25px !important;
}
button.bg-button:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}
@media all and (max-width: 1400px) {
  button.bg-button {
    font-size: 13px;
  }
}
@media all and (max-width: 1200px) {
  button.bg-button {
    padding: 5px 25px;
  }
}
@media all and (max-width: 640px) {
  button.bg-button {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  button.bg-button {
    padding: 5px 18px;
  }
}

button.transparent {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  padding: 6px 30px;
  font-size: 13px;
  border-radius: 6px;
  width: 15%;
  transition: 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1200px) {
  button.transparent {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  button.transparent {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  button.transparent {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  button.transparent {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  button.transparent {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  button.transparent {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  button.transparent {
    width: 50%;
  }
}
button.transparent svg {
  transition: 0.3s ease-in-out;
  margin-left: 6px;
  width: 25px !important;
  height: 25px !important;
}
button.transparent:hover {
  border-color: var(--icons-color);
  color: var(--icons-color);
}
button.transparent:hover svg {
  fill: var(--icons-color);
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

.vjs_video_3_ima-ad-container {
  z-index: 1 !important;
}/*# sourceMappingURL=App.css.map */