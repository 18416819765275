div.searchResult{
    padding: 0 0 60px;
    @media all and (max-width:1200px){
        padding: 0 0 10px;
    }
    h1.heading{
        font-size: 24px;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--white-color);
        margin-bottom: 20px;
        @media all and (max-width:640px){
            padding-left: 20px;
        }
    }
    h1.noData{
        color: var(--primary-color);
        text-align: center;
        padding: 100px 0;
    }
}