div.statementOfFaith { 
    padding: 0 0 80px;
    @media all and (max-width:641px){
        padding: 0 20px 80px;
    }
	div.wrapper { 

		div.imageContainer { 
            width: 60%;
            margin: 0 auto 30px;
			img { 
                display: block;
                width: 100%;
                border-radius: 6px;
			}
            @media all and (max-width:1200px){
                width: 70%;
            }
            @media all and (max-width:640px){
                width: 80%;
            }
            @media all and (max-width:481px){
                width: 100%;
            }
		}

		p.head { 
            font-size: 20px;
            text-align: center;
            margin-bottom: 80px;
            color: var(--white-color);
            font-weight: normal;
            @media all and (max-width:1200px){
                font-size: 18px;
            }
            @media all and (max-width:980px){
                font-size: 16px;
            }
            @media all and (max-width:768px){
                margin-bottom: 50px;
            }
            @media all and (max-width:480px){
                font-size: 16px;
            }
		}
        div.contentsContainer{
            width: 70%;
            margin: 0 auto;
            @media all and (max-width:1200px){
                width: 80%;
            }
            @media all and (max-width:640px){
                width: 85%;
            }
            @media all and (max-width:480px){
                width: 90%;
            }
            div.contents { 
                margin-bottom: 50px;
                h1.title { 
                    font-size: 18px;
                    margin-bottom: 10px;
                    @media all and (max-width:980px){
                        font-size: 16px;
                    }
                }
    
                div.details { 
                    border-left: 2px solid var(--text-color);
                    padding-left: 15px;
                    p { 
                        font-size: 16px;
                        text-align: left;
                        margin-bottom: 0;
                        @media all and (max-width:980px){
                            font-size: 14px;
                        }
                    }
                }
            }
        }


		div.buttonContainer { 
            text-align: center;
            margin-top: 50px;
            position: relative;
            button.sharebutton { 
    
                background-color: transparent;
                border: 1px solid var(--primary-color);
                padding: 3px 10px;
                width: 10%;
                border-radius: 6px;
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                transition: 0.4s ease-in-out;
                font-size: 13px;
                
                &.active{
                    svg.active{
                        transform: rotate(0deg);
                    }
                }
                @media all and (max-width:1400px){
                    width: 12%;
                }
                @media all and (max-width:1200px){
                    width: 14%;
                    svg.share{
                        margin-right: 6px;
                    }
                }
                @media all and (max-width:980px){
                    width: 20%;
                    font-size: 14px;
                }
                @media all and (max-width:640px){
                   width: 25%;
                }
                @media all and (max-width:480px){
                    width: 40%;
                    padding: 3px 15px;
                 }
                 @media all and (max-width:360px){
                    width: 45%;
                    padding: 3px 15px;
                 }
                span{
                    color: var(--primary-color);
                    margin :0 10px;
                    transition: 0.4s ease-in-out;
                }
                svg.share{
                    margin-right: 8px;
                    @media all and (max-width:1200px){
                            margin-right: 6px;
                    }
                }
                svg{
                    transition: 0.4s ease-in-out;
                }
                svg.active{
                    transform: rotate(180deg);
                }
                &:hover{
                    border-color: var(--icons-color);
                    span{
                        color: var(--icons-color);
                    }
                    svg{
                       fill: var(--icons-color);
                    }
                }
            }
            div.shareContainer{
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: var(--secondary-bg-color);
                width: 15%;
                animation: slideDown 0.4s ease-in-out;
                @media all and (max-width:980px){
                    width: 20%;
                }
                @media all and (max-width:640px){
                    width: 30%;
                }
                @media all and (max-width:480px){
                    width: 40%;
                }
                button{
                   padding: 8px 0 !important;
                   padding: 8px 0 !important;
                   display: block;
                   text-align: center;
                   margin: 0 auto;
                }
                button.clipboard{
                    border: none;
                    background-color: transparent;
                    font-size: 14px;
                    color: var(--text-color);
                }
                span.name{
                    font-size: 14px;
                    color: var(--primary-color);
                }
                span.twitter{
                    color: #00aceb;
                }
                span.facebook{
                    color: #3a5896;
                    font-size: 14px;
                }
                span.whatsapp{
                    color: #2a9b77;
                    font-size: 14px;
                }
                
                &:hover{
                    svg.bi-facebook{

                        fill: #1877f2 !important;
                    }
                }
            }
        }
	}
}