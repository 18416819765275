div.presenters-info { 
    padding: 0 0 80px;
    @media all and (max-width:640px){
        padding: 0 20px 80px;
    }
	div.imageContainer { 
        width: 40%;
        margin: 0 auto;
        @media all and (max-width:768px){
            width: 70%;
        }
        @media all and (max-width:640px){
           width: 80%;
        }
		img { 
            display: block;
            width: 100%;
		}
	}

	h1.name { 
        font-size: 22px;
        text-align: center;
        margin-top: 30px;
	}

	p.presenter-info { 
        text-align: center;
        font-size: 18px;
        margin-bottom: 40px;
	}   

	p.presenter-details { 
        font-size: 15px;
        text-align: center;
        max-width: 70%;
        margin: 0 auto;
        @media all and (max-width:768px){
            max-width: 90%;
        }
        @media all and (max-width:640px){
            max-width: 95%;
         }
         @media all and (max-width:480px){
            max-width: 100%;
         }
	}

	div.details { 
        margin-top: 50px;
		h1 { 
            font-size: 22px;
            margin-bottom: 20px;
		}

		div.description { 
            border-left: 2px solid var(--text-color);
            padding-left: 15px;
			p { 
                font-size: 16px;
                @media all and (max-width:640px){
                    font-size: 15px;
                 }
			}
		}
	}

	div.shows { 

		categorytile { 

		}
	}
}