div.contact { 
    padding: 0 0 80px;
    @media all and (max-width:641px){
        padding: 0 20px 80px;
     }
	h1.heading { 
        font-size: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--white-color);
        padding-left: 20px;
	}

	div.contactContainer { 
        background-color: var(--secondary-bg-color);
        padding: 80px 80px 120px;
        display: flex;
        justify-content: space-between;
        position: relative;
        @media all and (max-width:768px){
           padding: 60px 60px 80px;
         }
         @media all and (max-width:480px){
            padding: 30px 30px 40px;
         }
         @media all and (max-width:360px){
            padding: 30px 10px 40px;
         }
        @media all and (max-width:768px){
           flex-direction: column;
        }
		div.left { 
            width: 40%;
            @media all and (max-width:980px){
                width:49%;
            }
            @media all and (max-width:768px){
                width: 100%;
                margin-bottom: 20px;
             }
			h1 { 
                font-size: 20px;
                margin-bottom: 20px;
			}

			div.UKContactContainer { 
                margin-left: 20px;
                padding-left: 20px;
                border-left: 1px solid var(--text-color);
				div.oneByTwo { 
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
					div.logoContainer { 
                        width: 15px;
                        margin-right: 10px;
						img { 
                            display: block;
                            width: 100%;
						}
					}

					span { 
                        color: var(--text-color);
                        font-size: 14px;
                        display: block
					}
				}
			}
		}

		div.right { 
            width: 40%;
            @media all and (max-width:980px){
                width:49%;
            }
            @media all and (max-width:768px){
                width: 100%;
             }
			h1 { 
                font-size: 20px;
                margin-bottom: 20px;
			}

			div.USAContactContainer { 
                margin-left: 20px;
                padding-left: 20px;
                border-left: 1px solid var(--text-color);
				div.oneByTwo { 
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
					div.logoContainer { 
                        width: 15px;
                        margin-right: 10px;
						img { 
                            display: block;
                            width: 100%;
						}
                        &.location{
                            @media all and (max-width:1200px){
                                font-size: 15px;
                            }
                        }
					}
                    div.prayer{
                        a.tel{
                            @media all and (max-width:1200px){
                                font-size: 15px;
                            }
                        }
                    }

					span { 
                        color: var(--text-color);
                        font-size: 14px;
                        display: block
					}
				}
			}
		}
        div.technicalSupport{
            position: absolute;
            right: 20px;
            bottom: 20px;
            span{
                color: var(--text-color);
                font-size: 14px;
                span{
                    color: var(--primary-color);
                    cursor: pointer;
                    margin-left: 6px;
                }
            }
        }
	}
}