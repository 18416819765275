div.donation { 
    padding: 0 0 80px;
    @media all and (max-width:640px){
        padding: 0 20px 80px;
    }
    span.error{
        font-size: 14px;
        color: var(--primary-color);
    }
    div.wrapper{
        width: 85%;
        @media all and (max-width:640px){
            width: 100%;
        }
    }
	h1.heading { 
        font-size: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--white-color);
	}

	div.details { 
        padding-top: 50px;
        text-align: center;
		h1.title { 
            font-size: 22px;
            margin-bottom: 30px;
		}

		p { 
            font-size: 16px;
            max-width: 85%;
            margin: 0 auto;
            @media all and (max-width:480px){
                max-width: 100%;
                font-size: 14px;
            }
		}
	}

	div.donationContainer { 
        margin-top: 50px;
        background-color: var(--secondary-bg-color);
        padding: 30px 25px 30px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;
        @media all and (max-width:480px){
           padding-left: 25px;
           padding-right: 20px;
        }
        @media all and (max-width:768px){
            flex-direction: column;
        }
		div.leftContainer { 
            width: 70%;
            @media all and (max-width:1200px){
                width: 66%;
            }
            @media all and (max-width:768px){
                width: 100%;
            }
			p.description { 
                padding-bottom: 15px;
                border-bottom: 1px solid var(--white-color);
                @media all and (max-width:980px){
                    font-size: 14px;
                }
               
            }

			div.formSection { 

				form { 

					div.oneByOne { 
                        display: flex;
                        margin-bottom: 30px;
                        @media all and (max-width:640px){
                            flex-direction: column;
                        }
						div.left { 
                            width: 40%;
                            @media all and (max-width:768px){
                                width: 35%;
                            }
                            @media all and (max-width:640px){
                                margin-bottom: 20px;
                                width: 100%;
                            }
							label { 
                                color: var(--text-color);
                                font-weight: 400;
                                @media all and (max-width:980px){
                                    font-size: 15px;
                                }
							}
						}

						div.right { 
                            display: flex;
                            width: 60%;
                            @media all and (max-width:640px){
                                flex-direction: column;
                                width: 100%;
                            }

							div.inputContainer { 
                                margin-right: 40px;
                                @media all and (max-width:640px){
                                margin-bottom: 20px;
                                width: 100%;
                                &:last-child{
                                    margin-bottom: 0;
                                }

                            }
                                &:last-child{
                                    margin-right: 0;
                                }
								label{
                                    font-size: 14px;
                                    color: var(--text-color);
                                    span.para{
                                        font-size: 14px;
                                    color: var(--text-color);
                                    }
                                    @media all and (max-width:1200px){
                                        font-size: 13px;
                                    }
                                }
                                
                                
							}
                            div.inputContainerFull {
                                width: 60%;
                                @media all and (max-width:640px){
                                    width: 90%;
                                }
                                input{
                                    padding: 6px 10px;
                                    border-radius: 6px;
                                    width: 100%;
                                    font-size: 14px;
                                    @media all and (max-width:480px){
                                        padding: 3px 10px;
                                    }
                                }
                                select{
                                    padding: 6px 10px;
                                    border-radius: 6px;
                                    width: 100%;
                                    font-size: 14px;
                                }
                            }
                            
						}
                        div.right2{
                            display: flex;
                            justify-content: space-between;
                            width: 60%;
                            @media all and (max-width:640px){
                                flex-direction: column;
                                width: 100%;
                                justify-content: center;
                                align-items: center;
                            }
                            div.inputContainer2 {
                                width: 48%;
                                @media all and (max-width:640px){
                                    margin-bottom: 20px;
                                    width: 60%;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                                select{
                                    padding: 8px 10px;
                                    border-radius: 6px;
                                    width: 100%;
                                    @media all and (max-width:640px){
                                        font-size: 14px;
                                        padding: 6px 10px;
                                    }
                                }
                                input{
                                    padding: 6px 10px;
                                    border-radius: 6px;
                                    width: 100%;
                                    font-size: 14px;
                                    @media all and (max-width:640px){
                                        font-size: 14px;
                                        padding: 6px 10px;
                                    }
                                }
                            }
                        }
					}

					div.description{
                        padding-bottom: 30px;
                        margin-bottom: 20px;
                        label{
                            color: var(--text-color);
                        }
                        div.contactWay {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            position: relative;
                            span.error {
                              position: absolute;
                              right: 143px;
                              top: -4px;
                              @media all and (max-width:1200px){
                                font-size: 13px;
                              }
                              @media all and (max-width:768px){
                                right: 0;
                                top: 20px;
                              }
                              @media all and (max-width: 640px) {
                                font-size: 10px;
                               }
                            }
                            &:last-child {
                              margin-bottom: 0;
                            }
                            div.left {
                              span {
                                font-weight: 600;
                                font-size: 14px;
                                color: var(--text-color);
                              }
                            }
                
                            div.right {
                              display: flex;
                              label.container {
                                font-size: 14px;
                                padding-left: 30px;
                                margin-right: 20px;
                                &:last-child {
                                  margin-right: 0;
                                }
                
                                span.checkmark {
                                }
                              }
                            }
                          }
                    }

					div.buttonContainer { 
                        margin-top: 30px;
                        display: flex;
                        border-top: 1px solid (var(--white-color));
                        padding-top: 20px;
                        @media all and (max-width:640px){
                            justify-content: center;
                        }
						button.bg-button { 
                            margin-right: 30px;
                            padding: 6px 12px;
                            width: 20%;
                            font-size: 16px;
                            @media all and (max-width:768px){
                                font-size: 13px;
                            }
                            @media all and (max-width:640px){
                                width: 25%;
                            }
                            @media all and (max-width:480px){
                                width: 35%;
                             }


						}

						button.transparent { 
                            padding: 6px 12px;
                            width: 20%;
                            font-size: 16px;
                            @media all and (max-width:1200px){
                                width: 30%;
                            }
                            @media all and (max-width:768px){
                                font-size: 13px;
                            }
                            @media all and (max-width:480px){
                                width: 40%;
                            }
                            @media all and (max-width:360px){
                               width: 45%;
                            }

						}
					}
				}
			}
		}

		div.rightContainer { 
            width: 23%;
            @media all and (max-width:1200px){
                width: 28%;
            }
            @media all and (max-width:768px){
                width: 100%;
                margin-top: 40px;
            }
			div.debitDonate { 
                padding-bottom: 10px;
                border-bottom: 1px solid var(--white-color);
				h4 { 
                    font-size: 22px;
                    margin-bottom: 15px;
                    @media all and (max-width:1200px){
                        font-size: 20px;
                    }
				}

				p { 
                    font-size: 15px;
                    
				}

				button { 
                    background: transparent;
                    color: var(--primary-color);
				}
			}

			div.otherDonate { 
                margin-top: 20px;
				h4 { 
                    font-size: 22px;
                    margin-bottom: 15px;
                    @media all and (max-width:1200px){
                        font-size: 20px;
                    }

				}

				span { 
                    font-size: 15px;
                    color: var(--primary-color);
                    opacity: 0.8;
				}

				p { 
                    @media all and (max-width:1200px){
                        font-size: 13px;
                    }
				}
			}
		}
	}
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
      }
      
      /* On mouse-over, add a grey background color */
      .container{
        &:hover{
            span.checkmark{
                background-color: #ccc;
            }
        }
      }
      
      /* When the radio button is checked, add a blue background */
      .container input:checked  {
        ~ span.checkmark{
            background-color: var(--primary-color);
        }
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .container .checkmark:after {
           top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
      }
    
      .Squarecontainer {
        display: block;
        position: relative;
        margin-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .Squarecontainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    
      .Squarecheckmark {
        position: absolute;
        top: -1px;
        left: -33px;
        height: 20px;
        width: 20px;
        border-radius: 8px;
        background-color: #eee;
      }
      
      /* On mouse-over, add a grey background color */
      .Squarecontainer:hover input  {
        ~ .Squarecheckmark{
            background-color: #ccc;
        }
      }
      
      /* When the checkbox is checked, add a blue background */
      .Squarecontainer input:checked {
        ~ .Squarecheckmark {
    
            background-color: var(--primary-color);
        }
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .Squarecheckmark {
        &::after{
    
            content: "";
            position: absolute;
            display: none;
        }
      }
      
      /* Show the checkmark when checked */
      .Squarecontainer input:checked  {
        ~ .Squarecheckmark{
            &::after{
    
                display: block;
            }
        }
      }
      
      /* Style the checkmark/indicator */
      .Squarecontainer .Squarecheckmark {
        &::after{
    
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg) ;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;  
        }
      }
    
      .descriptionContainer {
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 14px;
        @media all and (max-width:480px){
            font-size: 15px;
         }
      }
      
      /* Hide the browser's default checkbox */
      .descriptionContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    
      .descCheckmark {
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 8px;
        background-color: #eee;
        &.right{
            margin-left: 10px;
        }
      }
      
      /* On mouse-over, add a grey background color */
      .descriptionContainer:hover input  {
        ~ .descCheckmark{
            background-color: #ccc;
        }
      }
      
      /* When the checkbox is checked, add a blue background */
      .descriptionContainer input:checked {
        ~ .descCheckmark {
    
            background-color: var(--primary-color);
        }
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .descCheckmark {
        &::after{
    
            content: "";
            position: absolute;
            display: none;
        }
      }
      
      /* Show the checkmark when checked */
      .descriptionContainer input:checked  {
        ~ .descCheckmark{
            &::after{
    
                display: block;
            }
        }
      }
      
      /* Style the checkmark/indicator */
      .descriptionContainer .descCheckmark {
        &::after{
    
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg) ;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;  
        }
      }
}