div.magazinesList{
    div.wrapper{
        @media all and (max-width:640px){
            width: 90%;
        }
        h1.heading { 
            font-size: 24px;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--white-color);
            @media all and (max-width:640px){
                padding-left: 20px;
                font-size: 22px;
            }
        }
        div.magazines{
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            div.item{
                width: 23%;
                margin-right: 30px;
        margin-bottom: 100px;
        margin-top: 20px;
        &:nth-child(4n){
            margin-right: 0;
        }
        @media all and (max-width:1600px){
            margin-right: 29px;
        }
        @media all and (max-width:1400px){
            margin-right: 25px;
        }
        @media all and (max-width:1200px){
            margin-right: 23px;
        }
        @media all and (max-width:980px){
            width: 47%;
            margin-bottom: 19px;
            &:nth-child(4n){
                margin-right: 23px;
            }
            &:nth-child(2n){
                margin-right: 0;
            }
            
        }
        @media all and (max-width:768px){
            width: 46%;
            margin-right: 25px;
        }
        @media all and (max-width:640px){
           width: 48%;
           margin-right: 17px;
        }
        @media all and (max-width:480px){
            width: 100%;
            margin-right: 0;
         }
        &:last-child{
            margin-right: 0;
        }
    
             }
        }
    }
}