div.prayerSection { 
    padding: 0 0 120px;
    span.error{
        font-size: 12px;
        text-align: left;
    }
    span.success{
        font-size: 12px;
    }
	h1.heading { 
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        @media all and (max-width:640px){
            padding-left: 20px;
            font-size: 22px;
        }
	}

	div.contents { 
        margin-top: 40px;
        text-align: center;
        @media all and (max-width:480px){
            padding: 0 10px;
        }
		h2.title { 
            font-size: 22px;
            margin-bottom: 30px;
            color: var(--white-color);
            opacity: 0.8;
            @media all and (max-width:640px){
                font-size: 20px;
            }
            @media all and (max-width:480px){
                font-size: 18px;
            }
		}

		p { 
            max-width: 50%;
            margin: 0 auto 40px;
            font-size: 15px;
            @media all and (max-width:980px){
                max-width: 100%;
                font-size: 14px;
            }
            @media all and (max-width:640px){
                font-size: 13px;
            }
			a { 
                display: inline-block;
                color: var(--primary-color);
                font-weight: 600;
			}
		}

		div.formSection { 
            width: 42%;
            margin: 0 auto;
            @media all and (max-width:1200px){
                width: 55%;
            }
            @media all and (max-width:980px){
                width: 75%;
            }
            @media all and (max-width:640px){
                width: 85%;
            }
			form { 
                input{
                    border-radius: 6px;
                    padding: 6px 10px;
                }
                textarea{
                    border-radius: 6px;
                    resize: none;
                    height: 150px;

                }
                label{
                    margin-bottom: 6px;
                    @media all and (max-width:640px){
                        font-size: 13px;
                    }
                }
				div.half { 
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    @media all and (max-width:480px){
                        flex-direction: column;
                    }
					div.fname { 
                        display:flex;
                        flex-direction: column;
                        width: 49%;
                        @media all and (max-width:480px){
                            width: 100%;
                            margin-bottom: 10px;
                        }
						label { 
                            text-align: left;
						}
					}

					div.lname { 
                        width: 49%;
						display:flex;
                        flex-direction: column;
                        @media all and (max-width:480px){
                            width: 100%;
                        }
						label { 
                            text-align: left;
						}
					}
				}

				div.full { 
                    width: 100%;
                    margin-bottom: 10px;
					div.email { 
                        display: flex;
                        flex-direction: column;
						label { 
                            text-align:left;
						}
					}
                    div.messageBox{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        label{
                            text-align: left;
                        }
                    }
				}

				div.buttonContainer { 
                    text-align: center;
					button.bg-button { 
                        margin: 0 auto;
                        width: 30%;
					}
				}
			}
		}
	}
}