div.buildingFund { 
    padding: 0 0 80px;
	div.wrapper { 

		h1.heading { 
            font-size: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--white-color);
            @media all and (max-width:641px){
                padding-left: 20px;
            }
		}

		div.videoContainer { 
            width: 80%;
            margin: 30px auto 0;
            @media all and (max-width:480px){
                width: 90%;
             }
			video { 
                width: 100%;
                display: block;
			}
		}

		div.details { 
            width: 80%;
            margin: 20px auto 0;
            @media all and (max-width:480px){
                width: 90%;
             }
			p { 
                font-size: 15px;
                margin-bottom: 20px;
                @media all and (max-width:768px){
                    font-size: 14px;
                }
			}

			span.link { 
                display: inline-block;
                color: var(--primary-color);
                font-size: 16px;
                display:block;
                text-align: center;
                @media all and (max-width:480px){
                    font-size: 14px;
                 }
			}
		}

		
	}
    div.gallery { 
        display: flex;
        margin-top: 30px;
        div.imageContainer { 
            width: 34%;
            img { 
                width: 100%;
                display: block;
                height: 290px;
                object-fit: cover;
                @media all and (max-width:980px){
                    height: 200px;
                }
                @media all and (max-width:641px){
                    height: 170px;
                }
                @media all and (max-width:480px){
                    height: 130px;
                 }
            }
        }
    }

    div.buttonContainer { 
        text-align: center;
        margin-top: 50px;
        position: relative;
        div.shareContainer{
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: var(--secondary-bg-color);
            width: 15%;
            animation: slideDown 0.4s ease-in-out;
            @media all and (max-width:980px){
                width: 20%;
            }
            @media all and (max-width:640px){
                width: 30%;
            }
            @media all and (max-width:480px){
                width: 40%;
            }
            button{
               padding: 8px 0 !important;
               padding: 8px 0 !important;
               display: block;
               text-align: center;
               margin: 0 auto;
            }
            button.clipboard{
                border: none;
                background-color: transparent;
                font-size: 14px;
                color: var(--text-color);
            }
            span.name{
                font-size: 14px;
                color: var(--primary-color);
            }
            span.twitter{
                color: #00aceb;
            }
            span.facebook{
                color: #3a5896;
                font-size: 14px;
            }
            
            &:hover{
                svg.bi-facebook{

                    fill: #1877f2 !important;
                }
            }
        }
    }
}