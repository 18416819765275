div.privacyPolicy{
    padding-bottom: 150px;
    @media all and (max-width:640px){
        padding: 0 20px 120px;
    }
    h1.heading{
        padding-bottom: 20px;
        border-bottom:1px solid var(--white-color);
        font-size: 24px;
    }
    h6{
        color: var(--text-color);
        font-size: 16px;
        font-weight: 600;
        &.bottom-distance{
            margin-bottom: 30px;
        }

    }
    p{
        color: var(--text-color);
        font-size: 16px;
        font-weight: normal;
        font-weight: 600;
        &.bottom-distance{
            margin-bottom: 70px;
        }
    }
    h4{
        font-size: 18px;
        color:var(--text-color);
        font-weight: 600;
    }
    ul{
        li{
            color: var(--text-color);
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
}