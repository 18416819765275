div.donate-by-card {
  padding: 0 0 80px;
  @media all and (max-width: 640px) {
    padding: 0 20px 80px;
  }
  span.error {
    font-size: 14px;
    color: var(--primary-color);
    display: inline-block;
    margin-top: 5px;
    @media all and (max-width: 768px) {
        font-size: 12px;
       }
       @media all and (max-width: 640px) {
        font-size: 10px;
       }
  }
  label {
    padding: 0;
    color: var(--text-color);
    font-size: 13px;
    font-weight: 600;
    span {
      color: var(--primary-color);
    }
  }
  div.wrapper {
    h1.heading {
      font-size: 24px;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--white-color);
    }

    div.currentDonationAmountContainer {
      display: flex;
      margin-top: 30px;
      margin-bottom: 40px;
      @media all and (max-width: 768px) {
        flex-direction: column;
      }
      @media all and (max-width: 480px) {
        flex-direction: column;
      }
      p {
        font-size: 16px;
        margin-right: 20px;
        margin-bottom: 0;
        @media all and (max-width: 480px) {
          font-size: 14px;
        }
        span {
          font-family: Arial, Helvetica, sans-serif;
          color: var(--primary-color);
        }
      }

      div.buttonContainer {
        width: 18%;
        margin-right: 10px;
        @media all and (max-width: 1400px) {
            width: 20%;
           }
        @media all and (max-width: 980px) {
           width: 28%;
          }
          @media all and (max-width: 640px) {
            width: 40%;
           }
           @media all and (max-width: 480px) {
            width: 50%;
           }
           @media all and (max-width: 360px) {
            width: 60%;
           }
          span.error{
            @media all and (max-width: 768px) {
                font-size: 12px;
               }
          }
        button.transparent {
          display: block;
          width: 100%;
          @media all and (max-width: 480px) {
            margin: 20px auto 0;
          }
        }
        @media all and (max-width: 768px) {
          margin-top: 10px;
        }
      }
      div.inputContainer {
        background-color: var(--bg-color);
        border: 1px solid var(--primary-color);
        padding: 6px;
        border-radius: 6px;
        @media all and (max-width: 768px) {
          margin-top: 10px;
        }
        input {
          background-color: transparent;
          outline: none;
          border: none;
          margin-left: 10px;
          color: var(--text-color);
          font-size: 14px;
        }
        span {
          color: var(--primary-color);
          cursor: pointer;
        }
        label {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
        }
      }
    }

    ul.donationFormContainer {
      li.giftSection {
        display: flex;
        align-items: flex-start;
        @media all and (max-width: 640px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          border-radius: 6px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: var(--primary-color);
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 8px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &::before {
          content: "1";
          width: 100px;
          height: 45px;
          font-size: 20px;
          font-weight: 600;
          background-color: var(--primary-color);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          @media all and (max-width: 1400px) {
            width: 122px;
          }
          @media all and (max-width: 1200px) {
            width: 141px;
          }
          @media all and (max-width: 980px) {
            width: 166px;
            height: 40px;
          }
          @media all and (max-width: 768px) {
            width: 186px;
          }
          @media all and (max-width: 640px) {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
        h1.subHeading {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          @media all and (max-width: 640px) {
            font-size: 14px;
          }
        }
        label.container {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 400;
          #gift {
          }

          span.checkmark {
          }
        }
      }

      li.personDetails {
        display: flex;
        margin-top: 20px;
        margin-bottom: 30px;
        @media all and (max-width: 640px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        &::before {
          content: "2";
          width: 50px;
          height: 45px;
          font-size: 20px;
          font-weight: 600;
          background-color: var(--primary-color);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          @media all and (max-width: 640px) {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
        div.personDetailsContainer {
          width: 100%;
          h1.subHeading {
            font-size: 20px;
            margin-bottom: 20px;
          }

          form {
            div.oneByTwo {
              margin-bottom: 20px;
              display: flex;
              width: 50%;
              justify-content: space-between;
              flex-wrap: wrap;
              @media all and (max-width: 980px) {
                width: 70%;
              }
              @media all and (max-width: 640px) {
                width: 100%;
              }
              div.Container {
                display: flex;
                flex-direction: column;

                width: 48%;
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-bottom: 10px;
                }
                label {
                  margin-bottom: 8px;
                  span {
                  }
                }

                input {
                  padding: 6px 10px;
                  border-radius: 5px;
                  outline: none;
                  border: none;
                }
                select {
                  padding: 6px 10px;
                  border-radius: 5px;
                  outline: none;
                  border: none;
                }
              }
            }
          }
        }
      }

      li.contactSection {
        display: flex;
        margin-bottom: 30px;
        @media all and (max-width: 640px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default radio button */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: #eee;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: var(--primary-color);
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
        }
        &::before {
          content: "3";
          width: 65px;
          height: 45px;
          font-size: 20px;
          font-weight: 600;
          background-color: var(--primary-color);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          @media all and (max-width: 1200px) {
            width: 85px;
          }
          @media all and (max-width: 980px) {
            width: 100px;
          }
          @media all and (max-width: 768px) {
            width: 130px;
          }
          @media all and (max-width: 640px) {
            width: 50px;
            height: 50px;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        div.contactContainer {
          h1.subHeading {
            font-size: 20px;
            margin-bottom: 20px;
          }

          p {
            font-size: 16px;
          }

          div.contactWay {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            position: relative;
            span.error {
              position: absolute;
              right: 143px;
              top: -4px;
              @media all and (max-width:1200px){
                font-size: 13px;
              }
              @media all and (max-width:768px){
                right: 0;
                top: 20px;
              }
              @media all and (max-width: 640px) {
                font-size: 10px;
               }
            }
            &:last-child {
              margin-bottom: 0;
            }
            div.left {
              span {
                font-weight: 600;
                font-size: 14px;
                color: var(--text-color);
              }
            }

            div.right {
              display: flex;
              label.container {
                font-size: 14px;
                padding-left: 30px;
                margin-right: 20px;
                &:last-child {
                  margin-right: 0;
                }

                span.checkmark {
                }
              }
            }
          }
        }
      }

      li.accountDetails {
        display: flex;
        @media all and (max-width: 640px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default radio button */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: #eee;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: var(--primary-color);
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
        }
        &::before {
          content: "4";
          width: 48px;
          height: 45px;
          font-size: 20px;
          font-weight: 600;
          background-color: var(--primary-color);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          @media all and (max-width: 640px) {
            width: 50px;
            height: 50px;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        div.accountDetailsContainer {
          width: 100%;
          h1.subHeading {
            font-size: 20px;
            margin-bottom: 20px;
          }

          form {
            width: 100%;
            div.oneByOne {
              display: flex;
              flex-direction: column;
              width: 50%;
              margin-bottom: 10px;
              @media all and (max-width: 980px) {
                width: 70%;
              }
              @media all and (max-width: 640px) {
                width: 100%;
              }
              label {
                margin-bottom: 10px;
                span {
                }
              }

              input {
                padding: 6px 10px;
                border-radius: 5px;
                outline: none;
                border: none;
              }
            }

            div.dateSection {
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              span.error {
                position: absolute;
                right: 153px;
                top: -4px;
                @media all and (max-width:1200px){
                    font-size: 13px;
                  }
                  @media all and (max-width:768px){
                    right: 0;
                    top: 20px;
                  }
                  @media all and (max-width: 640px) {
                    font-size: 10px;
                   }
                   @media all and (max-width: 480px) {
                    top: 31px;
                   }
              }
              div.left {
                span {
                  color: var(--text-color);
                  font-size: 13px;
                  font-weight: 600;
                  span {
                    color: var(--primary-color);
                  }
                }
              }

              div.right {
                display: flex;
                align-items: center;
               
                label.container {
                  font-size: 14px;
                  margin-right: 20px;
                  margin-bottom: 0;
                  &:last-child {
                    margin-right: 0;
                  }
                  #dateYes {
                  }

                  span.checkmark {
                  }
                }
              }
            }
          }
        }
      }
    }

    div.donateButtonContainer {
      text-align: center;
      margin-top: 80px;
      button.bg-button {
        margin: 0 auto;
        width: 15%;
        @media all and (max-width: 640px) {
          width: 30%;
        }
      }
    }
  }
}
