div.latestNews{
    padding: 0 0 80px;
    div.head{
        display: flex;
        justify-content: right;
        @media all and (max-width:640px){
            padding-right: 20px;
        }
        div.logoContainer{
            width: 150px;
            cursor: pointer;
            @media all and (max-width:640px){
                width: 120px;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                display: block;
                width: 100%;
                transition: 0.5s ease-in-out;
            }
        }
    }
    div.body{
        h1.heading{
            font-size: 24px;
            border-bottom:1px solid var(--white-color);
            padding-bottom: 10px;
            @media all and (max-width:640px){
                padding-left: 20px;
            }
        }
    }
    div.gradientBg{
        bottom: -36px;
        button.showMore{
            margin-top: 90px;
        }
    }
}