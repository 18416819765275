div.magazineCard{
    width: 100%;
    transition: 0.4s ease-in-out;
    
    cursor: pointer;
   
    &:hover{
        transform: scale(1.1);
        @media all and (max-width:980px){
            transform: scale(1);
        }
        div.bottom{
            display: block;
            animation: textAnimationIn 0.4s ease-in-out;
            width: 100%;
            @media all and (max-width:980px){
                animation: none;
            }
        }
    }
    div.top{
        div.imageContainer{
            width: 100%;
            img{
                display: block;
                width: 100%;
                border-radius: 6px;
            }
        }
    }
    div.bottom{
        display: none;
        position: absolute;
        @media all and (max-width:980px){
            display: block;
            animation: none;
            position: relative;
        }
        h3.title{

        }
        p{
            margin-bottom: 5px;
            font-size: 14px;
            @media all and (max-width:980px){
            max-width: 90%;
            font-size: 12px;
            }
            @media all and (max-width:641px){
            max-width: 100%;
            font-size: 14px;

                
             }
        }
        div.buttonContainer{
            svg{
                color: var(--primary-color);
            }
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--primary-color);
                    background-color: transparent;
                    color: var(--primary-color);
                    padding: 6px 20px;
                    font-size: 12px;
                    border-radius: 6px;
                    width: 70%;
                    transition: 0.3s ease-in-out;
                    &:hover{

                        border-color: var(--icons-color);
                        color: var(--icons-color);
                        svg{
                            polygon{
                                fill: var(--icons-color);
                            }
                        }
                    }
            }
        }
    }
}
@keyframes textAnimationIn{
    from{
        opacity: 0;
        transform: translateY(-20px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}