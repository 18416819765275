:root{
    --primary-color:#e72a31;
    --bg-color:#101010;
    --secondary-bg-color:#181818;
    --white-color:#f8f8f8;
    --icons-color:#ff0103;
    --hover-color:#1d2124;
  }
div.footer-container{
    background-color: var(--hover-color);
    
    div.footerWrapper{
        width: 80%;
        margin: 0 auto;
        @media all and (max-width:768px){
            flex-direction: column;
        }
        div.left{
            div.top{
                margin-bottom: 10px;
                ul.links{
                    flex-wrap: wrap;
                    li{
                        margin-right: 10px;
                        @media all and (max-width:768px){
                            margin-right: 6px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        span{
                            font-size: 14px;
                        color: var(--text-color);
                            font-weight: 600;
                            @media all and (max-width:1400px){
                                font-size: 13px;
                            }
                            @media all and (max-width:1200px){
                                font-size: 11px;
                            }
                            @media all and (max-width:980px){
                                font-size: 10px;
                            }
                            @media all and (max-width:768px){
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
            div.center{
                p{
                    font-size: 14px;
                    &:first-child{
                        
                        margin-bottom: 0;
                    }
                    @media all and (max-width:1400px){
                        font-size: 13px;
                    }
                    @media all and (max-width:1200px){
                        font-size: 11px;
                    }
                    @media all and (max-width:980px){
                        font-size: 10px;
                    }
                    

                }
            }
            div.bottom{
                p{
                    margin-bottom: 0;
                    font-size: 14px;
                    span{
                        color: var(--text-color);
                        font-weight: 600;
                    }
                    @media all and (max-width:1400px){
                        font-size: 13px;
                    }
                    @media all and (max-width:1200px){
                        font-size: 11px;
                    }
                    @media all and (max-width:980px){
                        font-size: 10px;
                    }

                    @media all and (max-width:768px){
                        font-size: 11px;
                    }

                }
            }
        }
        div.right{
            @media all and (max-width:768px){
                margin-top: 20px;

            }
            div.top{
                @media all and (max-width:980px){
                    margin-top: 8px;
                }
                ul.social{
                    justify-content: right;
                    @media all and (max-width:768px){
                        justify-content: left;

                    }
                    li{
                        margin-right: 10px;
                        width: 20px;
                        cursor: pointer;
                        @media all and (max-width:1200px){
                            width: 15px;
                        }
                        @media all and (max-width:980px){
                            width: 15px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
            div.bottom{
                margin-top: 20px;
                @media all and (max-width:768px){
                    margin-top: 10px;
                }
                div.imageContainer{
                    width: 400px;
                    @media all and (max-width:1400px){
                        width: 296px;
                    }
                    @media all and (max-width:1200px){
                        width: 220px;
                    }
                    @media all and (max-width:980px){
                        width: 171px;
                    }
                    @media all and (max-width:768px){
                        width: 236px;
                    }
                    img{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}