div.giftAidInfo{
    padding-bottom: 200px;
    h1.heading{
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--white-color);
        @media all and (max-width:640px){
            padding-left: 20px;
            font-size: 22px;
        }
    }
    div.contentsContainer{
        width: 70%;
        margin: 0 auto;
        padding-top: 100px;
        text-align: center;
        @media all and (max-width:1200px){
            width: 77%;
        }
        @media all and (max-width:980px){
            width: 87%;
        }
        @media all and (max-width:768px){
            width: 100%;
        }
        @media all and (max-width:640px){
            padding: 30px 20px 0;
        }
        div.imageContainer{
            width: 70%;
            margin: 0 auto 50px;
            img{
                display: block;
                width: 100%;
            }
        }
        p{
            @media all and (max-width:640px){
                font-size: 13px;
            }
        }
    }
}