div.cardContainer{
    h1.title{
        font-size: 24px;
        @media all and (max-width:480px){
            font-size: 20px;
        }
    }
    div.cards{
        justify-content: space-between;
        margin-top: 30px;
        flex-wrap: wrap;
        @media all and (max-width:768px){
            flex-direction: column;
        }
        div.imageContainer{
            width: 48%;
            margin-bottom: 30px;
            transition: 0.4s ease-in-out;
            overflow: hidden;
            button.pointer{
                cursor: pointer;
            }
            button.name{
                cursor: default;
            }
            @media all and (max-width:768px){
                width: 100%;
            }
            @media all and (max-width:640px){
                width: 90%;
                margin: 0 auto 30px;
            }
            &:hover{
                transform: scale(1.1);
            }
            &:hover div.cardGradient{
                transform: translateY(10px);
            }
            img{
                display: block;
                width: 100%;
                border-radius: 12px;
            }
        }
    }
}