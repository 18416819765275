div.subscribeBanner{
    margin-top: 100px;
    div.imageContainer{
        width:100%;
        display: block;
        position: relative;
        img{
            width: 100%;
        display: block;
        }
        div.buttonContainer{
            text-align: center;
            position: absolute;
                bottom: 20%;
                width: 15%;
                left: 50%;
                transform: translateX(-50%);
            button.subscribe{
                
                background-color: var(--primary-color);
                color: var(--white-color);
                
                border: none;
                outline: none;
                font-size: 14px;
                padding: 6px 30px;
                width: 84%;
                border-radius: 6px;
                border: 1px solid var(--primary-color);
                transition: 0.4s ease-in-out;
                @media all and (max-width:980px){
                    font-size: 12px;
                    padding: 6px 16px;
                    width: 100%_Pe;
                }
                @media all and (max-width:768px){
                    font-size: 9px;
                    padding: 6px 16px;
                }
                @media all and (max-width:480px){
                    font-size: 8px;
                    padding: 2px 1px;
                }
                
                &:hover{
                    background-color: var(--white-color);
                    color: var(--primary-color);
                    
                }
            }
        }
    }
}