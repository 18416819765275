div.satteliteDevices{
    background-color: #fff;
    padding: 60px 0;
    h1.heading{
        padding-bottom: 10px;
        border-bottom: 1px solid var(--primary-color);
        max-width: 45%;
        color: #000;
        margin-bottom: 40px;
        @media all and (max-width:640px){
            margin-left: 20px;
            max-width: 85%;
        }
    }
    div.contents{
        @media all and (max-width:640px){
            width: 90%;
            margin: 0 auto;
        }
        div.sections{
        margin-bottom: 60px;
            &:last-child{
                div.imageContainer{
                    width: 400px;
                }
            }
            div.imageContainer{
                width: 300px;
                margin-bottom: 20px;
                img{
                    display: block;
                    width: 80%;
                }
            }
            div.description{
                p{
                    color: var(--bg-color);

                    font-size: 15px;
                    span.link{
                        color: rgb(53, 53, 248);
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .content-list{
    height: 100px;
    color: #000000;
    list-style: disc;
   margin-left: 39px;
}
            }
        }
        div.date{
            display: flex;
            align-items: center;
            div.icon{
                margin-right: 10px;
                width: 20px;
                img{
                    width: 100%;
                    display: block;
                }
            }
            span{
                color: var(--bg-color);
                
            }
        }
        div.buttonContainer { 
            text-align: center;
            margin-top: 50px;
            position: relative;
            button.shareButton { 
    
                background-color: transparent;
                border: 1px solid var(--primary-color);
                padding: 10px 10px;
                width: 10%;
                border-radius: 10px;
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 !important;
                transition: 0.4s ease-in-out;
                @media all and (max-width:980px){
                    width: 20%;
                    font-size: 14px;
                }
                @media all and (max-width:641px){
                   width: 25%;
                }
                @media all and (max-width:480px){
                    width: 35%;
                    padding: 6px 15px;
                 }
                span{
                    color: var(--primary-color);
                    margin :0 10px;
                    transition: 0.4s ease-in-out;
                }
                
                svg{
                    transition: 0.4s ease-in-out;
                }
                svg.active{
                    transform: rotate(180deg);
                }
                &:hover{
                    border-color: var(--icons-color);
                    span{
                        color: var(--icons-color);
                    }
                    svg{
                       fill: var(--icons-color);
                    }
                }
                
            }
            button.active{
                    svg.active{
                        transform: rotate(0deg);
                    }
            }
            div.shareContainer{
                position: absolute;
                bottom: 44px;
                left: 0;
                right: 0;
                background-color: var(--secondary-bg-color);
                width: 10%;
                animation: slideUp 0.4s ease-in-out;
                @media all and (max-width:1200px){
                    width: 17%;
                }
                @media all and (max-width:980px){
                    width: 21%;
                }
                @media all and (max-width:768px){
                    width: 23%;
                }
                @media all and (max-width:640px){
                    width: 30%;
                }
                @media all and (max-width:480px){
                    width: 35%;
                }
                button{
                   padding: 8px 0 !important;
                   padding: 8px 0 !important;
                   display: block;
                   text-align: center;
                   margin: 0 auto;
                }
                button.clipboard{
                    border: none;
                    background-color: transparent;
                    font-size: 14px;
                    color: var(--text-color);
                }
                span.name{
                    font-size: 14px;
                    color: var(--primary-color);
                }
                span.twitter{
                    color: #00aceb;
                }
                span.facebook{
                    color: #3a5896;
                    font-size: 14px;
                }
                
                &:hover{
                    svg.bi-facebook{

                        fill: #1877f2 !important;
                    }
                }
            }
        }
    }
}