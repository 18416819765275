div.videoPlayer { 
    padding: 0 0 16px;
    @media all and (max-width:640px){
        padding: 0 20px 80px;
     }
	div.wrapper { 

		div.videoContainer { 
            width: 100%;
			video { 
                width: 100%;
                display: block;
			}
		}

		div.detailsContainer { 
            margin-top: 20px;
			div.top { 
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid var(--white-color);
				div.left { 

					h1.title { 
                        font-size: 22px;
					}
				}

				div.right { 

					div.dateSection { 
                        display: flex;
                        align-items: center;
                        
						span.icon { 
                            width: 15px;
                            margin-right: 10px;
                            display: block;
							img { 
                                width: 100%;
                                display: block;
							}
						}

						span.date { 
                            font-size: 14px;
						}
					}
				}
			}

			div.bottom { 
                margin-top: 20px;
				p { 
                    font-size: 14px;
                    margin-bottom: 0;
				}

				div.buttonContainer { 
                    margin-top: 10px;
                    position: relative;
                    width: 10%;
                    @media all and (max-width:1400px){
                        width: 13%;
                        svg.share{
                            width: 14px !important;
                            height: 14px !important;
                        }
                    }
                    @media all and (max-width:980px){
                        width: 20%;
                    }
                    @media all and (max-width:768px){
                        width: 25%;
                    }
                    @media all and (max-width:640px){
                        width: 30%;

                    }
                    @media all and (max-width:480px){
                        width: 40%;

                    }

                    div.shareContainer{
                        position: absolute;
                        top: 40px;
                        right:0;
                        background-color: var(--secondary-bg-color);
                        width: 100%;
                        animation: slideDown 0.4s ease-in-out;
                        z-index: 9;
                        svg.active{
                            width: 12px !important;
                            height: 12px !important;
                        }
                        button{
                           padding: 8px 0 !important;
                           padding: 8px 0 !important;
                           display: block;
                           text-align: center;
                           margin: 0 auto;
                        }
                        button.clipboard{
                            border: none;
                            background-color: transparent;
                            font-size: 14px;
                            color: var(--text-color);
                        }
                        span.name{
                            font-size: 14px;
                            color: var(--primary-color);
                        }
                        span.twitter{
                            color: #00aceb;
                        }
                        span.facebook{
                            color: #3a5896;
                            font-size: 14px;
                        }
                        
                        &:hover{
                            svg.bi-facebook{
        
                                fill: #1877f2 !important;
                            }
                        }
                    }
                    button.share { 
                        
                        background-color: transparent;
                        border: 1px solid var(--primary-color);
                        padding: 4px 10px;
                        width: 100%;
                        border-radius: 6px;
                        color: var(--primary-color);
                        display: flex;
                        font-size: 13px;
                        align-items: center;
                        justify-content: center;
                        transition: 0.4s ease-in-out;
                        &.active{
                            svg.active{
                                transform: rotate(0deg);
                            }
                        }
                        svg{
                            transition: 0.4s ease-in-out;
                        }
                        svg.active{
                            transform: rotate(180deg);
                        }
                        @media all and (max-width:980px){
                            font-size: 14px;
                        }
                        @media all and (max-width:641px){
                        }
                        @media all and (max-width:480px){
                            padding: 6px 15px;
                         }
                        span{
                            color: var(--primary-color);
                            margin :0 10px;
                            transition: 0.4s ease-in-out;
                        }
                        svg{
                            transition: 0.4s ease-in-out;
                        }
                        &:hover{
                            border-color: var(--icons-color);
                            span{
                                color: var(--icons-color);
                            }
                            svg{
                               fill: var(--icons-color);
                            }
                        }
                    }
                }
			}
		}

		div.episodesContainer { 
            margin-top: 30px;
			h1.heading { 
                font-size: 24px;
                padding-bottom: 20px;
                border-bottom: 1px solid var(--white-color);
			}

			div.episodes { 
                padding-top: 30px;
				display: flex;
                align-items: center;
                flex-wrap: wrap;
                
                div.episode{
                    width: 23%;
                    margin-right: 30px;
                    margin-bottom: 150px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1600px){
                        width: 22%;
                    }
                    @media all and (max-width:980px){
                        width: 30%;
                        margin-bottom: 125px;
                        &:nth-child(4n){
                                margin-right: 30px;
                            }
                            &:nth-child(3n){
                                margin-right: 0;
                            }
                    }
                    @media all and (max-width:768px){
                        width: 47%;
                        
                        &:nth-child(3n){
                            margin-right: 30px;
                        }
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                    }
                    @media all and (max-width:640px){
                        width: 100%;
                        margin-right: 0;
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                    }
                    @media all and (max-width:480px){
                        width: 100%;
                    }
                }
			}

			div.gradientBg { 
                z-index: 9;
                bottom: 112px;
                div.buttonContainer{
                    position: absolute;
                    bottom: -66px;
                    width: 100%;
                    z-index: 9;
                    background: var(--bg-color);
                    button.showMore { 
                        margin-top: 38px;
                        downarrow { 
    
                        }
                    }
                }
			}
		}
	}
}