div.liveContainer{
    padding: 0 0 0;
    div.wrapper{
        div.liveVideoContainer{
            width: 100%;
            .vjs-big-play-button{
                display: none !important;
            }
            @media all and (max-width:640px){
                width: 90%;
                margin: 0 auto;
            }
           
            img{
                width: 100%;
                display: block;
            }   
        }
         h1.nowPlaying { 
                font-size: 22px;
                margin-bottom: 10px;
                @media all and (max-width:640px){
                    padding-left: 10px;
                }
            }
        div.showsContainer{
           
        }
    }
}