@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
.wrapper{
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width:1200px){
  .wrapper{
    width: 90%;
  }
}
@media all and (max-width:641px){
  .wrapper{
    width: 100%;
  }
}
/* @font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url(./fonts/Vaud400.woff2) format("woff2"),
    url(./fonts/Vaud400.woff) format("woff"),
    url(./fonts/Vaud400.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
} */
@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: local('ITC Avant Garde Gothic Light'), local('ITC-Avant-Garde-Gothic-Light'),
      url('./fonts/ITCAvantGardeStd-XLt.woff2') format('woff2'),
      url('./fonts/ITCAvantGardeStd-XLt.woff') format('woff'),
      url('./fonts/ITCAvantGardeStd-XLt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: local('ITC Avant Garde Gothic Bk'), local('ITC-Avant-Garde-Gothic-Bk'),
      url('./fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
      url('./fonts/ITCAvantGardeStd-Bk.woff') format('woff'),
      url('./fonts/ITCAvantGardeStd-Bk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family:ITC Avant Garde Gothic, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101010;
  color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
